import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import type {
  TPostResReturnSettlementsDto,
  TPostPayloadReturnSettlementsDto,
} from './return-settlements.type'
import { postResReturnSettlementsDto } from './return-settlements.schema'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/tGO45n
 * @description 반품 환불관련 정산에 필요한정보
 */
export async function postReturnSettlements({
  body,
  orderCode,
  sectionCode,
}: {
  body: TPostPayloadReturnSettlementsDto
  orderCode: string
  sectionCode: string
}) {
  type T = TPostResReturnSettlementsDto
  type R = typeof body
  const result = await axios
    .post<
      T,
      AxiosResponse<T>,
      R
    >(`/order/${orderCode}/sections/${sectionCode}/return-settlements`, body)
    .then((res) => res.data)

  try {
    postResReturnSettlementsDto.parse(result)
  } catch (error) {
    Sentry.captureException(error)
  }
  return result
}
