import { pipe, flow } from 'fp-ts/function'
import { ISpecification, Spec } from 'spec-pattern-ts'
import { 모델_외부채널_페이먼트 } from '.'
import { 상수_결제상태 } from '~/entities/@x'

export const 결제대기: ISpecification<모델_외부채널_페이먼트> = new Spec(
  flow(
    (candidate) => candidate.payment,
    (e) => e.statusCd === 상수_결제상태.결제대기
  )
)
