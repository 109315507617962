import * as Sentry from '@sentry/react'
import { ApiClient } from '~/shared/api'
import type { TGetResOrderMemoSchema } from './order-memo.type'
import { getResOrderMemoSchema } from './order-memo.schema'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/xzu1W5
 * @description 주문 목록 상세데이터 조회 - 메모
 */
export async function getOrderMemo(orderCode: string) {
  const result = await axios
    .get<ApiResponse<TGetResOrderMemoSchema>>(`/order/${orderCode}/memo`)
    .then((res) => res.data)

  try {
    getResOrderMemoSchema.parse(result.data)
  } catch (error) {
    Sentry.captureException(error)
  }
  return result
}
