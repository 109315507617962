import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import type {
  TPostResBulkActionSectionInvoicePrintValidateSchema,
  TPostPayloadBulkActionSectionInvoicePrintValidateSchema,
} from './bulk-action-section-invoice-print-validate.type'
import { postResBulkActionSectionInvoicePrintValidateSchema } from './bulk-action-section-invoice-print-validate.schema'
import * as Sentry from '@sentry/react'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/afvL3C
 * @description 주문 섹션 굿스플로 송장출력 대량 처리 검증
 */
export async function postBulkActionSectionInvoicePrintValidate({
  body,
}: {
  body: TPostPayloadBulkActionSectionInvoicePrintValidateSchema
}) {
  type R = typeof body
  type T = TPostResBulkActionSectionInvoicePrintValidateSchema
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/section/invoice/print/validate', body)
    .then((res) => res.data)

  try {
    postResBulkActionSectionInvoicePrintValidateSchema.parse(result.data)
  } catch (error) {
    Sentry.captureException(error)
  }
  return result.data
}
