import { debounce } from 'lodash'
import React from 'react'

/**
 * 타겟 요소에 세로 스크롤이 있는지 여부
 */
export function useHasScroll<T extends HTMLElement>() {
  const ref = React.useRef<T>(null)
  const [hasScroll, setHasScroll] = React.useState(false)

  React.useEffect(() => {
    const updateHasScroll = () => {
      if (ref.current) {
        const { scrollHeight, clientHeight } = ref.current
        setHasScroll(scrollHeight > clientHeight)
      }
    }

    updateHasScroll()

    const debouncedUpdateHasScroll = debounce(updateHasScroll, 200)

    const resizeObserver = new ResizeObserver(debouncedUpdateHasScroll)

    if (ref.current) {
      resizeObserver.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current)
      }
      resizeObserver.disconnect()
    }
  }, [])

  return { ref, hasScroll }
}
