import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient } from '~/shared/api'
import {
  patchResOpenPendingOrderExitDto,
  type TPatchResOpenPendingOrderExitDto,
  type TPatchPayloadOpenPendingOrderExitDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description open-pending-order-exit
 */
export function patchOpenPendingOrderExit({
  orderCode,
}: {
  orderCode: string
}) {
  return async function () {
    type T = TPatchResOpenPendingOrderExitDto
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>
      >(`/order/${orderCode}/open-pending-order/exit`)
      .then((res) => res.data)

    try {
      patchResOpenPendingOrderExitDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}
