import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import { type TGetResExternalClaimsDto, getResExternalClaimsDto } from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/oJ8HVw
 * @description 외부채널 취소/반품 사유 리스트
 */
export function getExternalClaims({
  saleChannelIdx,
  claimType,
}: {
  saleChannelIdx: 1 | 2 | 3
  claimType: 'cancel' | 'return' | 'delay'
}) {
  return async function () {
    const result = await axios
      .get<
        ApiResponse<TGetResExternalClaimsDto>
      >(`/site/${saleChannelIdx}/claims/${claimType}`)
      .then((res) => res.data)

    try {
      getResExternalClaimsDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result.data
  }
}
