import React from 'react'
import { useCurOrderSearchFilter } from './order-search-filter.store'
import { Flex, Skeleton } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'

const OrderSearchFilter = React.lazy(() =>
  import('./order-search-filter').then((module) => ({
    default: module.OrderSearchFilter,
  }))
)

export function OrderSearchFilterPack() {
  const [filter] = useCurOrderSearchFilter()

  if (!filter.open) {
    return null
  }
  return (
    <React.Suspense
      fallback={
        <Flex
          columnGap={vars.spacing[1]}
          height="214px"
          padding={`${vars.spacing[1]} ${vars.spacing[5]} ${vars.spacing[5]}`}
        >
          <Skeleton width="76px" height="32px" rounded="base" />
          <Skeleton width="76px" height="32px" rounded="base" />
          <Skeleton width="76px" height="32px" rounded="base" />
        </Flex>
      }
    >
      <OrderSearchFilter />
    </React.Suspense>
  )
}
