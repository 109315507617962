import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPatchResCancelReasonDto,
  type TPatchPayloadCancelReasonDto,
  patchResCancelReasonDto,
  patchPayloadCancelReasonDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/YiQgvO
 * @description [cacnel] 외부 주문서 취소접수 섹션 사유수정 실제 (외부 접수건의 사유는 변경되지 않습니다)
 */
export function patchCancelReason({ orderCode }: { orderCode: string }) {
  return async function (body: TPatchPayloadCancelReasonDto) {
    type T = TPatchResCancelReasonDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/external-order/${orderCode}/sections/cancel-reason`, body)
      .then((res) => res.data)

    try {
      patchResCancelReasonDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}
