import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TGetResExternalOrderDeliveryDto,
  getResExternalOrderDeliveryDto,
  type TPostResExternalOrderDeliveryDto,
  type TPostPayloadExternalOrderDeliveryDto,
  postResExternalOrderDeliveryDto,
  postPayloadExternalOrderDeliveryDto,
  type TPatchResExternalOrderDeliveryDto,
  type TPatchPayloadExternalOrderDeliveryDto,
  patchResExternalOrderDeliveryDto,
  patchPayloadExternalOrderDeliveryDto,
  type TDeleteResExternalOrderDeliveryDto,
  type TDeletePayloadExternalOrderDeliveryDto,
  deleteResExternalOrderDeliveryDto,
  deletePayloadExternalOrderDeliveryDto,
  TPostResExternalOrderDeliveryGoodsflowDto,
  TPostPayloadExternalOrderDeliveryGoodsflowDto,
} from '.'
import * as Sentry from '@sentry/react'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/FSeBti
 * @description 배송지 단일 조회
 */
export function getExternalOrderDelivery({
  orderCode,
  orderDeliveryCode,
}: {
  orderCode: string
  orderDeliveryCode: string
}) {
  return async function () {
    const result = await axios
      .get<
        ApiResponse<TGetResExternalOrderDeliveryDto>
      >(`/external-order/${orderCode}/delivery/${orderDeliveryCode}`)
      .then((res) => res.data)

    try {
      getResExternalOrderDeliveryDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result.data
  }
}

/**
 * @API문서 https://5k.gg/fPZwRh
 * @description 배송지 생성
 */
export function postExternalOrderDelivery({
  orderCode,
}: {
  orderCode: string
}) {
  return async function (body: TPostPayloadExternalOrderDeliveryDto) {
    type R = typeof body
    type T = TPostResExternalOrderDeliveryDto
    const result = await axios
      .post<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/external-order/${orderCode}/delivery`, body)
      .then((res) => res.data)

    try {
      postResExternalOrderDeliveryDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}

/**
 * @API문서 https://5k.gg/uRxgZk
 * @description 배송지 수정
 */
export function patchExternalOrderDelivery({
  orderCode,
  orderDeliveryCode,
}: {
  orderCode: string
  orderDeliveryCode: string
}) {
  return async function (body: TPatchPayloadExternalOrderDeliveryDto) {
    type T = TPatchResExternalOrderDeliveryDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/external-order/${orderCode}/delivery/${orderDeliveryCode}`, body)
      .then((res) => res.data)

    try {
      patchResExternalOrderDeliveryDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}

/**
 * @API문서 https://5k.gg/MUtCHz
 * @description 배송지 정보 삭제
 */
export function deleteExternalOrderDelivery({
  orderCode,
  orderDeliveryCode,
}: {
  orderCode: string
  orderDeliveryCode: string
}) {
  return async function () {
    type T = TDeleteResExternalOrderDeliveryDto
    const result = await axios
      .delete<
        T,
        AxiosResponse<ApiResponse<T>>
      >(`/external-order/${orderCode}/delivery/${orderDeliveryCode}`)
      .then((res) => res.data)

    try {
      deleteResExternalOrderDeliveryDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}

/**
 * @API문서 https://5k.gg/HoEn5y
 * @description 굿스플로 배송서비스 송장출력할 배송건 등록 및 송장출력 요청(송장출력에 필요한 Url 및 정보 반환)
 */
export function postExternalOrderDeliveryGoodsflow({
  orderCode,
}: {
  orderCode: string
}) {
  return async function (body: TPostPayloadExternalOrderDeliveryGoodsflowDto) {
    type R = typeof body
    type T = TPostResExternalOrderDeliveryGoodsflowDto
    const result = await axios
      .post<
        T,
        ApiResponse<T>,
        R
      >(`/external-order/${orderCode}/delivery/print-invoice`, body)
      .then((res) => res.data)

    try {
      postResExternalOrderDeliveryDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}
