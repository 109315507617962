import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPostResBulkActionSectionDeliveryShippingValidateAllSchema,
  type TPostPayloadBulkActionSectionDeliveryShippingValidateAllSchema,
  postResBulkActionSectionDeliveryShippingValidateAllSchema,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/TuTF75
 * @description 주문 배송 중 대량 처리 검증
 */
export async function postBulkActionSectionDeliveryShippingValidateAll({
  body,
}: {
  body: TPostPayloadBulkActionSectionDeliveryShippingValidateAllSchema
}) {
  type R = typeof body
  type T = TPostResBulkActionSectionDeliveryShippingValidateAllSchema
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/section/delivery/shipping/validate/all', body)
    .then((res) => res.data)

  try {
    postResBulkActionSectionDeliveryShippingValidateAllSchema.parse(result.data)
  } catch (error) {
    Sentry.captureException(error)
  }
  return result.data
}
