import React from 'react'
import { OrdersTableBodySkeleton } from '~/container/orders/orders-skeleton/orders-table-skeleton'
import { OrdersTableBodyContainer } from './orders-table-body.container'

export function OrdersTableBodyPack() {
  return (
    <React.Suspense fallback={<OrdersTableBodySkeleton />}>
      <OrdersTableBodyContainer />
    </React.Suspense>
  )
}
