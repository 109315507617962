import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { useQuery } from '@tanstack/react-query'
import { type TCancelAllProductPreparationBase } from '.'
import {
  OrderSectionItemDto,
  orderDetailOptions,
} from '~/entities/order-detail'
import { OrderSection } from '~t'
import { __ } from '~/shared/i18n'
import { fpDate } from '~/shared/utils'
import { ItemImmutableList } from '../item-immutable-list'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { OrderSectionSkeleton } from '~/shared/components/order-section-skeleton'

export interface TCancelAllProductPreparation
  extends TCancelAllProductPreparationBase {}
export function CancelAllProductPreparation({
  sectionCode,
}: TCancelAllProductPreparationBase) {
  const orderCode = useOrderCode()
  const {
    data: _data,
    isSuccess,
    isLoading,
  } = useQuery({
    ...orderDetailOptions({ orderCode }),
    select(e) {
      const result = pipe(
        e.data.orderSectionList,
        O.fromNullable,
        O.fold(
          () => {
            throw new Error('orderSectionList is null')
          },
          flow(
            A.findFirst((r) => r.orderSectionCode === sectionCode),
            O.fold(
              () => {
                throw new Error('orderSectionCode is null')
              },
              (r) => r
            )
          )
        ),
        (r) => ({
          orderData: e.data,
          data: r,
        })
      )
      return result
    },
  })

  // ************************************** 렌더
  if (isLoading) {
    return <OrderSectionSkeleton />
  }
  if (isSuccess) {
    const { data } = _data
    return (
      <>
        <OrderSection name="상품준비중">
          <OrderSection.Slot name="title" className="">
            <div className="grid grid-cols-[1fr,auto] gap-x-[8px]">
              <div>
                <div className="typo-clay-heading-xlarge-bold">
                  {__('상품준비')}{' '}
                  {pipe(
                    data.orderSectionItemList,
                    O.fromNullable,
                    O.fold(
                      () => null,
                      flow(
                        (e) => e.length,
                        (e) => (
                          <span className="text-clay-semantic-textPrimary">
                            {e}
                          </span>
                        )
                      )
                    )
                  )}
                </div>
                <div className="mt-[8px] typo-clay-body-small text-clay-semantic-textSub">
                  {__('#{{섹션넘버}} · {{생성일}} 생성', {
                    생성일: pipe(
                      data.wtime,
                      O.fromNullable,
                      O.fold(
                        () => new Date(),
                        (e) => new Date(e)
                      ),
                      fpDate('yyyy년 MM월 dd일')
                    ),
                    섹션넘버: data.orderSectionNo,
                  })}
                </div>
              </div>
            </div>
          </OrderSection.Slot>
          <OrderSection.Slot
            name="body"
            className="mt-[24px] [--mx:24px] space-y-[24px]"
          >
            {data.orderSectionItemList && (
              <ItemImmutableList sectionItems={data.orderSectionItemList} />
            )}
          </OrderSection.Slot>
        </OrderSection>
      </>
    )
  }
  return null
}
