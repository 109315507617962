import { queryOptions } from '@tanstack/react-query'
import {
  getOrderList,
  getOrderListPagination,
  TGetPayloadOrderListDto,
  TGetPayloadOrderListPaginationDto,
} from '.'

export function orderListQueryOptions(params: TGetPayloadOrderListDto) {
  return queryOptions({
    queryKey: ['orderList', params],
    queryFn: () => getOrderList(params),
  })
}

export function orderListPaginationQueryOptions(
  params: TGetPayloadOrderListPaginationDto
) {
  return queryOptions({
    queryKey: ['orderList', 'pagination', params],
    queryFn: () => getOrderListPagination(params),
  })
}
