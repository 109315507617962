import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import { Controller } from 'react-hook-form'
import { useOrderSectionReturnComplete } from '..'
import { RadioGroup } from '~/shared/components/ui/radio-group'
import { __ } from '~/shared/i18n'
import { match } from 'ts-pattern'
import { Trans } from 'react-i18next'
import {
  모델_반품금액계산,
  스펙_반품금액계산,
} from '~/entities/order-section/return-complete/model'
import { Input } from '~/shared/components/input'
import { ContentsBanner } from '@imwebme/clay-components'
import { useCurrency } from '~/shared/hooks/use-currency'
import { cn } from '~/shared/utils'
import { useSuspenseQuery } from '@tanstack/react-query'
import { orderDetailOptions } from '~/entities/order-detail'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import * as math from 'mathjs'
import { 상수_환불금액_타입 } from '~/entities/@x'

export function DeliveryExtraPriceType({
  $return,
}: {
  $return: 모델_반품금액계산
}) {
  const orderCode = useOrderCode()
  const currency = useCurrency()
  const { data } = useSuspenseQuery(orderDetailOptions({ orderCode }))
  const {
    control,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
  } = useOrderSectionReturnComplete()
  const deliveryExtraPriceType = watch('deliveryExtraPriceType')

  React.useEffect(() => {
    const _sub = match<boolean>(true)
      .with(
        $return.form.refundPriceTypeCd === 상수_환불금액_타입.실결제가,
        $return.form.refundPriceTypeCd === 상수_환불금액_타입.평균실결제가,
        () =>
          math.min(
            $return.최대_환불가능_배송비,
            math.chain(data.deliveryPrice).add(data.deliveryIslandPrice).done()
          )
      )
      .otherwise(() => null)
    setValue('deliveryExtraPrice._sub', _sub)
  }, [])

  React.useEffect(() => {
    if ($return.form.deliveryExtraPrice?._sub) {
      if (
        Number($return.form.deliveryExtraPrice._sub) >
          $return.최대_환불가능_배송비 &&
        errors.deliveryExtraPrice?._sub === undefined
      ) {
        setError('deliveryExtraPrice._sub', {
          message: __('최대 환불 가능 배송비를 초과하였습니다.'),
        })
      } else if (
        Number($return.form.deliveryExtraPrice._sub) <=
          $return.최대_환불가능_배송비 &&
        errors.deliveryExtraPrice?._sub &&
        Number($return.form.deliveryExtraPrice._sub) > 0
      ) {
        clearErrors('deliveryExtraPrice._sub')
      }
    }
  }, [$return])

  const disabled = 스펙_반품금액계산.배송비환불가능여부
    .not()
    .isSatisfiedBy($return)

  return (
    <div className="space-y-[20px]">
      {match<boolean>(true)
        .with($return.form.refundPriceTypeCd === 'ORT05', () => (
          <ContentsBanner
            type="secondary"
            variant="warning"
            text={__('금액 설정을 직접하는 경우 배송비 설정이 제한되어요')}
          />
        ))
        .with(
          스펙_반품금액계산.배송비환불타입
            .and(스펙_반품금액계산.배송비환불가능여부.not())
            .isSatisfiedBy($return),
          () => (
            <ContentsBanner
              type="secondary"
              variant="warning"
              text={__(
                '환불 가능한 금액보다 환불 요청 금액이 더 많은경우 배송비 환불이 제한되어요'
              )}
            />
          )
        )
        .otherwise(() => null)}
      <Controller
        control={control}
        name="deliveryExtraPriceType"
        render={({ field: { value, onChange } }) => (
          <RadioGroup
            defaultValue="separate"
            value={value}
            onValueChange={onChange}
            className="flex gap-x-[24px]"
            disabled={$return.form.refundPriceTypeCd === 'ORT05'}
          >
            <div className="flex items-center space-x-2">
              <RadioGroup.Item value="ADD" id="r1" />
              <RadioGroup.Label htmlFor="r1">{__('요청')}</RadioGroup.Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroup.Item value="SUB" id="r2" />
              <RadioGroup.Label htmlFor="r2">{__('환불')}</RadioGroup.Label>
            </div>
          </RadioGroup>
        )}
      />
      <div>
        {match<boolean>(true)
          .with(deliveryExtraPriceType === 'ADD', () => (
            <div className="space-y-[8px]">
              <Controller
                control={control}
                name="deliveryExtraPrice._add"
                render={({ field, fieldState: { error } }) => (
                  <>
                    <div className="relative w-[180px]">
                      <Trans
                        i18nKey="<input /> <span>{{ currency, currency }}</span>"
                        values={{
                          formatParams: {
                            currency: {
                              currency,
                            },
                          },
                        }}
                        components={{
                          input: (
                            <Input
                              type="number"
                              value={pipe(field.value, String)}
                              onChange={flow(
                                (e) => e.target.value,
                                Number,
                                field.onChange
                              )}
                              className={cn(
                                'pr-[calc(12px*2+12px)]',
                                error &&
                                  'bg-clay-semantic-surfaceCritical border-none'
                              )}
                              disabled={
                                $return.form.refundPriceTypeCd === 'ORT05'
                              }
                            />
                          ),
                          span: (
                            <span className="text-clay-semantic-textSub typo-clay-body-medium absolute right-[12px] top-1/2 -translate-y-1/2" />
                          ),
                        }}
                      />
                    </div>
                    {error && (
                      <div className="typo-clay-body-small">
                        {__('배송비 추가금액은 20만원 이하로 입력해주세요.')}
                      </div>
                    )}
                  </>
                )}
              />
              <div className="typo-clay-body-small text-clay-semantic-textSub">
                {__('최대 {{price, 3comma}} {{currency, currency}} 가능', {
                  price: 200000,
                  formatParams: {
                    currency: {
                      currency,
                    },
                  },
                })}
              </div>
            </div>
          ))
          .with(deliveryExtraPriceType === 'SUB', () => (
            <div>
              <Controller
                control={control}
                name="deliveryExtraPrice._sub"
                render={({ field, fieldState: { error } }) => (
                  <div className="relative w-[180px]">
                    <Trans
                      i18nKey="<input /> <span>{{ currency, currency }}</span>"
                      values={{
                        formatParams: {
                          currency: {
                            currency,
                          },
                        },
                      }}
                      components={{
                        input: (
                          <Input
                            type="number"
                            value={pipe(field.value, String)}
                            onChange={flow(
                              (e) => e.target.value,
                              Number,
                              field.onChange
                            )}
                            disabled={disabled}
                            className={cn(
                              'pr-[calc(12px*2+12px)]',
                              error &&
                                'bg-clay-semantic-surfaceCritical border-none'
                            )}
                          />
                        ),
                        span: (
                          <span
                            className={cn(
                              'typo-clay-body-medium absolute right-[12px] top-1/2 -translate-y-1/2',
                              disabled
                                ? 'text-clay-semantic-textDisabled'
                                : 'text-clay-semantic-textSub'
                            )}
                          />
                        ),
                      }}
                    />
                  </div>
                )}
              />
              {!disabled && (
                <div className="mt-[8px] typo-clay-body-small text-clay-semantic-textSub">
                  {__('최대 {{price, 3comma}} {{currency, currency}} 가능', {
                    price: $return.최대_환불가능_배송비,
                    formatParams: {
                      currency: {
                        currency,
                      },
                    },
                  })}
                </div>
              )}
            </div>
          ))
          .otherwise(() => null)}
      </div>
    </div>
  )
}
