import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPatchResNextShippingDto,
  type TPatchPayloadNextShippingDto,
  patchResNextShippingDto,
  patchPayloadNextShippingDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/Wn1Oeb
 * @description [next] 외부 주문서 섹션 배송중으로 변경
 */
export function patchNextShipping({ orderCode }: { orderCode: string }) {
  return async function (body: TPatchPayloadNextShippingDto) {
    type T = TPatchResNextShippingDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/external-order/${orderCode}/sections/next/shipping`, body)
      .then((res) => res.data)

    try {
      patchResNextShippingDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}
