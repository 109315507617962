import React from 'react'
import { OrdersPaginationContainer } from './orders-pagination.container'
import { Skeleton } from '@imwebme/clay-components'

export function OrdersPaginationPack() {
  return (
    <React.Suspense
      fallback={
        <div className="px-clay-5 pt-clay-6 pb-clay-3 flex justify-center">
          <Skeleton width="120px" height="32px" rounded="small" />
        </div>
      }
    >
      <OrdersPaginationContainer />
    </React.Suspense>
  )
}
