import React from 'react'
import { __, __e } from '~/shared/i18n'
import {
  Button,
  ButtonGroup,
  Clay,
  Flex,
  Modal,
} from '@imwebme/clay-components'
import { FormProvider, useForm, DefaultValues } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  orderDeliveryQueryOptions,
  patchOrderDelivery,
} from '~/entities/order-delivery'
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { orderDetailOptions } from '~/entities/order-detail'
import { vars } from '@imwebme/clay-token'
import { deliveryCountryQueryOptions } from '~/entities/site/delivery-country'
import { useModals } from '~/router'
import { useModalParams } from '~/shared/hooks/use-modal-params'
import { delivPayloadSchema, TDelivPayloadSchema } from '~/entities/@dto'
import {
  배송국가,
  받는분,
  연락처,
  주소정보_한국,
  주소정보_해외,
  배송메모,
} from '../form-fields'
import { useHasScroll } from '~/shared/hooks/use-has-scroll'
import { cn } from '~/shared/utils'

export function DeliveryEditModal() {
  const modals = useModals()
  const modalParams = useModalParams()
  const orderCode = modalParams?.orderCode as string
  const unitCode = modalParams?.unitCode as string
  const orderDeliveryCode = modalParams?.orderDeliveryCode as string
  const queryClient = useQueryClient()
  const { ref, hasScroll } = useHasScroll<HTMLDivElement>()

  const { data: delivCountryList } = useSuspenseQuery(
    deliveryCountryQueryOptions(unitCode)
  )

  const { data: delivData } = useSuspenseQuery(
    orderDeliveryQueryOptions({ orderCode, orderDeliveryCode })
  )

  const { mutate, isPending } = useMutation({
    mutationFn: patchOrderDelivery({
      orderCode,
      orderDeliveryCode,
    }),
  })

  const formDefaultValues: DefaultValues<TDelivPayloadSchema> = React.useMemo(
    () => ({
      country: delivData.country,
      countryName: delivCountryList.find((el) => el.code === delivData.country)
        ?.name, //현재 선물하기 주문에서 delivData.countryName이 항상 대한민국인 이슈가 있음
      receiverName: delivData.receiverName,
      receiverCall: delivData.receiverCall,
      zipcode: delivData.zipcode,
      whoInput: delivData.whoInput,
      memo: delivData.memo || undefined,
      ...(delivData.country === 'KR'
        ? {
            kor: {
              addr1: delivData.addr1 || undefined,
              addr2: delivData.addr2 || undefined,
            },
          }
        : {}),
      ...(delivData.country !== 'KR'
        ? {
            intl: {
              building: delivData.building || undefined,
              street: delivData.street || undefined,
              city: delivData.city || undefined,
              state: delivData.state || undefined,
            },
          }
        : {}),
    }),
    [delivCountryList, delivData]
  )

  const methods = useForm<TDelivPayloadSchema>({
    resolver: zodResolver(delivPayloadSchema),
    defaultValues: formDefaultValues,
  })
  const {
    handleSubmit,
    getValues,
    formState: { isValid },
  } = methods

  const country = getValues('country')

  const onSubmit = handleSubmit((form) => {
    const { kor, intl, ...rest } = form

    mutate(
      { ...kor, ...intl, ...rest },
      {
        onSuccess() {
          queryClient.invalidateQueries({
            queryKey: [orderDetailOptions({ orderCode }).queryKey[0]],
          })
          queryClient.invalidateQueries({
            queryKey: [
              orderDeliveryQueryOptions({
                orderCode,
                orderDeliveryCode,
              }).queryKey[0],
            ],
          })
          modals.close()
          toast.success(__('배송지를 수정했습니다'))
        },
        onError(error) {
          toast.error(
            error.response
              ? __e(error.response.data.code)
              : __('배송지 수정에 실패했어요')
          )
        },
      }
    )
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} className="overflow-hidden flex flex-col">
        <Modal.Body ref={ref}>
          <Flex.Column rowGap={vars.spacing[5]}>
            <배송국가 {...{ delivCountryList }} />
            <Flex columnGap={vars.spacing[3]}>
              <받는분 />
              <연락처 />
            </Flex>
            {country === 'KR' ? <주소정보_한국 /> : <주소정보_해외 />}
            <배송메모 />
          </Flex.Column>
        </Modal.Body>

        <Clay
          className={cn(
            'px-clay-4 pb-clay-4 z-10',
            hasScroll && 'pt-clay-3 shadow-clay-layer'
          )}
        >
          <ButtonGroup sx={{ justifyContent: 'flex-end' }}>
            <Button
              native={{ type: 'button' }}
              variant="outlined"
              onClick={() => modals.close()}
              text={__('취소')}
            />
            <Button
              native={{ type: 'submit' }}
              variant="primary"
              isDisabled={!isValid}
              isLoading={isPending}
              text={__('완료')}
            />
          </ButtonGroup>
        </Clay>
      </form>
    </FormProvider>
  )
}
