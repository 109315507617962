import React from 'react'
import { __, __e } from '~/shared/i18n'
import {
  Button,
  ButtonGroup,
  Clay,
  Flex,
  Modal,
} from '@imwebme/clay-components'
import { FormProvider, useForm, DefaultValues } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { vars } from '@imwebme/clay-token'
import { deliveryCountryQueryOptions } from '~/entities/site/delivery-country'
import { useModals } from '~/router'
import { useModalParams } from '~/shared/hooks/use-modal-params'
import { delivPayloadSchema, TDelivPayloadSchema } from '~/entities/@dto'
import { 받는분, 연락처, 주소정보_한국, 배송메모 } from '../form-fields'
import {
  externalOrderDeliveryQueryOptions,
  patchExternalOrderDelivery,
} from '~/entities/external-order-delivery'
import { externalOrderQueryOptions } from '~/entities/external-order'
import { useHasScroll } from '~/shared/hooks/use-has-scroll'
import { cn } from '~/shared/utils'

export function ExternalDeliveryEditModal() {
  const modals = useModals()
  const modalParams = useModalParams()
  const orderCode = modalParams?.orderCode as string
  const unitCode = modalParams?.unitCode as string
  const orderDeliveryCode = modalParams?.orderDeliveryCode as string
  const queryClient = useQueryClient()
  const { ref, hasScroll } = useHasScroll<HTMLDivElement>()

  const { data: delivCountryList } = useSuspenseQuery(
    deliveryCountryQueryOptions(unitCode)
  )

  const { data: delivData } = useSuspenseQuery(
    externalOrderDeliveryQueryOptions({ orderCode, orderDeliveryCode })
  )

  const { mutate, isPending } = useMutation({
    mutationFn: patchExternalOrderDelivery({
      orderCode,
      orderDeliveryCode,
    }),
  })

  const formDefaultValues: DefaultValues<TDelivPayloadSchema> = React.useMemo(
    () => ({
      country: delivData.country,
      countryName: delivCountryList.find((el) => el.code === delivData.country)
        ?.name,
      receiverName: delivData.receiverName,
      receiverCall: delivData.receiverCall,
      zipcode: delivData.zipcode,
      whoInput: delivData.whoInput,
      memo: delivData.memo || undefined,
      kor: {
        addr1: delivData.addr1 || undefined,
        addr2: delivData.addr2 || undefined,
      },
    }),
    [delivCountryList, delivData]
  )

  const methods = useForm<TDelivPayloadSchema>({
    resolver: zodResolver(delivPayloadSchema),
    defaultValues: formDefaultValues,
  })
  const {
    handleSubmit,
    formState: { isValid },
  } = methods

  const onSubmit = handleSubmit((form) => {
    const { kor, ...rest } = form

    mutate(
      { ...kor, ...rest, addr1: kor?.addr1 || '' },
      {
        onSuccess() {
          queryClient.invalidateQueries({
            queryKey: [externalOrderQueryOptions({ orderCode }).queryKey[0]],
          })
          queryClient.invalidateQueries({
            queryKey: [
              externalOrderDeliveryQueryOptions({
                orderCode,
                orderDeliveryCode,
              }).queryKey[0],
            ],
          })
          modals.close()
          toast.success(__('배송지를 수정했습니다'))
        },
        onError(error) {
          toast.error(
            error.response
              ? __e(error.response.data.code)
              : __('배송지 수정에 실패했어요')
          )
        },
      }
    )
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} className="overflow-hidden flex flex-col">
        <Modal.Body ref={ref}>
          <Flex.Column rowGap={vars.spacing[5]}>
            <Flex columnGap={vars.spacing[3]}>
              <받는분 />
              <연락처 />
            </Flex>
            <주소정보_한국 />
            <배송메모 />
          </Flex.Column>
        </Modal.Body>

        <Clay
          className={cn(
            'px-clay-4 pb-clay-4 z-10',
            hasScroll && 'pt-clay-3 shadow-clay-layer'
          )}
        >
          <ButtonGroup sx={{ justifyContent: 'flex-end' }}>
            <Button
              native={{ type: 'button' }}
              variant="outlined"
              onClick={() => modals.close()}
              text={__('취소')}
            />
            <Button
              native={{ type: 'submit' }}
              variant="primary"
              isDisabled={!isValid}
              isLoading={isPending}
              text={__('완료')}
            />
          </ButtonGroup>
        </Clay>
      </form>
    </FormProvider>
  )
}
