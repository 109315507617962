import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import type {
  TPostResCancelSettlementsSchema,
  TPostPayloadCancelSettlementsSchema,
} from './cancel-settlements.type'
import { postResCancelSettlementsSchema } from './cancel-settlements.schema'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/jv4Hc2
 * @description 취소 환불관련 정산에 필요한정보
 */
export async function postCancelSettlements({
  body,
  orderCode,
  sectionCode,
}: {
  body: TPostPayloadCancelSettlementsSchema
  orderCode: string
  sectionCode: string
}) {
  type T = TPostResCancelSettlementsSchema
  type R = typeof body
  const result = await axios
    .post<
      T,
      AxiosResponse<T>,
      R
    >(`/order/${orderCode}/sections/${sectionCode}/cancel-settlements`, body)
    .then((res) => res.data)

  try {
    postResCancelSettlementsSchema.parse(result)
  } catch (error) {
    Sentry.captureException(error)
  }
  return result
}
