import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import * as C from '@hyeon/calc-ts'
import * as Ord from 'fp-ts/Ord'
import {
  Item,
  OrderColumnSort,
  type OrderColumnSortProps,
} from './order-column-sort'
import { __ } from '~/shared/i18n'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  orderSearchTabQueryOptions,
  patchOrderSearchTab,
} from '~/entities/order-search-tab'
import { updateVisibleColumns } from '~/entities/order-search-tab/order-search-tab.calc'
import { match } from 'ts-pattern'
import {
  useActiveTab,
  useTabCode,
} from '~/shared/hooks/use-tab-code/use-tab-code'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Columns02 } from '@imwebme/clay-icons'
import { IconButton } from '@imwebme/clay-components'
import { ScrollArea } from '~/shared/components/ui/scroll-area'

interface TOrderColumnSortContainer
  extends Omit<
    OrderColumnSortProps,
    'list' | 'setList' | 'onChanged' | 'disabled'
  > {}
export const OrderColumnSortContainer = ({
  ...props
}: TOrderColumnSortContainer) => {
  const tabCode = useTabCode()
  const queryClient = useQueryClient()
  const data = useActiveTab()

  const { mutate } = useMutation({
    mutationFn: patchOrderSearchTab({ tabCode }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: orderSearchTabQueryOptions().queryKey,
      })
    },
  })

  const byOrderNo: Ord.Ord<Item> = Ord.fromCompare((a, b) => {
    if (a.id === 'order_no') {
      return -1
    }
    if (b.id === 'order_no') {
      return 1
    }
    return 0
  })
  const list: Item[] = React.useMemo(
    () =>
      pipe(
        data.visibleColumns,
        A.filter(({ key }) => key !== 'orderItems'), // TODO orderItems 컬럼은 불필요, 서버에서 지워지면 삭제한다
        A.map((e) => ({ ...e, key: C.camelToSnake(e.key) })),
        A.map((vc) =>
          match(vc)
            .with({ key: 'order_no' }, (r) => ({
              id: r.key,
              title: __('주문번호'),
              visible: true,
              onClickVisible: (e: boolean) =>
                log.debug('주문번호 visible click', e),
              isVisibleControl: false,
              lock: true,
            }))
            .with({ key: 'order_no' }, (r) => ({
              id: r.key,
              title: __('주문번호'),
              visible: r.isVisible === 'Y' ? true : false,
              onClickVisible: (e: boolean) =>
                log.debug('주문번호 visible click', e),
              isVisibleControl: false,
            }))
            .with({ key: 'channel' }, (r) => ({
              id: r.key,
              title: __('판매채널'),
              visible: r.isVisible === 'Y' ? true : false,
              onClickVisible: (e: boolean) =>
                log.debug('판매채널 visible click', e),
              isVisibleControl: true,
            }))
            .with({ key: 'order_date' }, (r) => ({
              id: r.key,
              title: __('주문일'),
              visible: r.isVisible === 'Y' ? true : false,
              onClickVisible: (e: boolean) =>
                log.debug('주문일 visible click', e),
            }))
            .with({ key: 'payment_date' }, (r) => ({
              id: r.key,
              title: __('결제일'),
              visible: r.isVisible === 'Y' ? true : false,
              onClickVisible: (e: boolean) =>
                log.debug('결제일 visible click', e),
            }))
            .with({ key: 'orderer_name' }, (r) => ({
              id: r.key,
              title: __('구매자'),
              visible: r.isVisible === 'Y' ? true : false,
              onClickVisible: (e: boolean) =>
                log.debug('구매자 visible click', e),
            }))
            .with({ key: 'item_count' }, (r) => ({
              id: r.key,
              title: __('품목'),
              visible: r.isVisible === 'Y' ? true : false,
              onClickVisible: (e: boolean) =>
                log.debug('품목 visible click', e),
            }))
            .with({ key: 'paid_price' }, (r) => ({
              id: r.key,
              title: __('최종 실제결제금액'),
              visible: r.isVisible === 'Y' ? true : false,
              onClickVisible: (e: boolean) =>
                log.debug('최종 실제결제금액 visible click', e),
            }))
            .with({ key: 'payment_method' }, (r) => ({
              id: r.key,
              title: __('결제수단'),
              visible: r.isVisible === 'Y' ? true : false,
              onClickVisible: (e: boolean) =>
                log.debug('결제수단 visible click', e),
            }))
            .with({ key: 'transaction_status' }, (r) => ({
              id: r.key,
              title: __('거래상태'),
              visible: r.isVisible === 'Y' ? true : false,
              onClickVisible: (e: boolean) =>
                log.debug('거래상태 visible click', e),
            }))
            .with({ key: 'order_status' }, (r) => ({
              id: r.key,
              title: __('주문상태'),
              visible: r.isVisible === 'Y' ? true : false,
              onClickVisible: (e: boolean) =>
                log.debug('주문상태 visible click', e),
            }))
            .with({ key: 'payment_status' }, (r) => ({
              id: r.key,
              title: __('결제상태'),
              visible: r.isVisible === 'Y' ? true : false,
              onClickVisible: (e: boolean) =>
                log.debug('결제상태 visible click', e),
            }))
            .with({ key: 'cs_status' }, (r) => ({
              id: r.key,
              title: __('CS상태'),
              visible: r.isVisible === 'Y' ? true : false,
              onClickVisible: (e: boolean) =>
                log.debug('CS상태 visible click', e),
            }))
            .with({ key: 'tracking_number' }, (r) => ({
              id: r.key,
              title: __('운송장번호'),
              visible: r.isVisible === 'Y' ? true : false,
              onClickVisible: (e: boolean) =>
                log.debug('운송장번호 visible click', e),
            }))
            .with({ key: 'memo_count' }, (r) => ({
              id: r.key,
              title: __('메모'),
              visible: r.isVisible === 'Y' ? true : false,
              onClickVisible: (e: boolean) =>
                log.debug('메모 visible click', e),
            }))
            .with({ key: 'delivery_type' }, (r) => ({
              id: r.key,
              title: __('배송타입'),
              visible: r.isVisible === 'Y' ? true : false,
              onClickVisible: (e: boolean) =>
                log.debug('배송타입 visible click', e),
            }))
            .with({ key: 'cash_receipt' }, (r) => ({
              id: r.key,
              title: __('현금영수증'),
              visible: r.isVisible === 'Y' ? true : false,
              onClickVisible: (e: boolean) =>
                log.debug('현금영수증 visible click', e),
            }))
            .with({ key: 'delivery_type_cd' }, (r) => ({
              id: r.key,
              title: __('배송방식'),
              visible: r.isVisible === 'Y' ? true : false,
              onClickVisible: (e: boolean) =>
                log.debug('배송방식 visible click', e),
            }))
            // .with({ key: 'external_channel_order_status' }, (r) => ({
            //   id: r.key,
            //   title: __('채널 주문상태'),
            //   visible: r.isVisible === 'Y' ? true : false,
            //   onClickVisible: (e: boolean) =>
            //     log.debug('채널 주문상태 visible click', e),
            // }))
            .with({ key: 'external_channel_order_no' }, (r) => ({
              id: r.key,
              title: __('채널 주문번호'),
              visible: r.isVisible === 'Y' ? true : false,
              onClickVisible: (e: boolean) =>
                log.debug('채널 주문번호 visible click', e),
            }))
            .with({ key: 'delivery' }, (r) => ({
              id: r.key,
              title: __('배송지'),
              visible: r.isVisible === 'Y' ? true : false,
              onClickVisible: (e: boolean) =>
                log.debug('배송지 visible click', e),
            }))
            .otherwise(() => ({
              id: vc.key,
              title: vc.key,
              visible: true,
              onClickVisible: (e: boolean) => log.debug(vc.key, e),
            }))
        ),
        // orderNo는 항상 index가 1로 고정
        A.sort(byOrderNo)
      ),
    [data]
  )

  const onChanged = (li: { id: string; visible: boolean }[]) => {
    pipe(
      data,
      O.fromNullable,
      O.chainNullableK(
        flow((e) =>
          updateVisibleColumns(
            pipe(
              li,
              A.mapWithIndex(
                flow((index, item) => ({
                  key: C.snakeToCamel(item.id),
                  sortNo: index + 1,
                  isVisible: item.visible ? 'Y' : 'N',
                }))
              )
            )
          )({
            name: e.name,
            columnCondition: e.columnCondition,
            visibleColumns: [],
            ...(e.columnCondition
              ? { columnCondition: e.columnCondition }
              : {}),
          })
        )
      ),
      O.fold(() => undefined, flow(mutate))
    )
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <IconButton
          native={{ type: 'button' }}
          variant="secondary"
          aria-label={__('컬럼설정')}
          className="data-[state=open]:bg-clay-semantic-actionSecondaryPressed"
          icon={<Columns02 color="inherit" />}
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="end"
          sideOffset={8}
          className="shadow-clay-layer rounded-clay-medium overflow-hidden"
        >
          <ScrollArea className="h-[600px]">
            <OrderColumnSort
              list={list || []}
              onChanged={onChanged}
              {...props}
            />
          </ScrollArea>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
