import { useOrderListQueryParams } from '~/entities/order-list/order-list.model'
import { useSuspenseQuery } from '@tanstack/react-query'
import {
  orderListQueryOptions,
  TGetResOrderListDto,
} from '~/entities/order-list'
import { transformC3OrderListToUiOrderTable } from '~/container/orders/orders-fn'
import { OrdersTableBody } from './orders-table-body'
import React from 'react'
import { pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/Array'
import * as C from '@hyeon/calc-ts'
import { OrdersTableBodyEmpty } from '../orders-table-body-empty'
import { useSetRecoilState } from 'recoil'
import { OrderTableHeaderState } from '~/container/orders/orders-table/orders-table.store'
import { useActiveTab } from '~/shared/hooks/use-tab-code'

export function OrdersTableBodyContainer() {
  const ordersQueryString = useOrderListQueryParams()
  const activeTab = useActiveTab()

  const { data: orders } = useSuspenseQuery({
    ...orderListQueryOptions(ordersQueryString),
    select: ordersQuerySelector,
  })

  const columnOrderSortList = React.useMemo(
    () =>
      pipe(
        activeTab,
        (r) => r.visibleColumns,
        A.filter((r) => r.isVisible === 'Y'),
        A.map((r) => C.camelToSnake(r.key))
      ),
    [activeTab]
  )

  // ==========================
  // 테이블 정보
  // ==========================
  const set_orderTableHeader_title = useSetRecoilState(
    OrderTableHeaderState.title
  )
  React.useEffect(() => {
    set_orderTableHeader_title(() => activeTab.name)
  }, [activeTab, set_orderTableHeader_title])

  if (orders.list.length === 0) {
    return <OrdersTableBodyEmpty />
  }
  return (
    <OrdersTableBody
      data={orders.list}
      columnOrderSortList={columnOrderSortList}
    />
  )
}

function ordersQuerySelector(data: ApiResponse<TGetResOrderListDto>) {
  return transformC3OrderListToUiOrderTable(data.data)
}
