import React from 'react'
import { useParams } from 'react-router-dom'

/**
 * pageNumber 주소 파라미터를 정수로 변환하여 반환합니다.
 */
export function usePageNumber(): number {
  const pageNumberParams = useParams().pageNumber

  const pageNumber = React.useMemo(() => {
    if (pageNumberParams === undefined) {
      return 1
    }
    return parseInt(pageNumberParams)
  }, [pageNumberParams])

  return pageNumber
}
