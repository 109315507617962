import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPatchResCashReceiptDto,
  type TPatchPayloadCashReceiptDto,
  patchResCashReceiptDto,
  patchPayloadCashReceiptDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/HTXnyU
 * @description 현금영수증 발급 정보 변경
 */
export function patchCashReceipt({ paymentCode }: { paymentCode: string }) {
  return async function (body: TPatchPayloadCashReceiptDto) {
    type T = TPatchResCashReceiptDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/payment/${paymentCode}/cash-receipt`, body)
      .then((res) => res.data)

    try {
      patchResCashReceiptDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}
