import React from 'react'
import { Modal, ModalProps } from '@imwebme/clay-components'
import { useModals } from '~/router'
import { match, P } from 'ts-pattern'

export default function ({ children }: React.PropsWithChildren) {
  const modals = useModals()
  const handleModalClose = () => modals.close()

  const width = match(modals.current)
    .with('/export/:tabCode/excel', () => 'medium')
    .otherwise(() => 'full') as ModalProps['width']

  const fullHeight = match(modals.current)
    .with('/export/:tabCode/excel', () => false)
    .otherwise(() => true)

  return (
    <Modal
      isOpen
      setClose={handleModalClose}
      width={width}
      fullHeight={fullHeight}
    >
      {children}
    </Modal>
  )
}
