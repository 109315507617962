import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import {
  type TPostResBulkActionSectionDeliveryHoldApplyAllSchema,
  type TPostPayloadBulkActionSectionDeliveryHoldApplyAllSchema,
  postResBulkActionSectionDeliveryHoldApplyAllSchema,
} from '.'
import * as Sentry from '@sentry/react'

const axios = ApiClient()

export async function postBulkActionSectionDeliveryHoldApplyAll({
  body,
}: {
  body: TPostPayloadBulkActionSectionDeliveryHoldApplyAllSchema
}) {
  type R = typeof body
  type T = TPostResBulkActionSectionDeliveryHoldApplyAllSchema
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/section/delivery/hold/all', body)
    .then((res) => res.data)

  try {
    postResBulkActionSectionDeliveryHoldApplyAllSchema.parse(result.data)
  } catch (error) {
    Sentry.captureException(error)
  }
  return result.data
}
