import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Clay, Flex } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { OrdersHeader } from './orders-header'
import { OrdersTab } from '~/container/orders/orders-tab/orders-tab'
import { OrdersTable } from './orders-table'
import { OrderToast, OrderToastPack } from './order-toast'
import { OrdersTabSkeleton } from './orders-skeleton/orders-tab-skeleton'
import { PageTopBar } from '~/shared/components/page-top-bar/page-top-bar'
import { __ } from '~/shared/i18n'
import { useTabCode } from '~/shared/hooks/use-tab-code/use-tab-code'
import { useOrderAtom } from './orders-table/orders-table-body/orders-table-body.store'
import { DialogDeliveryContainer } from '~/container/orders/view/dialog-delivery/dialog-delivery.container'
import { DialogDeliverReleaseContainer } from '~/container/orders/view/dialog-deliver-release/dialog-deliver-release.container'
import { Notification } from '~/widgets/notification'
import { useResetAllAtom } from '~/container/order-create/order-create.store'
import { useCreatedModalAtom } from './orders-header/created-orders-modal/created-orders-modal.store'
import { usePermissionAtom } from '~/app/provider/permission'
import { OnboardingTooltip } from './view/onboarding-tooltip/onboarding-tooltip'
import { useModals } from '~/router'
import { useOrderSearchQuery } from '~/shared/hooks/use-order-search-query'
import { useTrackOrdersPage } from './orders.hook'

export default function Orders() {
  const tabCode = useTabCode()
  const selectedList = useOrderAtom.codeSelection.get({ tabCode }) // 선택된 주문서의 데이터
  const navigate = useNavigate()
  const resetAllOrderCreateAtom = useResetAllAtom()
  const [_createdModal, setCreatedModalOpen] = useCreatedModalAtom()
  const permission = usePermissionAtom()
  const modals = useModals()
  const [orderSearchQuery] = useOrderSearchQuery()
  useTrackOrdersPage()

  return (
    <>
      <DialogDeliveryContainer />
      <DialogDeliverReleaseContainer />
      <OrderToast open={(selectedList.value || []).length > 0} />
      <OrderToastPack />

      <PageTopBar
        pageTitle={__('주문')}
        menuItems={[
          {
            onSelect: () =>
              modals.open('/export/:tabCode/excel', {
                params: { tabCode },
                ...(orderSearchQuery && { search: { q: orderSearchQuery } }),
              }),
            children: __('내보내기'),
          },
          {
            onSelect: () =>
              window.open(
                `${
                  ImwebOAuth2ClientAPI.getInfo().siteUrl
                }/admin/shopping/order-v1?subPath=${btoa('/onboarding')}`
              ),
            children: __('튜토리얼'),
          },
          ...(permission.adminShoppingOms.view === 'Y' &&
          permission.adminShoppingOms.edit === 'Y'
            ? [
                {
                  onSelect: () => {
                    resetAllOrderCreateAtom()
                    navigate('/order-create')
                  },
                  children: __('주문 생성'),
                },
              ]
            : []),
          {
            onSelect: () => {
              setCreatedModalOpen(true)
            },
            children: __('관리자 생성 주문'),
          },
        ]}
      />

      <div className="pt-clay-16 desktop:pt-0 px-clay-3 md:px-clay-4 desktop:px-clay-10">
        <Clay position="relative">
          <OrdersHeader />
          <OnboardingTooltip step={0} />
        </Clay>

        {/* 공지영역 */}
        <Notification />

        <Flex.Column gap={vars.spacing[3]}>
          <React.Suspense fallback={<OrdersTabSkeleton />}>
            <Clay position="relative">
              <OrdersTab />
              <OnboardingTooltip step={1} />
            </Clay>
          </React.Suspense>

          <OrdersTable />
        </Flex.Column>
      </div>
    </>
  )
}
