import React from 'react'
import { NumOrdersBadgeContainer } from './num-orders-badge.container'
import { Skeleton } from '@imwebme/clay-components'

export function NumOrdersBadgePack() {
  return (
    <React.Suspense
      fallback={<Skeleton width="50px" height="24px" rounded="full" />}
    >
      <NumOrdersBadgeContainer />
    </React.Suspense>
  )
}
