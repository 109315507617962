import * as O from 'fp-ts/Option'
import { pipe, flow } from 'fp-ts/function'
import {
  Button,
  ButtonGroup,
  Clay,
  Flex,
  Modal,
  OptionItem,
  OptionList,
  SafeImageLoading,
  Select,
  Tag,
  Textfield,
  Typography,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { useModals } from '~/router'
import { __ } from '~/shared/i18n'
import { useProductDiscountModalAtom, useDiscountPrice, PriceAction } from '.'
import ProdThumbFallback from '~/shared/components/icon/product-thumb-fallback.svg?react'
import { Trans } from 'react-i18next'
import { toast } from 'react-toastify'
import { Minus } from '@imwebme/clay-icons'

/** 상품 판매가 할인 모달 */
export function ProductDiscountModal() {
  const modals = useModals()
  const [modalData] = useProductDiscountModalAtom()
  const [localState, localDispatch] = useDiscountPrice({
    baseItemPrice: modalData.baseItemPrice,
    type: modalData.type || 'fixed',
    discountValue: modalData.discountValue || 0,
  })

  return (
    <Modal width="small" isOpen={true} setClose={modals.close}>
      <Modal.Header text={__('판매가 할인')} />
      <Modal.Body>
        <Flex.Column rowGap={vars.spacing[5]}>
          {/* 상품 정보 */}
          <Flex columnGap={vars.spacing[3]}>
            <Clay
              width="48px"
              height="48px"
              overflow="hidden"
              borderRadius={vars.rounded.medium}
              flexShrink={0}
            >
              <SafeImageLoading
                src={modalData.prodImg ?? undefined}
                fallback={<ProdThumbFallback />}
              />
            </Clay>

            <Flex.Column rowGap={vars.spacing[15]}>
              <Typography variant="label-medium">
                {modalData.prodName}
              </Typography>
              {modalData.optionLabels && (
                <Flex gap={vars.spacing[1]} flexWrap="wrap">
                  {modalData.optionLabels.map((label, idx) => (
                    <Tag
                      key={idx}
                      variant="other"
                      text={label}
                      sx={{
                        '--clay-tag-background-color':
                          'rgba(113, 118, 128, 0.10)',
                        '--clay-tag-color': vars.semantic.color.textSub,
                      }}
                    />
                  ))}
                </Flex>
              )}
            </Flex.Column>
          </Flex>

          <div className="space-y-clay-2">
            {/* 판매가 할인 타입 */}
            <Select>
              <Select.Trigger>
                <Select.Input
                  placeholder="placeholder"
                  value={
                    localState.type === 'fixed'
                      ? __('차감 금액 입력')
                      : __('할인율 적용')
                  }
                />
              </Select.Trigger>

              <Select.Portal
                sx={{
                  pointerEvents: 'auto',
                }}
              >
                <OptionList
                  popOver
                  setValue={(v) => {
                    localDispatch({
                      type: v as PriceAction['type'],
                      payload: '',
                    })
                  }}
                  sx={{
                    maxHeight: '200px',
                    overflowY: 'auto',
                  }}
                >
                  <OptionItem text={__('차감 금액 입력')} value="fixed" />
                  <OptionItem text={__('할인율 적용')} value="percent" />
                </OptionList>
              </Select.Portal>
            </Select>
            {/* 변경할 판매가 입력 */}
            <Textfield.Set>
              <Textfield.Box>
                {localState.type === 'fixed' && (
                  <Textfield.Addon>
                    <Minus />
                  </Textfield.Addon>
                )}
                <Textfield.Input
                  value={
                    localState.discountValue?.toLocaleString('ko-KR') ?? ''
                  }
                  onChangeText={(v) =>
                    localDispatch({ payload: v.replaceAll(/[^0-9]/g, '') })
                  }
                  isValid={localState.error ? false : undefined}
                  placeholder={__('{{price, 3comma}}', {
                    price: modalData.baseItemPrice,
                  })}
                />
                <Textfield.Addon>
                  <Typography variant="body-medium" colorToken="textSub">
                    {localState.type === 'fixed'
                      ? __('{{currency, currency}}', {
                          currency: modalData.currency,
                        })
                      : '%'}
                  </Typography>
                </Textfield.Addon>
              </Textfield.Box>

              <Textfield.HelperText>{localState.error}</Textfield.HelperText>
            </Textfield.Set>
          </div>

          {/* 판매가 결과 */}
          <div className="grid text-right">
            <div>
              {pipe(
                localState.itemPrice,
                O.fromNullable,
                O.fold(
                  () => null,
                  flow((e) =>
                    e !== localState.baseItemPrice ? (
                      <Typography
                        variant="body-medium"
                        colorToken="textDisabled"
                        as="span"
                        sx={{ textDecoration: 'line-through' }}
                      >
                        {__('{{price, 3comma}}{{currency, currency}}', {
                          price: localState.baseItemPrice,
                        })}
                      </Typography>
                    ) : null
                  )
                )
              )}
            </div>
            <div>
              <Trans
                i18nKey="<t1>{{price, 3comma}}</t1> <t2>{{currency, currency}}</t2>"
                values={{
                  price: localState.itemPrice ?? localState.baseItemPrice,
                }}
                components={{
                  t1: <Typography variant="heading-large-bold" as="span" />,
                  t2: <Typography variant="body-medium" as="span" />,
                }}
              />
            </div>
          </div>
        </Flex.Column>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button
            native={{ type: 'button' }}
            text={__('취소')}
            variant="outlined"
            fullWidth
            onClick={() => modals.close()}
          />
          <Button
            native={{ type: 'button' }}
            text={__('반영')}
            variant="primary"
            fullWidth
            isDisabled={!!localState.error || localState.itemPrice === null}
            onClick={() => {
              modalData.handleSubmit(
                localState.itemPrice || localState.baseItemPrice,
                {
                  type: localState.type,
                  discountValue: localState.discountValue as number,
                }
              )
              toast.success(__('판매가를 수정했어요'))
              modals.close()
            }}
          />
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  )
}
