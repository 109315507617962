import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPatchResCancelCompleteDto,
  type TPatchPayloadCancelCompleteDto,
  patchResCancelCompleteDto,
  patchPayloadCancelCompleteDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/8ywXpt
 * @description [cancel] 외부 주문서 섹션 취소완료 처리 (실제 해당 품목은 취소승인 됩니다)
 */
export function patchCancelComplete({ orderCode }: { orderCode: string }) {
  return async function (body: TPatchPayloadCancelCompleteDto) {
    type T = TPatchResCancelCompleteDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/external-order/${orderCode}/sections/cancel/complete`, body)
      .then((res) => res.data)

    try {
      patchResCancelCompleteDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}
