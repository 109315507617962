import React from 'react'
import { LoaderFunction, useNavigate } from 'react-router-dom'
import { useTabCode } from '~/shared/hooks/use-tab-code'
import { queryClient } from '~/app/provider'
import {
  orderSearchTabDetailQueryOptions,
  orderSearchTabQueryOptions,
} from '~/entities/order-search-tab'
import { 상수_기본탭코드 } from '~/entities/@x'
import { menuBannerQueryOptions } from '~/entities/site/menu-banner'

export const Loader: LoaderFunction = async () => {
  queryClient.prefetchQuery(orderSearchTabQueryOptions()) // 탭목록 조회
  queryClient.prefetchQuery(menuBannerQueryOptions()) // 공지사항 조회
  const res = await queryClient.ensureQueryData(
    orderSearchTabDetailQueryOptions(상수_기본탭코드)
  ) // 단일탭 조회

  queryClient.setQueryData(
    orderSearchTabDetailQueryOptions(res.data.adminOrderSearchTabCode).queryKey,
    res
  )

  return null
}

export default function Page() {
  const router = useNavigate()
  const tabCode = useTabCode()

  React.useEffect(() => {
    router(`/orders/tab/${tabCode}`)
  }, [])
  return null
}
