import { z } from 'zod'
import {
  ADDRESS_TYPE,
  DATE_FORMAT,
  DUPULICATE_ITEM_INFO_TYPE,
  PHONE_NUMBER_FORMAT,
  SELECTION,
} from '.'

export const templateInfoSettingDto = z.object({
  templateName: z.string().nullish(),
  addressType: z.nativeEnum(ADDRESS_TYPE),
  phoneNumberFormat: z.nativeEnum(PHONE_NUMBER_FORMAT),
  dateFormat: z.nativeEnum(DATE_FORMAT),
  dupulicateItemInfoType: z.nativeEnum(DUPULICATE_ITEM_INFO_TYPE).nullish(),
})

export const templateInfoSelectionItemDto = z.object({
  column: z.nativeEnum(SELECTION),
  sort: z.number(),
})
const templateInfoSelection = z.array(templateInfoSelectionItemDto)

const templateInfoSections = z.object({
  selection: templateInfoSelection,
})

export const templateInfoDto =
  templateInfoSettingDto.merge(templateInfoSections)
