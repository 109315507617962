import { TOrderItemDto } from '~/entities/order-list'

/**
 * 주문서의 총 품목 수량(orderSectionItemList.qty 의 총합)
 * @param order
 * @returns
 */
export const getTotalOrderItemQty = (order: TOrderItemDto): number =>
  order.orderSectionList.reduce(
    (sum, orderSection) =>
      sum +
      (orderSection.orderSectionItemList ?? []).reduce(
        (itemSum, orderItem) => itemSum + orderItem.qty,
        0
      ),
    0
  )
