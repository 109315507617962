import {
  ColumnDef,
  ColumnOrderState,
  type ColumnPinningState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { pipe, flow } from 'fp-ts/lib/function'
import * as A from 'fp-ts/lib/Array'
import * as E from 'fp-ts/lib/Either'
import * as R from 'fp-ts/Record'
import * as O from 'fp-ts/Option'
import * as C from '@hyeon/calc-ts'
import type { OrderTableNS } from './order-table.namespace'
import { __, __g, __c } from '~/shared/i18n'
import { Link } from '~/shared/components/link'
import {
  Checkbox,
  Clay,
  Flex,
  Tag,
  TextButton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@imwebme/clay-components'
import { Trans } from 'react-i18next'
import {
  OrdererPreviewPack,
  OrderMemoPreviewPack,
  OrderInvoicePreviewPack,
  OrderPricePreviewPack,
  PaymentMethodPreviewPack,
  CashReceiptPreviewPack,
} from '~/container/order-preview'
import { useOrderAtom } from './orders-table-body.store'
import {
  useActiveTab,
  useTabCode,
} from '~/shared/hooks/use-tab-code/use-tab-code'
import { OrderSectionDto } from '~/entities/order-detail'
import { isEmptyObject } from '~/shared/utils'
import { cn } from '~/shared/utils'
import { useDevice } from '~/shared/hooks/use-device'
import { OrderItemsTableHeader, OrderItems } from './주문품목컬럼'
import { Delivery, DeliveryTableHeader } from './배송지컬럼'
import { Table } from '~/shared/components/ui/table'
import { vars } from '@imwebme/clay-token'
import { atom, useRecoilState, useRecoilValue } from 'recoil'
import { orderTableTotalCountState } from '../orders-table.store'
import { Truck } from '@imwebme/clay-icons'
import {
  CsStatusTag,
  PaymentStatusTag,
  SectionStatusTag,
} from '~/shared/domain-components'

interface ISelectAllOrderButtonProps {
  selectAllOrders: boolean
  handleSelectAllOrders: () => void
}

const SelectAllOrderButton = ({
  selectAllOrders,
  handleSelectAllOrders,
}: ISelectAllOrderButtonProps) => (
  <TextButton
    text={selectAllOrders ? __('현재 페이지만 선택') : __('모든 주문 선택')}
    variant="primary"
    onClick={handleSelectAllOrders}
  />
)

interface DataTableProps<TData, TValue> {
  columns?: ColumnDef<TData, TValue>[]
  data: TData[]
}

const useXScroll = (elementRef: React.RefObject<HTMLElement>): boolean => {
  const [isScrolledHorizontally, setIsScrolledHorizontally] = useState(false)

  useEffect(() => {
    const elm = elementRef.current
    if (!elm) {
      throw new Error('elementRef.current is null')
    }
    const handleScroll = () => {
      const { scrollLeft } = elm
      setIsScrolledHorizontally(scrollLeft > 0)
    }

    const element = elm
    element.addEventListener('scroll', handleScroll)

    return () => {
      element.removeEventListener('scroll', handleScroll)
    }
  }, [elementRef])

  return isScrolledHorizontally
}

export interface OrderTableProps
  extends PropsWithChildren<
    DataTableProps<OrderTableNS.columns, OrderTableNS.columns>
  > {
  // action
  columnOrderSortList?: string[]
}

const ORDER_TABLE_SELECT_ALL_ORDERS_KEY =
  'KEY_ORDER_TABLE_SELECT_ALL_ORDERS' as const
export const orderTableSelectAllOrdersState = atom<boolean>({
  key: ORDER_TABLE_SELECT_ALL_ORDERS_KEY,
  default: false,
})

function RowLink({
  channel,
  orderCode,
  children,
  className,
  ...props
}: React.PropsWithChildren<{
  channel: OrderTableNS.columns['channel_type']
  orderCode: string
  className?: string
}>) {
  return (
    <Link
      to="/order/:saleChannel/:orderCode"
      params={{ orderCode, saleChannel: pipe(channel, String) }}
      className={cn('w-full h-[44px] py-clay-3 px-clay-2 block', className)}
      {...props}
    >
      {children}
    </Link>
  )
}

export function OrdersTableBody({
  data,
  columnOrderSortList,
}: OrderTableProps) {
  const tableContainerRef = React.useRef<HTMLDivElement>(null)
  const [_, setSelectAllPageRows] = useState(false)
  const [selectAllOrders, setSelectAllOrders] = useRecoilState(
    orderTableSelectAllOrdersState
  )
  const tableTotal = useRecoilValue(orderTableTotalCountState)

  /**
   * columns 건들지 마시오. cell 수정하고싶다면 order-fn에서 작업
   */
  const columns: ColumnDef<OrderTableNS.columns>[] = React.useMemo(
    () => [
      {
        id: 'head',
        header: () => <div />,
        cell: () => <div />,
        enableSorting: false,
        enableHiding: false,
        size: 16,
      },
      {
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            checked={table.getSelectedRowModel().rows.length > 0}
            onChangeChecked={(value: boolean) => {
              setSelectAllPageRows(value)
              table.toggleAllPageRowsSelected(value)
              setSelectAllOrders(false)
            }}
            aria-label="Select all"
            isIndeterminate={
              table.getSelectedRowModel().rows.length > 0 &&
              !table.getIsAllPageRowsSelected()
            }
          />
        ),
        cell: ({ row }) => (
          <div className="w-full flex items-center h-[44px]">
            <Checkbox
              checked={row.getIsSelected()}
              onChangeChecked={(value: boolean) => {
                row.toggleSelected(value)
                setSelectAllOrders(false)
              }}
              aria-label="Select row"
            />
          </div>
        ),
        enableSorting: false,
        enableHiding: false,
        size: 24,
      },
      {
        accessorKey: 'order_no',
        header: () => <div>{__('주문번호')}</div>,
        cell: ({ row }) => {
          const value = row.getValue('order_no') as number
          const channel = row.original.channel_type

          return (
            <RowLink
              channel={channel}
              orderCode={row.original.order_code}
              className="pl-clay-2"
            >
              {value}
            </RowLink>
          )
        },
        size: 170,
      },
      {
        accessorKey: 'channel',
        header: () => <div>{__('판매채널')}</div>,
        cell: ({ row }) => {
          const channel = row.original.channel_type
          return (
            <RowLink
              channel={channel}
              orderCode={row.original.order_code}
              className="line-clamp-1"
            >
              <div className="truncate">{row.getValue('channel')}</div>
            </RowLink>
          )
        },
        size: 132,
      },
      {
        accessorKey: 'item_count',
        header: () => <OrderItemsTableHeader />,
        cell: ({ row }) => {
          const value = row.getValue<OrderSectionDto[] | undefined>(
            'item_count'
          )

          const channel = row.original.channel_type

          if (!value) {
            return (
              <RowLink
                channel={channel}
                orderCode={row.original.order_code}
              ></RowLink>
            )
          }
          return (
            <OrderItems
              orderSectionList={value}
              totalQty={row.original.num_items}
              orderCode={row.original.order_code}
              isExternalOrder={channel !== 1}
            />
          )
        },
      },
      {
        accessorKey: 'order_date',
        header: () => __('주문일'),
        cell: ({ row }) => {
          const value = row.getValue('order_date') as string | React.ReactNode
          const channel = row.original.channel_type
          return (
            <RowLink channel={channel} orderCode={row.original.order_code}>
              {value}
            </RowLink>
          )
        },
        size: 108,
      },
      {
        accessorKey: 'payment_date',
        header: () => __('결제일'),
        cell: ({ row }) => {
          const value = row.getValue('payment_date') as string | React.ReactNode

          const channel = row.original.channel_type
          return (
            <RowLink channel={channel} orderCode={row.original.order_code}>
              {value}
            </RowLink>
          )
        },
        size: 108,
      },
      {
        accessorKey: 'orderer_name',
        header: () => __('구매자'),
        cell: ({ row }) => {
          const value = row.getValue('orderer_name') as string | React.ReactNode
          const channel = row.original.channel_type

          if (!value) {
            return (
              <RowLink channel={channel} orderCode={row.original.order_code}>
                {value}
              </RowLink>
            )
          }
          return (
            <div data-asiodkasodksao={channel}>
              <OrdererPreviewPack
                memberCode={row.original.member_code}
                saleChannel={row.original.channel}
                orderCode={row.original.order_code}
                isMember={row.original.is_member}
                isExternalOrder={channel !== 1}
              >
                {row.original.is_member === 'Y' ? (
                  value
                ) : (
                  <span className="text-clay-semantic-textDisabled">
                    {value}
                  </span>
                )}
              </OrdererPreviewPack>
            </div>
          )
        },
        size: 108,
      },
      {
        accessorKey: 'paid_price',
        header: () => (
          <div className="text-right pr-clay-5">{__('최종 실결제금액')}</div>
        ),
        cell: ({ row }) => {
          const value = row.getValue('paid_price') as string | React.ReactNode
          const channel = row.original.channel_type

          if (value === '-') {
            return (
              <RowLink
                channel={channel}
                orderCode={row.original.order_code}
              ></RowLink>
            )
          }
          return (
            <OrderPricePreviewPack
              orderCode={row.original.order_code}
              isExternalOrder={channel !== 1}
            >
              {value}
            </OrderPricePreviewPack>
          )
        },
      },
      {
        accessorKey: 'payment_method',
        header: () => <div>{__('결제수단')}</div>,
        cell: ({ row }) => {
          const value = row.getValue('payment_method') as
            | string
            | React.ReactNode

          const channel = row.original.channel_type

          if (!value) {
            return (
              <RowLink channel={channel} orderCode={row.original.order_code}>
                {value}
              </RowLink>
            )
          }
          return (
            <PaymentMethodPreviewPack
              orderCode={row.original.order_code}
              isExternalOrder={channel !== 1}
            >
              {value}
            </PaymentMethodPreviewPack>
          )
        },
        size: 108,
      },
      {
        accessorKey: 'transaction_status',
        header: () => <div>{__('거래상태')}</div>,
        cell: ({ row }) => {
          const value = row.getValue('transaction_status') as
            | string
            | React.ReactNode

          const channel = row.original.channel_type

          return (
            <RowLink channel={channel} orderCode={row.original.order_code}>
              {value}
            </RowLink>
          )
        },
        size: 86,
      },
      {
        accessorKey: 'order_status',
        header: () => <div>{__('주문상태')}</div>,
        cell: ({ row }) => {
          const hasDeliveryHold = row.original.has_delivery_hold
          const sectionStatus = row.original.section_status
          const evenRow = (row.index + 1) % 2 === 0
          const channel = row.original.channel_type
          const paymentStatusCd = row.original.payment_status

          return (
            <RowLink channel={channel} orderCode={row.original.order_code}>
              <Flex gap="4px">
                <SectionStatusTag
                  statusCd={sectionStatus}
                  paymentStatusCd={paymentStatusCd}
                  toggleDimmed={evenRow}
                />
                {hasDeliveryHold && (
                  <Tooltip>
                    <TooltipTrigger action="hover">
                      <Tag
                        variant="warning"
                        text={__('보류')}
                        leadingIcon={<Truck />}
                      />
                    </TooltipTrigger>
                    <TooltipContent hasArrow position="top">
                      배송보류 품목이 있어요
                    </TooltipContent>
                  </Tooltip>
                )}
              </Flex>
            </RowLink>
          )
        },
        size: 108,
      },
      {
        accessorKey: 'payment_status',
        header: () => <div>{__('결제상태')}</div>,
        cell: ({ row }) => {
          const evenRow = (row.index + 1) % 2 === 0
          const isRequestPayment = row.original.is_request_payment
          const paymentStatusCd = row.original.payment_status
          const channel = row.original.channel_type

          return (
            <RowLink channel={channel} orderCode={row.original.order_code}>
              {isRequestPayment === 'Y' ? (
                <Tag variant="warning" text={__('결제요청')} />
              ) : (
                <PaymentStatusTag
                  statusCd={paymentStatusCd}
                  toggleDimmed={evenRow}
                />
              )}
            </RowLink>
          )
        },
        size: 108,
      },
      {
        accessorKey: 'cs_status',
        header: () => <div>{__('CS상태')}</div>,
        cell: ({ row }) => {
          const evenRow = (row.index + 1) % 2 === 0
          const csStatusCd = row.original.cs_status

          const channel = row.original.channel_type
          return (
            <RowLink channel={channel} orderCode={row.original.order_code}>
              <CsStatusTag statusCd={csStatusCd} toggleDimmed={evenRow} />
            </RowLink>
          )
        },
        size: 108,
      },
      {
        accessorKey: 'tracking_number',
        header: () => <div>{__('운송장번호')}</div>,
        cell: ({ row }) => {
          const value = row.getValue('tracking_number') as
            | string
            | React.ReactNode
          const channel = row.original.channel_type

          if (!value) {
            return (
              <RowLink channel={channel} orderCode={row.original.order_code}>
                {value}
              </RowLink>
            )
          }
          return (
            <OrderInvoicePreviewPack
              orderCode={row.original.order_code}
              isExternalOrder={channel !== 1}
            >
              {value}
            </OrderInvoicePreviewPack>
          )
        },
      },
      {
        accessorKey: 'memo_count',
        header: () => <div>{__('메모')}</div>,
        cell: ({ row }) => {
          const value = row.getValue('memo_count') as string | React.ReactNode

          const channel = row.original.channel_type

          return (
            <OrderMemoPreviewPack
              orderCode={row.original.order_code}
              isExternalOrder={channel !== 1}
            >
              {value}
            </OrderMemoPreviewPack>
          )
        },
        size: 62,
      },
      {
        accessorKey: 'delivery_type',
        header: () => <div>{__('배송타입')}</div>,
        cell: ({ row }) => {
          const value = row.getValue('delivery_type') as
            | string
            | React.ReactNode

          const channel = row.original.channel_type
          return (
            <RowLink channel={channel} orderCode={row.original.order_code}>
              {value}
            </RowLink>
          )
        },
        size: 100,
      },
      {
        accessorKey: 'cash_receipt',
        header: () => <div>{__('현금영수증')}</div>,
        cell: ({ row }) => {
          const value = row.getValue('cash_receipt') as string

          const channel = row.original.channel_type

          if (!value) {
            return (
              <RowLink channel={channel} orderCode={row.original.order_code}>
                {value}
              </RowLink>
            )
          }
          return (
            <CashReceiptPreviewPack orderCode={row.original.order_code}>
              {value}
            </CashReceiptPreviewPack>
          )
        },
      },
      {
        accessorKey: 'delivery_type_cd',
        header: () => <div>{__('배송방식')}</div>,
        cell: ({ row }) => {
          const value = row.getValue<OrderSectionDto[] | undefined>(
            'delivery_type_cd'
          )
          const channel = row.original.channel_type
          return (
            <RowLink channel={channel} orderCode={row.original.order_code}>
              {value?.length ? __g(value[0]?.deliveryTypeCd) : ''}
            </RowLink>
          )
        },
      },
      // {
      //   accessorKey: 'external_channel_order_status',
      //   header: () => <div>{__('채널 주문상태')}</div>,
      //   cell: ({ row }) => {
      //     const value = row.getValue<T상수_외부채널주문_주문상태>(
      //       'external_channel_order_status'
      //     )

      //     const channel = row.original.channel_type

      //     if (!value) {
      //       return (
      //         <Link
      //           to={`/${channel}/${row.original.external_channel_order_status}`}
      //           className="w-full h-[44px] py-clay-3 px-clay-2 block"
      //         >
      //           {value}
      //         </Link>
      //       )
      //     }
      //     return (
      //       <Link
      //         to={`/${channel}/${row.original.order_code}`}
      //         className="w-full h-[44px] py-clay-3 px-clay-2 block"
      //       >
      //         <ExternalChannelOrderStatusTag statusCd={value} toggleDimmed={(row.index + 1) % 2 === 0} />
      //       </Link>
      //     )
      //   },
      // },
      {
        accessorKey: 'external_channel_order_no',
        header: () => <div>{__('채널 주문번호')}</div>,
        cell: ({ row }) => {
          const value = row.getValue('external_channel_order_no') as string
          const channel = row.original.channel_type

          if (!value) {
            return (
              <RowLink
                channel={channel}
                orderCode={row.original.external_channel_order_no}
              >
                {value}
              </RowLink>
            )
          }
          return (
            <RowLink channel={channel} orderCode={row.original.order_code}>
              {value}
            </RowLink>
          )
        },
      },
      {
        accessorKey: 'delivery',
        header: () => <DeliveryTableHeader />,
        cell: ({ row }) => {
          const delivery = row.original.delivery
          const channel = row.original.channel_type
          const orderCode = row.original.order_code

          if (typeof delivery === 'string') {
            return <RowLink {...{ channel, orderCode }}>{delivery}</RowLink>
          }
          return <Delivery {...delivery} />
        },
      },
      {
        id: 'tail',
        header: () => <div />,
        cell: () => <div />,
        enableSorting: false,
        enableHiding: false,
      },
    ],
    []
  )
  const isScrolled = useXScroll(tableContainerRef)
  const [columnPinning, setColumnPinning] = React.useState<ColumnPinningState>({
    left: ['head', 'select', 'order_no'],
  })
  const [columnOrder, setColumnOrder] = React.useState<ColumnOrderState>(
    columnOrderSortList || []
  )
  const device = useDevice()

  // 선택된 주문서의 데이터
  const tabCode = useTabCode()
  const setSelectedList = useOrderAtom.codeSelection.set({ tabCode })
  const [rowSelection, setRowSelection] = useOrderAtom.rowSelection.state({
    tabCode,
  })
  const activeTab = useActiveTab()

  const columnVisibility = React.useMemo(
    () =>
      pipe(
        activeTab,
        (e) => e.visibleColumns,
        A.map((e): [string, boolean] => [
          C.camelToSnake(e.key),
          e.isVisible === 'Y',
        ]),
        A.reduce({}, (acc, [key, value]) => R.upsertAt(key, value)(acc))
      ),
    [activeTab]
  )

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: setColumnOrder,
    onColumnPinningChange: setColumnPinning,
    state: {
      columnVisibility,
      rowSelection,
      columnPinning: columnPinning,
      columnOrder,
    },
    defaultColumn: {
      minSize: 16,
      size: Number.MAX_SAFE_INTEGER,
    },
  })

  const handleSelectAllOrders = () => {
    setSelectAllOrders(selectAllOrders === true ? false : true)
    setSelectAllPageRows(true)
    table.toggleAllPageRowsSelected(true)
  }

  const tableHeaderRefList = React.useRef<(HTMLElement | null)[]>([])

  // 선택된 주문서 상태만 넘겨준다
  useEffect(() => {
    pipe(
      rowSelection,
      isEmptyObject,
      O.fold(
        () => setSelectedList((prev) => ({ ...prev, value: undefined })),
        flow(
          R.keys,
          A.map((key) => pipe(data[Number(key)], (e) => e.order_code)),
          (e) => setSelectedList((prev) => ({ ...prev, value: e }))
        )
      )
    )
  }, [rowSelection])

  // order-column-sort에서 변경된 column 순서를 order-table에서도 업데이트한다.
  useEffect(() => {
    if (columnOrderSortList) {
      setColumnOrder(columnOrderSortList)
    }
  }, [columnOrderSortList, setColumnOrder])

  // ==========================
  // Render
  // ==========================
  return (
    <div className="w-full semantic-p14">
      <div
        className={cn(
          table.getRowModel().rows?.length !== 0
            ? 'overflow-auto'
            : 'overflow-hidden'
        )}
        ref={tableContainerRef}
      >
        <Table className="h-full">
          <Table.Header>
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header, idx) => (
                  <Table.Cell
                    ref={(el) => (tableHeaderRefList.current[idx] = el)}
                    key={header.id}
                    className={cn(
                      'whitespace-nowrap text-clay-semantic-textSub px-[8px] py-[12px] bg-white',
                      header.column.id === 'select' &&
                        'px-[4px] leading-none !z-[11]',
                      (header.column.id === 'item_count' ||
                        header.column.id === 'delivery') &&
                        'px-0 py-0',
                      header.column.getIsPinned() &&
                        header.column.id === 'order_no' &&
                        isScrolled &&
                        Object.keys(rowSelection).map(
                          (key) => data[Number(key)]
                        ).length === 0
                        ? 'relative'
                        : null,
                      header.column.getIsPinned() &&
                        header.column.id === 'order_no' &&
                        isScrolled &&
                        Object.keys(rowSelection).map(
                          (key) => data[Number(key)]
                        ).length === 0 &&
                        'after:content-[""] after:absolute after:top-0 after:right-0 after:w-[1px] after:h-full after:bg-[#DBDEE3]'
                    )}
                    style={{
                      width: header.column.id === 'tail' ? '100%' : 'auto',
                      minWidth:
                        header.column.getSize() === Number.MAX_SAFE_INTEGER
                          ? 'auto'
                          : `${header.column.getSize()}px`,
                      maxWidth:
                        header.column.id === 'channel' ||
                        header.column.id === 'orderer_name'
                          ? `${header.column.getSize()}px`
                          : 'none',
                      zIndex: header.column.getIsPinned() ? 10 : 'auto',
                      borderCollapse:
                        header.column.getIsPinned() && device.desktop
                          ? 'separate'
                          : 'collapse',
                      position:
                        header.column.getIsPinned() && device.desktop
                          ? 'sticky'
                          : 'static',
                      left: header.column.getIsPinned()
                        ? header.column.getStart('left')
                        : undefined,
                    }}
                    data-id={header.column.id}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    {header.column.id === 'select' &&
                      pipe(
                        Object.keys(rowSelection),
                        A.map((key) => data[Number(key)]),
                        (e) => (e.length < 1 ? E.left(e) : E.right(e)),
                        E.fold(
                          () => null,
                          flow((e) => {
                            const count = selectAllOrders
                              ? tableTotal
                              : e.length

                            return (
                              <div className="absolute left-full top-0 pl-[4px] bg-white h-full flex items-center w-screen">
                                <div className="[&>span]:text-clay-semantic-textPrimary text-[#15181E] semantic-p14">
                                  <Trans
                                    defaults="<span>{{ count, 3comma }}</span>개 선택됨"
                                    values={{ count }}
                                    count={count}
                                    components={{
                                      span: (
                                        <span className="text-clay-semantic-textPrimary" />
                                      ),
                                    }}
                                  />
                                </div>
                                <Clay padding={`0 ${vars.spacing[2]}`}>
                                  <SelectAllOrderButton
                                    selectAllOrders={selectAllOrders}
                                    handleSelectAllOrders={
                                      handleSelectAllOrders
                                    }
                                  />
                                </Clay>
                              </div>
                            )
                          })
                        )
                      )}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Header>
          <Table.Body>
            {table.getRowModel().rows?.length >= 0 &&
              table.getRowModel().rows.map((row) => (
                <Table.Row
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  className={cn(
                    'group whitespace-nowrap border-0 odd:bg-clay-semantic-surface even:bg-clay-semantic-surfaceSub',
                    'data-[state=selected]:bg-clay-semantic-surfaceHighlight [&:not([data-state=selected])]:hover:bg-clay-semantic-surfaceSub'
                  )}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Table.Cell
                      key={cell.id}
                      data-id={cell.column.id}
                      className={cn(
                        'p-0 bg-inherit overflow-visible align-top',
                        cell.column.id === 'select' &&
                          'px-[4px] leading-none !z-[11]',
                        cell.column.getIsPinned() &&
                          cell.column.id === 'order_no' &&
                          isScrolled
                          ? 'relative'
                          : null,
                        cell.column.getIsPinned() &&
                          cell.column.id === 'order_no' &&
                          isScrolled &&
                          "after:content-[''] after:absolute after:top-0 after:right-0 after:w-[1px] after:h-full after:bg-[#DBDEE3]"
                      )}
                      style={{
                        width: cell.column.id === 'tail' ? '100%' : 'auto',
                        maxWidth:
                          cell.column.id === 'channel' ||
                          cell.column.id === 'orderer_name'
                            ? `${cell.column.getSize()}px`
                            : undefined,
                        zIndex: cell.column.getIsPinned() ? 10 : 'auto',
                        borderCollapse:
                          cell.column.getIsPinned() && device.desktop
                            ? 'separate'
                            : 'collapse',
                        position:
                          cell.column.getIsPinned() && device.desktop
                            ? 'sticky'
                            : 'static',
                        left: cell.column.getIsPinned()
                          ? cell.column.getStart('left')
                          : undefined,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  )
}
