import { atom, useAtomValue, useSetAtom, useAtom } from 'jotai'
import {
  atomWithReset,
  atomWithStorage,
  createJSONStorage,
  RESET,
} from 'jotai/utils'
import {
  FilterColumnConditionDataDto,
  PrefixedFilterColumnConditionDataDto,
  CUSTOM_FILTER_IDX,
} from '~/entities/order-search-filter'
import { pipe } from 'fp-ts/function'
import * as R from 'fp-ts/Record'
import * as A from 'fp-ts/Array'
import * as B from 'fp-ts/boolean'
import { subDays, subWeeks, subMonths, startOfDay, endOfDay } from 'date-fns'
import {
  FilterSearchDateRangeType,
  FILTER_SEARCH_DATES_RANGE_MAP,
} from '~/entities/order-search-filter'
import { validateFilterColumnCondition } from '~/container/order-search-filter/order-search-filter.fn'
import { formatDate } from './order-search-filter.fn'
import { DEFAULT_FILTER_FORM_KEY } from './order-search-filter.constants'

// ====================================== 현재 필터 상태
type TCurFilterDto = {
  idx: number
  open: boolean
}
const curFilterAtom = atomWithStorage<TCurFilterDto>(
  'oms-cur-order-search-filter',
  {
    idx: CUSTOM_FILTER_IDX.DEFAULT,
    open: false,
  },
  createJSONStorage(() => sessionStorage),
  { getOnInit: true }
)

export function useCurOrderSearchFilter() {
  return useAtom(curFilterAtom)
}

// ====================================== 필터 쿼리 스트링

const orderSearchFilterQueryStringAtom =
  atomWithStorage<PrefixedFilterColumnConditionDataDto>(
    'filter-query-string',
    {},
    createJSONStorage(() => sessionStorage),
    { getOnInit: true }
  )
orderSearchFilterQueryStringAtom.debugLabel = 'orderSearchFilterQueryString'

/**
 * 기간값(ex 오늘, 어제, 일주일)을 받아서 시작일과 종료일로 변환
 */
const getStartEndDate = (
  dateRange: FilterSearchDateRangeType | undefined | null
): Pick<PrefixedFilterColumnConditionDataDto, 'fStartDate' | 'fEndDate'> => {
  let fStartDate: null | string = null
  let fEndDate: null | string = null

  const now = new Date()

  switch (dateRange) {
    case FILTER_SEARCH_DATES_RANGE_MAP.TODAY:
      fStartDate = pipe(now, startOfDay, formatDate)
      fEndDate = pipe(now, endOfDay, formatDate)
      break
    case FILTER_SEARCH_DATES_RANGE_MAP.YESTERDAY:
      fStartDate = pipe(now, (d) => subDays(d, 1), startOfDay, formatDate)
      fEndDate = pipe(now, (d) => subDays(d, 1), endOfDay, formatDate)
      break
    case FILTER_SEARCH_DATES_RANGE_MAP.WEEK:
      fStartDate = pipe(now, (d) => subWeeks(d, 1), startOfDay, formatDate)
      fEndDate = pipe(now, endOfDay, formatDate)
      break
    case FILTER_SEARCH_DATES_RANGE_MAP.MONTH:
      fStartDate = pipe(now, (d) => subMonths(d, 1), startOfDay, formatDate)
      fEndDate = pipe(now, endOfDay, formatDate)
      break
    case FILTER_SEARCH_DATES_RANGE_MAP.THREE_MONTHS:
      fStartDate = pipe(now, (d) => subMonths(d, 3), startOfDay, formatDate)
      fEndDate = pipe(now, endOfDay, formatDate)
      break
    default:
      break
  }

  return { fStartDate, fEndDate }
}

export const setOrderSearchFilterQueryStringAtom = atom(
  null,
  (_get, set, filterConfig: FilterColumnConditionDataDto) => {
    pipe(
      filterConfig,
      validateFilterColumnCondition,
      B.fold(
        () => undefined,
        () =>
          pipe(
            filterConfig,
            R.filter((v) => v !== undefined && v !== null),
            R.keys<keyof FilterColumnConditionDataDto>,
            A.reduce({}, (acc, key) => {
              const prefixedKey = `f${
                key.charAt(0).toUpperCase() + key.slice(1)
              }`
              Object.assign(acc, {
                [prefixedKey]: filterConfig[key],
              })

              if (key === 'dateRange' && filterConfig[key] !== 'CUSTOM') {
                Object.assign(acc, getStartEndDate(filterConfig[key]))
              }

              return acc
            }),
            (v) => set(orderSearchFilterQueryStringAtom, v)
          )
      )
    )
  }
)

export const useOrderSearchFilterQueryString = () =>
  useAtomValue(orderSearchFilterQueryStringAtom)

export const useSetOrderSearchFilterQueryString = () =>
  useSetAtom(setOrderSearchFilterQueryStringAtom)

// ====================================== 필터 폼이 수정되었는지 여부
const orderSearchFilterFormHasChangedAtom = atomWithReset<boolean>(false)
orderSearchFilterFormHasChangedAtom.debugLabel =
  'orderSearchFilterFormHasChanged'

export const useOrderSearchFilterFormHasChanged = {
  state: () => useAtom(orderSearchFilterFormHasChangedAtom),
  get: () => useAtomValue(orderSearchFilterFormHasChangedAtom),
  set: () => useSetAtom(orderSearchFilterFormHasChangedAtom),
}

// ====================================== 필터 리셋

const resetOrderSearchFilterAtom = atom(null, (_get, set) => {
  set(curFilterAtom, RESET)
  set(orderSearchFilterFormHasChangedAtom, RESET)
  set(orderSearchFilterQueryStringAtom, RESET)
  sessionStorage.removeItem(DEFAULT_FILTER_FORM_KEY)
})

export const useResetOrderSearchFilter = () =>
  useSetAtom(resetOrderSearchFilterAtom)
