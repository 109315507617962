import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient } from '~/shared/api'
import type {
  TPatchResStatusNextDto,
  TPatchPayloadStatusNextDto,
} from './status-next.type'
import { patchResStatusNextDto } from './status-next.dto'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/PD1fLi
 * @description status-next
 */
export function patchStatusNext({
  orderCode,
  sectionCode,
}: {
  orderCode: string
  sectionCode: string
}) {
  return async function (body: TPatchPayloadStatusNextDto) {
    type T = TPatchResStatusNextDto
    type R = typeof body
    const result = await axios.patch<T, AxiosResponse<ApiResponse<T>>, R>(
      `/order/${orderCode}/sections/${sectionCode}/next`,
      body
    )

    try {
      patchResStatusNextDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}
