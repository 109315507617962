import React from 'react'
import { __, __e } from '~/shared/i18n'
import {
  Button,
  ButtonGroup,
  Clay,
  Flex,
  Modal,
} from '@imwebme/clay-components'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { vars } from '@imwebme/clay-token'
import { deliveryCountryQueryOptions } from '~/entities/site/delivery-country'
import { useModals } from '~/router'
import { useModalParams } from '~/shared/hooks/use-modal-params'
import { delivPayloadSchema, TDelivPayloadSchema } from '~/entities/@dto'
import { 받는분, 연락처, 주소정보_한국, 배송메모 } from '../form-fields'
import { externalOrderQueryOptions } from '~/entities/external-order'
import { postExternalOrderDelivery } from '~/entities/external-order-delivery'
import { cn } from '~/shared/utils'
import { useHasScroll } from '~/shared/hooks/use-has-scroll'

export function ExternalDeliveryAddModal() {
  const modals = useModals()
  const modalParams = useModalParams()
  const orderCode = modalParams?.orderCode as string
  const unitCode = modalParams?.unitCode as string
  const queryClient = useQueryClient()
  const { ref, hasScroll } = useHasScroll<HTMLDivElement>()

  const { data: delivCountryList } = useSuspenseQuery(
    deliveryCountryQueryOptions(unitCode)
  )

  const { mutate, isPending } = useMutation({
    mutationFn: postExternalOrderDelivery({
      orderCode,
    }),
  })

  const defaultCountry = React.useMemo(
    () =>
      delivCountryList?.find((el) => el.code === 'KR') ?? delivCountryList?.[0],
    [delivCountryList]
  )

  const methods = useForm<TDelivPayloadSchema>({
    resolver: zodResolver(delivPayloadSchema),
    defaultValues: {
      country: defaultCountry.code,
      countryName: defaultCountry.name,
      whoInput: 'orderer',
    },
  })
  const {
    handleSubmit,
    formState: { isValid },
  } = methods

  const onSubmit = handleSubmit((form) => {
    const { kor, ...rest } = form
    mutate(
      { ...kor, ...rest, addr1: kor?.addr1 || '' },
      {
        onSuccess() {
          queryClient.invalidateQueries({
            queryKey: [externalOrderQueryOptions({ orderCode }).queryKey[0]],
          })
          modals.close()
          toast.success(__('배송지가 추가되었습니다'))
        },
        onError(error) {
          toast.error(
            error.response
              ? __e(error.response.data.code)
              : __('배송지 추가에 실패했어요')
          )
        },
      }
    )
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} className="overflow-hidden flex flex-col">
        <Modal.Body>
          <Flex.Column rowGap={vars.spacing[5]}>
            <Flex columnGap={vars.spacing[3]}>
              <받는분 />
              <연락처 />
            </Flex>
            <주소정보_한국 />
            <배송메모 />
          </Flex.Column>
        </Modal.Body>

        <Clay
          className={cn(
            'px-clay-4 pb-clay-4 z-10',
            hasScroll && 'pt-clay-3 shadow-clay-layer'
          )}
        >
          <ButtonGroup sx={{ justifyContent: 'flex-end' }}>
            <Button
              native={{ type: 'button' }}
              variant="outlined"
              onClick={() => modals.close()}
              text={__('취소')}
            />
            <Button
              native={{ type: 'submit' }}
              variant="primary"
              isDisabled={!isValid}
              isLoading={isPending}
              text={__('완료')}
            />
          </ButtonGroup>
        </Clay>
      </form>
    </FormProvider>
  )
}
