import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPostResBulkActionSectionDeliveryHoldValidateSchema,
  type TPostPayloadBulkActionSectionDeliveryHoldValidateSchema,
  postResBulkActionSectionDeliveryHoldValidateSchema,
} from '.'
import * as Sentry from '@sentry/react'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/IWUes1
 * @description 주문 배송 보류 설정 대량 처리 검증
 */
export async function postBulkActionSectionDeliveryHoldValidate({
  body,
}: {
  body: TPostPayloadBulkActionSectionDeliveryHoldValidateSchema
}) {
  type R = typeof body
  type T = TPostResBulkActionSectionDeliveryHoldValidateSchema
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/section/delivery/hold/validate', body)
    .then((res) => res.data)

  try {
    postResBulkActionSectionDeliveryHoldValidateSchema.parse(result.data)
  } catch (error) {
    Sentry.captureException(error)
  }
  return result.data
}
