import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useParams } from 'react-router-dom'
import { 상수_기본탭코드, 상수_전체탭이름 } from '~/entities/@x'
import {
  orderSearchTabDetailQueryOptions,
  orderSearchTabQueryOptions,
  TGetResOrderSearchTabDetailSchema,
} from '~/entities/order-search-tab'

export function useTabCode() {
  const activeTab = useActiveTab()

  return activeTab.adminOrderSearchTabCode
}

export function useIsDefaultTab() {
  const activeTab = useActiveTab()

  return activeTab.name === 상수_전체탭이름
}

export function useActiveTab(): TGetResOrderSearchTabDetailSchema {
  const tabCodeParam = useParams().tabCode
  const tabCode = tabCodeParam ?? 상수_기본탭코드

  const { data: tabList } = useQuery(orderSearchTabQueryOptions())

  const fallbackData = React.useMemo(
    () => ({
      data: {
        adminOrderSearchTabCode: tabCode,
        name: '',
        visibleColumns: [],
        columnCondition: null,
        filters: [],
        sortNo: 0,
        count: 0,
      },
      statusCode: 200,
      message: '',
      timestamp: '',
      path: '',
    }),
    [tabCode]
  )

  const { data = fallbackData } = useQuery({
    ...orderSearchTabDetailQueryOptions(tabCode),
    enabled: !tabList,
  })

  // 탭필터 목록 조회 캐시가 있다면 해당 값으로 리턴
  if (tabList) {
    const foundTab = tabList.data.list.find(
      (tab) => tab.adminOrderSearchTabCode === tabCode
    )
    return foundTab ?? tabList.data.list[0]
  }

  return data.data
}
