import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient } from '~/shared/api'
import type {
  TPatchResFormDataSchema,
  TPatchPayloadFormDataSchema,
} from './form-data.type'
import { patchResFormDataSchema } from './form-data.schema'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/tdLb3s
 * @description 추가정보 수정
 */
export function patchFormData({ orderCode }: { orderCode: string }) {
  return async function (body: TPatchPayloadFormDataSchema) {
    type T = TPatchResFormDataSchema
    type R = typeof body
    const result = await axios.patch<T, AxiosResponse<ApiResponse<T>>, R>(
      `order/${orderCode}/form-data`,
      body
    )

    try {
      patchResFormDataSchema.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}
