import { z } from 'zod'

export const 상수_결제수단 = {
  무통장입금: 'OPM01',
  신용카드: 'OPM02',
  가상계좌: 'OPM03',
  실시간계좌이체: 'OPM04',
  휴대폰결제: 'OPM05',
  무료결제: 'OPM06',
  카카오페이: 'OPM07',
  네이버페이: 'OPM08',
  토스페이: 'OPM09',
  정기결제: 'OPM10',
  페이코: 'OPM20',
  삼성페이: 'OPM21',
  삼성페이_체크: 'OPM22',
  쓱페이: 'OPM23',
  L페이: 'OPM24',
  KB앱페이: 'OPM25',
  차이페이: 'OPM26',
  티머니페이: 'OPM27',
  핀페이: 'OPM28',
  애플페이: 'OPM29',
  네이버페이_주문형: 'OPM30',
  페이팔: 'OPM31',
  엑심베이: 'OPM32',
  톡체크아웃_주문형: 'OPM33',
  편의점_결제: 'OPM34',
  임시_스마트스토어: 'OPM99',
} as const

const schema = z.nativeEnum(상수_결제수단)
export type T상수_결제수단 = z.infer<typeof schema>
