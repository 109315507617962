import { Loading } from '~/shared/components/loading'
import { ErrorCard } from '~/shared/components/error'
import { LoaderFunction } from 'react-router-dom'
import { queryClient } from '~/app/provider'
import {
  orderSearchTabQueryOptions,
  orderSearchTabDetailQueryOptions,
} from '~/entities/order-search-tab'
import { menuBannerQueryOptions } from '~/entities/site/menu-banner'

export const Catch = () => <ErrorCard isFull />
export const Pending = () => <Loading />

export const Loader: LoaderFunction = async ({ params }) => {
  if (!params.tabCode) {
    throw new Error("The tabCode path parameter doesn't exist")
  }

  queryClient.prefetchQuery(orderSearchTabQueryOptions()) // 탭목록 조회
  queryClient.prefetchQuery(menuBannerQueryOptions()) // 공지사항 조회
  await queryClient.prefetchQuery(
    orderSearchTabDetailQueryOptions(params.tabCode)
  ) // 단일탭 조회

  return null
}

export { default } from '~/container/orders/orders'
