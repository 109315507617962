import React from 'react'
import { __ } from '~/shared/i18n'

interface PriceState {
  baseItemPrice: number //판매가
  itemPrice: number | null //변경된 판매가
  error: string | null
}

interface PriceAction {
  payload: string
}

function priceReducer(state: PriceState, action: PriceAction) {
  const payload = action.payload

  if (payload === '') {
    return {
      ...state,
      itemPrice: null,
      error: null,
    }
  }

  const numValue = Number(payload)

  if (Number.isNaN(numValue) || !Number.isInteger(numValue)) {
    return state
  }

  const baseNextState: PriceState = {
    ...state,
    itemPrice: numValue,
  }

  if (numValue < 0) {
    return { ...baseNextState, error: __('0 이상의 값을 입력해주세요') }
  } else {
    return { ...baseNextState, error: null }
  }
}

function initPriceArgs({
  baseItemPrice,
  itemPrice,
}: {
  baseItemPrice: number
  itemPrice: number
}) {
  return {
    baseItemPrice,
    itemPrice,
    error: null,
  }
}

export function useProductPrice(props: {
  baseItemPrice: number
  itemPrice: number
}) {
  return React.useReducer(priceReducer, props, initPriceArgs)
}
