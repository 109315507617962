import React from 'react'
import { InputNumber } from '~/shared/components/ui/input-number'
import { IconAccordionDown } from '@imwebme/icon'
import { type ISpecification, Spec } from 'spec-pattern-ts'
import { ProductCard001Template, type TProductCard001 } from '../../../product'
import { Trans, __ } from '~/shared/i18n'
import { useCurrency } from '~/shared/hooks/use-currency'

export class CProduct {
  id
  thumbnail
  name
  option
  qty
  price
  originalPrice
  currency
  #discountPrice = 0

  // 기본 qty에 더하거나 빼는 값을 저장하는 변수
  qtyChange: number

  constructor(
    props: TProductCard001.props & {
      qtyChange: number
      discountPrice?: number
    }
  ) {
    this.id = props.id
    this.thumbnail = props.thumbnail
    this.name = props.name
    this.option = props.option
    this.qty = props.qty
    this.price = props.price
    this.originalPrice = props.originalPrice
    this.currency = props.currency
    this.qtyChange = props.qtyChange
    this.#discountPrice = props.discountPrice || 0
  }

  getStatus(): string {
    // 상품의 갯수가 0개이상인지 확인하는 스펙
    const isQtyChangedSpec: ISpecification<CProduct> = new Spec(
      (candidate) => candidate.qtyChange > 0
    )

    // 상품 수량취소를하는데 모두 취소한 경우
    const allDeleteSpect: ISpecification<CProduct> = new Spec(
      (candidate) => candidate.qty === candidate.qtyChange
    )

    if (allDeleteSpect.isSatisfiedBy(this)) {
      return 'A'
    }
    if (isQtyChangedSpec.isSatisfiedBy(this)) {
      return 'B'
    }

    // If none of the conditions are met, return 'Unknown'.
    return 'Unknown'
  }

  discountItem(): TProductCard001.props {
    return {
      id: this.id,
      thumbnail: this.thumbnail,
      name: this.name,
      option: this.option,
    }
  }

  sumPrice() {
    if (this.price && this.qty) {
      return this.price * this.qty
    }
    if (this.originalPrice && this.qty) {
      return this.originalPrice * this.qty
    }
    if (this.originalPrice) {
      return this.originalPrice
    }
    return undefined
  }

  getPurchasingPrice() {
    if (this.#discountPrice && this.originalPrice) {
      return (this.originalPrice - this.#discountPrice) * (this.qtyChange || 0)
    }
    if (this.originalPrice) {
      return this.originalPrice * (this.qtyChange || 0)
    }
    return 0
  }

  getItem() {
    return {
      id: this.id,
      thumbnail: this.thumbnail,
      name: this.name,
      option: this.option,
      qty: this.qty,
      price: this.price,
      originalPrice: this.originalPrice,
      currency: this.currency,
      qtyChange: this.qtyChange,
    }
  }
}

type OrderEditReturnSectionRowItemProps = TProductCard001.props & {
  qtyChange?: number
}
export interface OrderEditReturnSectionRowProps {
  item: OrderEditReturnSectionRowItemProps
  onChange?: (item: number) => void
  valueIncrease?: (item: number) => void
  valueDecrease?: (item: number) => void
  onDelete?: (item: OrderEditReturnSectionRowItemProps) => void
  onItemChange?: (item: OrderEditReturnSectionRowItemProps) => void
}
export const OrderEditReturnSectionRow = ({
  item,
  onChange,
  valueIncrease,
  valueDecrease,
}: OrderEditReturnSectionRowProps) => {
  const currency = useCurrency()
  const product = new CProduct({
    ...item,
    qty: item.qty,
    qtyChange: item.qtyChange || 0,
  })
  const status = product.getStatus()

  return (
    <div data-id={item.id} className="@container px-[var(--mx)] py-[16px]">
      <div className="grid grid-cols-[1fr,102px,auto] @lg:grid-cols-[1fr,minmax(102px,auto),160px] gap-x-[16px] w-full">
        <div>
          <ProductCard001Template {...product.getItem()} />
          <div className="pl-[60px] text-[14px]" data-status={status}>
            <div className="mt-[4px]">
              {__('총 {{price, 3comma}}{{currency, currency}}', {
                price: product.sumPrice(),
                formatParams: {
                  currency: {
                    currency,
                  },
                },
              })}
            </div>
          </div>
        </div>
        <div className="w-full [&>*]:w-full">
          <InputNumber
            value={item.qtyChange || 0}
            valueIncrease={(v) => valueIncrease?.((item.qtyChange || 0) + v)}
            valueDecrease={(v) => valueDecrease?.((item.qtyChange || 0) + v)}
            onChange={(e) => onChange?.(Number(e.target.value))}
            min={0}
            // 취소 섹션에 품목은 추가할수없기때문에 처음 받아온 수량값을 max로 설정한다.
            max={item.qty}
            className="bg-white w-full border-solid"
          />
        </div>
        <div className="flex flex-col items-end">
          <span>
            {status !== 'Unknown' && '- '}
            {__('{{price, 3comma}} {{currency, currency}}', {
              price: product.getPurchasingPrice(),
              formatParams: {
                currency: {
                  currency,
                },
              },
            })}
          </span>
          <div className="mt-[8px]">
            {status === 'A' && (
              <div className="bg-clay-semantic-surfaceCritical rounded-full typo-clay-label-small px-[6px] py-[2px] text-clay-semantic-textCritical">
                {__('품목취소')}
              </div>
            )}
            {status === 'B' && (
              // 원래 같았으면 DS의 뱃지를 넣어야하는데, DS담당이 변경되고 DS가 계속바뀌면서 유지보수를 못했습니다.
              // 인라인으로 처리했습니다.
              <div className="bg-clay-semantic-surfaceCritical rounded-full typo-clay-label-small px-[6px] py-[2px] text-clay-semantic-textCritical">
                {__('수량취소')}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
