import React from 'react'
import { InputNumber } from '~/shared/components/ui/input-number'
import { IconAccordionDown } from '@imwebme/icon'
import { Trash03 } from '@imwebme/clay-icons'
import { type ISpecification, Spec } from 'spec-pattern-ts'
import { ProductCard001Template, type TProductCard001 } from '../../../product'
import OrderEditAddSectionRowDiscount from './order-edit-add-section-row-discount'
import { __ } from '~/shared/i18n'
import { useCurrency } from '~/shared/hooks/use-currency'
import { IconButton } from '@imwebme/clay-components'
import { Trans } from 'react-i18next'

export class CProduct {
  id
  thumbnail
  name
  option
  qty
  price
  originalPrice
  currency
  isNew
  #discountPrice = 0

  // 기본 qty에 더하거나 빼는 값을 저장하는 변수
  qtyChange: number

  constructor(
    props: TProductCard001.props & {
      isNew?: boolean
      qtyChange: number
      discountPrice?: number
    }
  ) {
    this.id = props.id
    this.thumbnail = props.thumbnail
    this.name = props.name
    this.option = props.option
    this.qty = props.qty
    this.price = props.price
    this.originalPrice = props.originalPrice
    this.currency = props.currency
    this.isNew = props.isNew
    this.qtyChange = props.qtyChange
    this.#discountPrice = props.discountPrice || 0
  }

  getStatus(): string {
    // 새롭게 추가된 상품인지 확인하는 스펙
    const isNewSpec: ISpecification<CProduct> = new Spec(
      (candidate) => candidate.isNew === true
    )
    // 상품의 갯수가 0개이상인지 확인하는 스펙
    const isQtyChangedSpec: ISpecification<CProduct> = new Spec(
      (candidate) => candidate.qtyChange > 0
    )
    // 새롭게 추가된 상품이 아니고 기존 상품이면서 갯수가 변경되고 1개 이상일때
    const isOriginalAndQtyChangedSpec: ISpecification<CProduct> = isNewSpec
      .not()
      .and(isQtyChangedSpec)

    if (isNewSpec.isSatisfiedBy(this)) {
      return 'A'
    }
    if (isOriginalAndQtyChangedSpec.isSatisfiedBy(this)) {
      return 'B'
    }

    // If none of the conditions are met, return 'Unknown'.
    return 'Unknown'
  }

  discountItem(): TProductCard001.props {
    return {
      id: this.id,
      thumbnail: this.thumbnail,
      name: this.name,
      option: this.option,
    }
  }

  getPrice() {
    const discountPrice = this.#discountPrice || 0
    if (this.price) {
      return this.price - discountPrice
    }
    if (this.originalPrice) {
      return this.originalPrice - discountPrice
    }
    return 0
  }

  sumPrice() {
    const discountPrice = this.#discountPrice || 0
    if (this.price && this.qty) {
      return (this.price - discountPrice) * this.qty
    }
    if (this.originalPrice && this.qty) {
      return (this.originalPrice - discountPrice) * this.qty
    }
    if (this.originalPrice) {
      return this.originalPrice - discountPrice
    }
    return undefined
  }

  getPurchasingPrice() {
    return (
      ((this.price || this.originalPrice || 0) - (this.#discountPrice || 0)) *
      (this.qtyChange || 0)
    )
  }

  getItem() {
    if (this.isNew) {
      return {
        id: this.id,
        thumbnail: this.thumbnail,
        name: this.name,
        option: this.option,
        currency: this.currency,
      }
    }
    return {
      id: this.id,
      thumbnail: this.thumbnail,
      name: this.name,
      option: this.option,
      qty: this.qty,
      price: this.price,
      originalPrice: this.originalPrice,
      currency: this.currency,
      qtyChange: this.qtyChange,
    }
  }
}

type OrderEditAddSectionRowItemProps = TProductCard001.props & {
  isNew?: boolean
  qtyChange?: number
}
export interface OrderEditAddSectionRowProps {
  item: OrderEditAddSectionRowItemProps
  onChange?: (item: number) => void
  valueIncrease?: (item: number) => void
  valueDecrease?: (item: number) => void
  onDelete?: (item: OrderEditAddSectionRowItemProps) => void
  onItemChange?: (item: OrderEditAddSectionRowItemProps) => void
}
export const OrderEditAddSectionRow = ({
  item,
  onChange,
  valueIncrease,
  valueDecrease,
  onDelete,
  onItemChange,
}: OrderEditAddSectionRowProps) => {
  const currency = useCurrency()
  const [discountPrice, setDiscountPrice] = React.useState(0)
  const product = new CProduct({
    ...item,
    qty: item.qty,
    qtyChange: item.qtyChange || 0,
    discountPrice,
  })
  const status = product.getStatus()

  const handleDiscount = (price: number) => {
    setDiscountPrice(price)
    onItemChange?.({
      ...item,
      discountPrice: price,
    })
  }

  return (
    <div data-id={item.id} className="@container px-[var(--mx)] py-[16px]">
      <div className="grid grid-cols-[1fr,102px,auto] @lg:grid-cols-[1fr,minmax(102px,auto),160px] gap-x-[16px] w-full">
        <div>
          <ProductCard001Template {...product.getItem()} />
          <div className="pl-[60px] text-[14px]" data-status={status}>
            {status === 'A' && (
              <div className="mt-[4px]">
                {__('개당 {{price, 3comma}}{{currency, currency}}', {
                  price: product.getPrice(),
                  formatParams: {
                    currency: {
                      currency: item.currency || 'KRW',
                    },
                  },
                })}
              </div>
            )}
            {product.sumPrice() && !product.isNew && (
              <div className="mt-[4px]">
                {__('총 {{price, 3comma}}{{currency, currency}}', {
                  price: product.sumPrice(),
                  formatParams: {
                    currency: {
                      currency: item.currency || 'KRW',
                    },
                  },
                })}
              </div>
            )}
          </div>
          {item.isNew && (
            <div className="mt-[4px] pl-[calc(60px-12px)]">
              <div className="flex gap-x-[4px]">
                <OrderEditAddSectionRowDiscount
                  item={product}
                  onSubmit={handleDiscount}
                />
                <IconButton
                  native={{ type: 'button' }}
                  variant="secondary"
                  size="tiny"
                  onClick={() => onDelete?.(item)}
                  icon={<Trash03 color="inherit" />}
                />
              </div>
            </div>
          )}
        </div>
        <div className="w-full [&>*]:w-full">
          <InputNumber
            value={item.qtyChange || 0}
            valueIncrease={(v) => valueIncrease?.((item.qtyChange || 0) + v)}
            valueDecrease={(v) => valueDecrease?.((item.qtyChange || 0) + v)}
            onChange={(e) => onChange?.(Number(e.target.value))}
            min={item.isNew ? 1 : 0}
            max={100_0000}
            className="bg-white w-full border-solid"
          />
        </div>
        <div className="flex flex-col items-end">
          <div>
            <Trans
              i18nKey="<b>{{price, 3comma}}</b> <span>{{currency, currency}}</span>"
              values={{
                price: product.getPurchasingPrice(),
                currency: currency,
              }}
              components={{
                b: <b className="typo-clay-body-medium-bold" />,
                span: <span className="typo-clay-body-medium" />,
              }}
            />
          </div>
          <div className="mt-[8px]">
            {status === 'A' && (
              <div className="bg-clay-semantic-layerSelected rounded-full typo-clay-label-small px-[6px] py-[2px] text-clay-semantic-textPrimary">
                {__('품목추가')}
              </div>
            )}
            {status === 'B' && (
              // 원래 같았으면 DS의 뱃지를 넣어야하는데, DS담당이 변경되고 DS가 계속바뀌면서 유지보수를 못했습니다.
              // 인라인으로 처리했습니다.
              <div className="bg-clay-semantic-layerSelected rounded-full typo-clay-label-small px-[6px] py-[2px] text-clay-semantic-textPrimary">
                {__('수량추가')}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
