import React from 'react'
import { __ } from '~/shared/i18n'
import { useCancelAllConfirmAtom } from './cancel-all-confirm.store'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { Modal } from '@imwebme/clay-components'
import { ErrorCard } from '~/shared/components/error'
import { Loading } from '~/shared/components/loading'
import { ErrorBoundary } from '@sentry/react'

const CancelAllConfirm = React.lazy(() =>
  import('./cancel-all-confirm').then((module) => ({
    default: React.memo(module.CancelAllConfirm),
  }))
)

export function CancelAllConfirmContainer() {
  const orderCode = useOrderCode()
  const [state, setState] = useCancelAllConfirmAtom.state({
    orderCode,
  })

  function onOpenChange(o: boolean) {
    setState((prev) => ({
      ...prev,
      value: {
        ...prev.value,
        open: o,
      },
    }))
  }

  return (
    <Modal
      isOpen={state.value.open}
      setClose={() => onOpenChange(false)}
      width="small"
    >
      <ErrorBoundary fallback={ErrorCard}>
        <React.Suspense fallback={<Loading />}>
          <CancelAllConfirm orderCode={orderCode} />
        </React.Suspense>
      </ErrorBoundary>
    </Modal>
  )
}
