import { useSuspenseQuery } from '@tanstack/react-query'
import { orderListPaginationQueryOptions } from '~/entities/order-list'
import { useOrderListQueryParams } from '~/entities/order-list/order-list.model'
import { NumberBadge } from '@imwebme/clay-components'
import { __ } from '~/shared/i18n'
import React from 'react'
import { OrderTableHeaderState } from '~/container/orders/orders-table/orders-table.store'
import { useSetRecoilState } from 'recoil'

export function NumOrdersBadgeContainer() {
  const queryParams = useOrderListQueryParams(true)
  const { data: pagination } = useSuspenseQuery(
    orderListPaginationQueryOptions(queryParams)
  )

  const set_orderTableHeader_totalCount = useSetRecoilState(
    OrderTableHeaderState.totalCount
  )
  React.useEffect(() => {
    set_orderTableHeader_totalCount(() => pagination.data.total)
  }, [pagination, set_orderTableHeader_totalCount])

  return (
    <NumberBadge
      number={__('{{number, 3comma}}', {
        number: pagination.data.total,
      })}
      variant="secondary"
      size="large"
    />
  )
}
