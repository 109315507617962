import { Typography, ButtonGroup, Button, Clay } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { Download01 } from '@imwebme/clay-icons'
import { __ } from '~/shared/i18n'
import { OmsTutorial } from './oms-tutorial'
import { Link } from '~/shared/components/link'
import { usePermissionAtom } from '~/app/provider/permission'
import { useDevice } from '~/shared/hooks/use-device'
import { CreatedOrdersModal } from './created-orders-modal/created-orders-modal'
import { useResetAllAtom } from '~/container/order-create/order-create.store'
import { useModals } from '~/router'
import { useTabCode } from '~/shared/hooks/use-tab-code'
import { useOrderSearchQuery } from '~/shared/hooks/use-order-search-query'

export function OrdersHeader() {
  const permission = usePermissionAtom()
  const { desktop } = useDevice()
  const resetAllOrderCreateAtom = useResetAllAtom()
  const modals = useModals()
  const tabCode = useTabCode()
  const [orderSearchQuery] = useOrderSearchQuery()

  return (
    <Clay
      display={desktop ? 'grid' : 'none'}
      gridTemplateColumns="1fr auto"
      gap={`${vars.spacing[3]} ${vars.spacing[4]}`}
      paddingTop={vars.spacing[8]}
      paddingBottom={vars.spacing[6]}
    >
      <div data-cypress-render-success>
        <Typography variant="heading-3xlarge-bold">{__('주문')}</Typography>
      </div>

      <ButtonGroup isInline>
        <Button
          variant="secondary"
          text={__('내보내기')}
          onClick={() =>
            modals.open('/export/:tabCode/excel', {
              params: { tabCode },
              ...(orderSearchQuery && { search: { q: orderSearchQuery } }),
            })
          }
          trailingIcon={<Download01 />}
        />
        <CreatedOrdersModal />

        {permission.adminShoppingOms.view === 'Y' &&
          permission.adminShoppingOms.edit === 'Y' && (
            <Link to="/order-create" onClick={resetAllOrderCreateAtom}>
              <Button as="div" text={__('주문 생성')} variant="primary" />
            </Link>
          )}
      </ButtonGroup>

      <Clay gridColumn="1/-1">
        <OmsTutorial />
      </Clay>
    </Clay>
  )
}
