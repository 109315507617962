import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPatchResBackShippingCompleteDto,
  type TPatchPayloadBackShippingCompleteDto,
  patchResBackShippingCompleteDto,
  patchPayloadBackShippingCompleteDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/ZO7ind
 * @description [back] 주문 섹션 상태 배송완료로 되돌리기
 */
export function patchBackShippingComplete({
  orderCode,
}: {
  orderCode: string
}) {
  return async function (body: TPatchPayloadBackShippingCompleteDto) {
    type T = TPatchResBackShippingCompleteDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/external-order/${orderCode}/sections/back/shipping-complete`, body)
      .then((res) => res.data)

    try {
      patchResBackShippingCompleteDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}
