import { match, P } from 'ts-pattern'
import { pipe, flow } from 'fp-ts/function'
import { OrderEditHeader } from '~t'
import { ContentsBanner, PageBanner } from '@imwebme/clay-components'
import { __ } from '~/shared/i18n'
import { Link } from '~/shared/components/link'
import { IconArrowLeft } from '@imwebme/icon'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { OrderTimelinePack } from '../order-timeline'
import { Button } from '@imwebme/clay-components'
import { useSuspenseQuery } from '@tanstack/react-query'
import { orderDetailOptions } from '~/entities/order-detail'
import { Sections } from './partials/sections'
import { CancelAllConfirmContainer } from './modal/cancel-complete-confirm'
import { useCancelAllConfirmAtom } from './modal/cancel-complete-confirm/cancel-all-confirm.store'
import { PageTopBar } from '~/shared/components/page-top-bar/page-top-bar'
import { 상수_판매채널 } from '~/entities/@x'
import { 모델_주문서, 스펙_주문서 } from '~/entities/order-detail/model/order'

function Header({ orderNo }: { orderNo: string }) {
  const orderCode = useOrderCode()

  return (
    <OrderEditHeader>
      <OrderEditHeader.Slot name="link">
        <Link
          to="/order/:saleChannel/:orderCode"
          params={{
            orderCode,
            saleChannel: pipe(상수_판매채널.아임웹, String),
          }}
          className="flex gap-x-[4px] items-center"
        >
          <IconArrowLeft className="stroke-[2]" />
          <span>{orderNo}</span>
        </Link>
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="title">{__('취소')}</OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="right-top">
        <OrderTimelinePack />
      </OrderEditHeader.Slot>
    </OrderEditHeader>
  )
}

function PriceTotalCard() {
  const orderCode = useOrderCode()
  const onOpenCancelAllConfirmModal = useCancelAllConfirmAtom.set({
    orderCode,
  })
  const { data } = useSuspenseQuery({
    ...orderDetailOptions({ orderCode }),
  })
  const $order = new 모델_주문서(data)
  return (
    <>
      <div className="[--mx:24px] py-[24px] bg-white rounded-[12px] self-start">
        <div className="mx-[var(--mx)]">
          <span className="typo-clay-heading-medium-bold">
            {__('예상 환불 금액')}
          </span>
        </div>
        <div className="mx-[var(--mx)] mt-[24px] space-y-[8px] text-center">
          <div className="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              fill="none"
              viewBox="0 0 48 48"
            >
              <g>
                <path
                  fill="#BCC0C6"
                  fillRule="evenodd"
                  d="M24 42c9.941 0 18-8.059 18-18S33.941 6 24 6 6 14.059 6 24s8.059 18 18 18zm-7.214-23.78a1.5 1.5 0 00-2.826 1.005l1.348 3.787H14a1.5 1.5 0 000 3h2.376l1.856 5.213a1.5 1.5 0 002.828 0l2.859-8.03 2.838 7.97a1.498 1.498 0 001.937.97c.463-.164.795-.533.93-.97l1.835-5.153H34a1.5 1.5 0 100-3h-1.473l1.348-3.787a1.5 1.5 0 10-2.826-1.006l-2.859 8.029-2.854-8.017a1.493 1.493 0 00-1.28-1.003 1.487 1.487 0 00-.692.1 1.492 1.492 0 00-.863.903l-2.855 8.021-2.86-8.033z"
                  clipRule="evenodd"
                ></path>
              </g>
            </svg>
          </div>
          <div className="typo-clay-body-small">
            {__('결제되지 않은 주문이에요')}
          </div>
        </div>
      </div>
      <div className="mt-[20px]">
        <Button
          native={{ type: 'button' }}
          fullWidth
          variant="primary"
          onClick={() =>
            onOpenCancelAllConfirmModal((prev) => ({
              ...prev,
              value: {
                ...prev.value,
                open: true,
              },
            }))
          }
          text={
            스펙_주문서.거래대기상태.model($order)
              ? __('주문 생성 취소')
              : __('주문 취소')
          }
          size="large"
        />
      </div>
    </>
  )
}

/**
 * @description
 * ```
 * 전체취소
 * - 입금전에 취소하는 경우는 전체취소
 * ```
 */
export function OrderCancelAll() {
  const orderCode = useOrderCode()
  const { data } = useSuspenseQuery({
    ...orderDetailOptions({ orderCode }),
  })
  const $order = new 모델_주문서(data)
  return (
    <>
      <PageTopBar />
      <Header orderNo={pipe(data.orderNo, String)} />
      <div className="space-y-[24px] mt-[30px] min-w-[992px] overflow-auto">
        <div className="w-[970px] mx-auto">
          {match<boolean>(true)
            .with(스펙_주문서.거래대기상태.model($order), () => (
              <PageBanner
                variant="critical"
                icon
                text={__(
                  '관리자가 생성한 주문은 전체 취소만 가능하며 취소 후에는 다시 되돌릴 수 없어요.'
                )}
              />
            ))
            .otherwise(() => (
              <PageBanner
                variant="critical"
                icon
                text={__(
                  '결제 수단이 무통장입금 · 가상 계좌인 주문은 입금확인 전까지 전체 취소만 가능해요. 입금확인 후 부분 취소를 진행해 주세요'
                )}
              />
            ))}
        </div>
        <div className="w-[970px] mx-auto grid grid-cols-12 gap-[20px]">
          <div className="col-span-8 space-y-clay-5 flex flex-col">
            <Sections />
          </div>
          <div className="col-span-4">
            <PriceTotalCard />
          </div>
        </div>
      </div>
      <CancelAllConfirmContainer />
    </>
  )
}
