import * as Sentry from '@sentry/react'
import { ApiClient, ApiResponse } from '~/shared/api'
import { type TGoodsflowSchema } from './goodsflow.type'
import { goodsflowSchema } from './goodsflow.schema'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/zMYnzZ
 */
export async function getGoodsflowDeliveryServiceDetail() {
  const result = await axios
    .get<ApiResponse<TGoodsflowSchema>>('/goodsflow-delivery-service/detail')
    .then((res) => res.data)

  try {
    goodsflowSchema.parse(result.data)
  } catch (error) {
    Sentry.captureException(error)
  }
  return result
}
