import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as math from 'mathjs'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import * as R from 'fp-ts/Record'
import type {
  TItem,
  TOption,
  TOptionDetails,
  TValue,
  T_VIEW_Item,
} from './product-search.type'
import { Product } from './product-search-item-class'
import type { TProductCard001 } from '../product-card/product-card-001'
// 모든 상품을 관리하는 atom
export const productSearchItemsState = atomFamily(
  ({ id, values = [] }: { id: string; values?: (TItem & T_VIEW_Item)[] }) => {
    const at = atom({
      id,
      values,
    })
    at.debugLabel = `search(${id})`
    return at
  },
  (a, b) => a.id === b.id
)

// ================== START ==================
// 체크된 상품만 가져오기
// 필요한함수가 많아서 주석으로 분리한다.

// 필터후에 length가 0이면 undefined를 리턴하게끔해야한다.
const AgetOrUndefined = <A>(a: A[]): undefined | A[] => {
  if (a.length === 0) {
    return undefined
  }
  return a
}
const RkeyFilter = (keyT: string) => (key: string, value: any) => {
  if (key !== keyT) {
    return O.some(value)
  }
  return O.none
}
// values에서 checked되어있는 것만 가져오기
const filterCheckedValues = (e: TValue[] | undefined) =>
  pipe(
    e,
    O.fromNullable,
    O.fold(
      () => undefined,
      flow(
        A.filter((i) => i.checked === true),
        AgetOrUndefined,
        // ============== START ================
        // 업데이트만 가져오는 것이라서 checked를 제거해준다.
        O.fromNullable,
        O.fold(
          () => undefined,
          flow(A.map(R.filterMapWithIndex(RkeyFilter('checked'))))
        )
        // ============== END ================
      )
    )
  )

// 상품(id)의 상태를 관리하는 atom
export const productSearchItemState = atomFamily(
  ({
    searchId,
    prodCode,
  }: {
    searchId: string
    prodCode: string
    values?: (TItem & T_VIEW_Item)[]
  }) => {
    const at = atom(
      (get) => {
        const productSearchItemsAtom = productSearchItemsState({ id: searchId })
        const allValues = get(productSearchItemsAtom)
        const result = pipe(
          allValues,
          (e) => e.values,
          A.findFirst((e) => e.prodCode === prodCode),
          O.fold(
            () => undefined,
            (e) => e
          )
        )
        // 없는상품을 조회할수없다 (이런경우는 없을것이다)
        return result as TItem & T_VIEW_Item
      },
      (get, set, arg: TItem & T_VIEW_Item) => {
        const productSearchItemsAtom = productSearchItemsState({ id: searchId })
        const allValues = get(productSearchItemsAtom)
        const result = pipe(
          allValues,
          (e) => e.values,
          A.findIndex((e) => e.prodCode === prodCode),
          O.fold(
            () => undefined,
            (e) => e
          )
        )
        if (result !== undefined) {
          set(productSearchItemsAtom, (e) => ({
            ...e,
            values: pipe(
              e.values,
              A.updateAt(result, arg),
              O.getOrElse(() => e.values)
            ),
          }))
        }
      }
    )
    at.debugLabel = `search-product(${prodCode})`
    return at
  },
  (a, b) => a.prodCode === b.prodCode && a.searchId === b.searchId
)

// 상품(id)의 상태를 관리하는 atom
const productSearchItemSelectedState = atomFamily(
  ({
    searchId,
    prodCode,
  }: {
    searchId: string
    prodCode: string
    values?: (TItem & T_VIEW_Item)[]
  }) => {
    const at = atom(
      (get) => {
        const productSearchItemsAtom = productSearchItemsState({ id: searchId })
        const allValues = get(productSearchItemsAtom)
        const result = pipe(
          allValues,
          (e) => e.values,
          A.findFirst((e) => e.prodCode === prodCode),
          O.fold(
            () => undefined,
            (e) => e
          )
        )
        if (result === undefined) {
          return undefined
        }
        // 없는상품을 조회할수없다 (이런경우는 없을것이다)
        return result as TItem & T_VIEW_Item
      },
      (get, set, arg: TItem & T_VIEW_Item) => {
        const productSearchItemsAtom = productSearchItemsState({ id: searchId })
        const allValues = get(productSearchItemsAtom)
        const result = pipe(
          allValues,
          (e) => e.values,
          A.findIndex((e) => e.prodCode === prodCode),
          O.fold(
            () => undefined,
            (e) => e
          )
        )
        if (result !== undefined) {
          set(productSearchItemsAtom, (e) => ({
            ...e,
            values: pipe(
              e.values,
              A.updateAt(result, arg),
              O.getOrElse(() => e.values)
            ),
          }))
        }
      }
    )
    at.debugLabel = `search-product(${prodCode})-selected`
    return at
  },
  (a, b) => a.prodCode === b.prodCode && a.searchId === b.searchId
)

// ================== 검색결과 상태 (START) ==================
// 체크된 상품만 가져오기
const productSearchItemsSelectedState = atomFamily(
  ({ searchId }: { searchId: string; values?: TItem[] }) => {
    const at = atom((get) => {
      const productSearchItemsAtom = productSearchItemsState({ id: searchId })
      const allValues = get(productSearchItemsAtom)

      const selectedItems = pipe(allValues, (e) => ({
        ...e,
        values: pipe(
          e.values,
          A.map(
            flow((r) => {
              const product = new Product(r, searchId)
              // 선택된 선택옵션가져오기
              const selectedOptions = pipe(r, (t) =>
                pipe(
                  t.options,
                  O.fromNullable,
                  O.fold(
                    () => undefined,
                    flow(
                      // options중에 isRequired가 false인것만 가져오기
                      // 그래야지 선택옵션이니까
                      A.filter((y) => !y.isRequiredOption),
                      // 선택옵션이 있는지 없는지
                      AgetOrUndefined,
                      O.fromNullable,
                      O.fold(
                        () => undefined,
                        flow(
                          A.map(
                            flow((u) => ({
                              ...u,
                              values: filterCheckedValues(u.values),
                            }))
                          ),
                          // 선택된 values가 없다면 undefined를 리턴하게끔해야한다.
                          A.filter((u) => u.values !== undefined),
                          // 선택된 values가 없다면 undefined를 리턴하게끔해야한다.
                          AgetOrUndefined
                        )
                      )
                    )
                  )
                )
              )
              if (product.childComponentType === '단일상품') {
                const result = pipe(
                  r,
                  (t) => {
                    if (!t.checked) {
                      return undefined
                    }
                    return t
                  },
                  O.fromNullable,
                  O.fold(
                    () => undefined,
                    flow(
                      R.filterMapWithIndex(RkeyFilter('options')),
                      R.filterMapWithIndex(RkeyFilter('open')),
                      R.filterMapWithIndex(RkeyFilter('checked')),
                      (t) => ({
                        ...t,
                        ...(selectedOptions
                          ? { options: selectedOptions }
                          : {}),
                      })
                    )
                  )
                )
                return result as TItem
              }
              if (product.childComponentType === '단일상품_입력형옵션') {
                if (r.siOptions === undefined) {
                  return undefined
                }
                const result = pipe(
                  r.options,
                  O.fromNullable,
                  O.fold(
                    () => undefined,
                    flow(
                      A.filter((y) => y.isRequiredOption),
                      A.map((y) => ({
                        ...y,
                        values: pipe(
                          y.values,
                          O.fromNullable,
                          O.fold(
                            () => undefined,
                            flow(
                              A.filter((t) => t.checked),
                              AgetOrUndefined
                            )
                          )
                        ),
                      }))
                    )
                  )
                )
                return {
                  ...r,
                  options: [...(result || []), ...(selectedOptions || [])],
                }
              }
              if (
                product.childComponentType === '필수비조합옵션' ||
                product.childComponentType === '필수비조합옵션_입력형옵션'
              ) {
                if (!r.rncoOptions) {
                  return undefined
                }
                const values = pipe(
                  r.rncoOptions,
                  A.map(
                    flow(
                      Object.values,
                      A.filter((y) => y.type !== 'input')
                    )
                  ),
                  A.flatten,
                  A.map((y) => y.optionValueCode)
                )
                const result = pipe(
                  r,
                  R.filterMapWithIndex(RkeyFilter('open')),
                  R.filterMapWithIndex(RkeyFilter('checked')),
                  R.mapWithIndex((k, v) => {
                    if (k === 'rncoOptions' && v.length === 0) {
                      return undefined
                    }
                    if (k === 'options') {
                      return pipe(
                        r.options,
                        O.fromNullable,
                        O.fold(
                          () => v,
                          flow(
                            A.filter((y) => y.isRequiredOption),
                            A.map((y) => ({
                              ...y,
                              values: pipe(
                                y.values,
                                O.fromNullable,
                                O.fold(
                                  () => undefined,
                                  flow(
                                    A.filter((t) =>
                                      values.includes(t.valueCode)
                                    )
                                  )
                                )
                              ),
                            }))
                          )
                        )
                      )
                    }
                    return v
                  })
                )
                if (!result.rncoOptions && !selectedOptions) {
                  return undefined
                }
                const returnResult = {
                  ...result,
                  options: [...result.options, ...(selectedOptions || [])],
                } as TItem
                return returnResult
              }
              if (product.childComponentType === '필수조합옵션') {
                const selectedOptionDetails = pipe(
                  r,
                  (t) => t.optionDetails,
                  O.fromNullable,
                  O.fold(
                    () => undefined,
                    flow(
                      A.map((y) => y),
                      A.filter((y) => !!y.checked),
                      AgetOrUndefined,
                      O.fromNullable,
                      O.fold(
                        () => undefined,
                        flow(
                          A.map(
                            flow(
                              (y) => y,
                              R.filterMapWithIndex(RkeyFilter('checked'))
                            )
                          )
                        )
                      )
                    )
                  )
                )
                const result = pipe(
                  r,
                  R.filterMapWithIndex(RkeyFilter('open')),
                  R.filterMapWithIndex(RkeyFilter('options')),
                  R.filterMapWithIndex(RkeyFilter('checked')),
                  R.filterMapWithIndex(RkeyFilter('optionDetails'))
                )
                if (!selectedOptionDetails && !selectedOptions) {
                  return undefined
                }

                return {
                  ...result,
                  ...(selectedOptions ? { options: selectedOptions } : {}),
                  ...(selectedOptionDetails
                    ? { optionDetails: selectedOptionDetails }
                    : {}),
                } as TItem
              }
              if (product.childComponentType === '필수조합옵션_입력형옵션') {
                if (!r.rcoOptions) {
                  return undefined
                }
                const optionDetails = pipe(
                  r.rcoOptions,
                  A.map(
                    flow(
                      (y) => y.optionDetails,
                      R.map((y) => y.optionDetailCode),
                      Object.values
                    )
                  ),
                  A.flatten,
                  (y) => y[0]
                )
                const result = pipe(
                  r,
                  R.filterMapWithIndex(RkeyFilter('open')),
                  R.mapWithIndex((k, v) => {
                    if (k === 'rcoOptions' && v.length === 0) {
                      return undefined
                    }
                    if (k === 'optionDetails' && v) {
                      return pipe(
                        v as TOptionDetails[],
                        A.filter((y) => y.optionDetailCode === optionDetails)
                      )
                    }
                    if (k === 'options' && v) {
                      return pipe(
                        v as TOption[],
                        A.filter(
                          (y) => y.isRequiredOption && y.type === 'input'
                        )
                      )
                    }
                    return v
                  })
                )
                const returnResult = {
                  ...result,
                  options: [...result.options, ...(selectedOptions || [])],
                } as TItem
                return returnResult
              }
              return undefined
            })
          ),
          A.filter((r) => r !== undefined),
          AgetOrUndefined
        ) as TItem[],
      }))

      if (selectedItems.values === undefined) {
        return undefined
      }

      return selectedItems
    })

    at.debugLabel = `search(${searchId})-update`
    return at
  },
  (a, b) => a.searchId === b.searchId
)

// 선택한 총갯수
const productSearchItemsSelectedCountState = atomFamily(
  ({ searchId }: { searchId: string; count?: number }) => {
    const at = atom((get) => {
      const selectedItems = productSearchItemsSelectedState({ searchId })
      const allValues = get(selectedItems)
      if (allValues === undefined) {
        return undefined
      }
      const count = pipe(
        allValues,
        (e) => e.values,
        A.map(
          flow((r) => {
            const p = new Product(r, searchId)
            // 선택옵션
            const optionalOptionCount = pipe(
              r.options,
              O.fromNullable,
              O.fold(
                () => 0,
                flow(
                  A.filter((t) => !t.isRequiredOption),
                  A.reduce(0, (a, b) => a + (b.values?.length || 0))
                )
              )
            )
            if (p.childComponentType === '단일상품') {
              return optionalOptionCount + 1
            }
            if (p.childComponentType === '단일상품_입력형옵션') {
              if (r.siOptions === undefined) {
                throw new Error('필수비조합옵션에 siOptions가 없습니다.')
              }
              return optionalOptionCount + r.siOptions.length
            }
            if (p.childComponentType === '필수조합옵션') {
              if (r.optionDetails === undefined) {
                return 0
              }
              return pipe(
                r.optionDetails,
                A.filter((t) => t.isRequiredOption),
                (t) => t.length,
                // 선택옵션 더하기
                (t) => t + optionalOptionCount
              )
            }
            if (p.childComponentType === '필수조합옵션_입력형옵션') {
              if (r.rcoOptions === undefined) {
                throw new Error('필수비조합옵션에 rcoOptions가 없습니다.')
              }
              return r.rcoOptions.length + optionalOptionCount
            }
            if (
              p.childComponentType === '필수비조합옵션' ||
              p.childComponentType === '필수비조합옵션_입력형옵션'
            ) {
              if (r.rncoOptions === undefined) {
                throw new Error('필수비조합옵션에 rncoOptions가 없습니다.')
              }
              return r.rncoOptions.length + optionalOptionCount
            }
            return 0
          })
        ),
        A.reduce(0, (a, b) => a + b)
      )

      return { searchId, count }
    })

    return at
  },
  (a, b) => a.searchId === b.searchId
)

// 체크된 상품을 가져오는데 섹션에 넣어줄 포맷으로 변경해서 가져오기
const productSearchItemsSelectedToTProductCard001State = atomFamily(
  ({ searchId }: { searchId: string; values?: TItem[] }) => {
    const at = atom((get) => {
      const productSearchItemsSelectedAtom = productSearchItemsSelectedState({
        searchId,
      })
      const data = get(productSearchItemsSelectedAtom)
      if (data === undefined) {
        return undefined
      }

      const result = pipe(
        data.values,
        A.map(
          flow((e) => {
            const product = new Product(e, searchId)
            // 선택상품
            const optionalOption: TProductCard001.props[] | undefined = pipe(
              e,
              (r) => r.options,
              O.fromNullable,
              O.fold(
                () => undefined,
                flow(
                  A.filter((t) => !t.isRequiredOption),
                  A.map((t) =>
                    pipe(
                      // options isRequired를 필터링했다면 values는 항상있다.
                      t.values!,
                      flow(
                        A.map((y) => ({
                          id: `SIO=${y.optionDetailCode}`,
                          thumbnail: e.imageUrls[0],
                          name: e.prodName,
                          option: `${t.optionName} ${y.valueName}`,
                          originalPrice: pipe(
                            y.price,
                            O.fromNullable,
                            O.getOrElse(() => '0'),
                            Number
                          ),
                        }))
                      )
                    )
                  ),
                  A.flatten
                )
              )
            )
            // 단일상품
            if (product.childComponentType === '단일상품') {
              const singeResult: TProductCard001.props = {
                id: e.prodCode,
                thumbnail: e.imageUrls[0],
                name: e.prodName,
                option: '',
                originalPrice: pipe(e.price, Number),
              }
              return [singeResult, ...(optionalOption || [])]
            }
            if (product.childComponentType === '단일상품_입력형옵션') {
              if (e.siOptions === undefined) {
                throw new Error('필수비조합옵션에 siOptions가 없습니다.')
              }
              const empty: TProductCard001.props = {
                id: '',
                thumbnail: '',
                name: '',
                option: '',
                price: pipe(e.price, Number),
              }
              const rncoResult: TProductCard001.props[] = pipe(
                e.siOptions,
                A.map(
                  flow(
                    R.reduce([], (acc: any, curr) => [
                      ...acc,
                      {
                        id: `${curr.optionCode}:${curr.optionValueCode}`,
                        thumbnail: '',
                        name: e.prodName,
                        option: `${curr.optionName} ${
                          curr.optionValueName === curr.optionName
                            ? curr.optionValue
                            : curr.optionValueName
                        }`,
                      },
                    ]),
                    A.reduce(empty, (acc, curr: TProductCard001.props) => {
                      const accOriginalPrice = pipe(
                        acc.originalPrice,
                        O.fromNullable,
                        O.getOrElse(() => acc.price || 0)
                      )
                      const currOriginalPrice = pipe(
                        curr.originalPrice,
                        O.fromNullable,
                        O.getOrElse(() => 0)
                      )
                      return {
                        ...acc,
                        id: `${acc.id ? `${acc.id}:` : 'SICO='}${curr.id}`,
                        thumbnail: e.imageUrls[0],
                        name: e.prodName,
                        option: `${acc.option}${acc.option !== '' ? ' · ' : ''}${
                          curr.option
                        }`,
                        originalPrice: math
                          .chain(accOriginalPrice)
                          .add(currOriginalPrice)
                          .done(),
                      }
                    }),
                    ({ price, ...t }) => t
                  )
                )
              )
              return [...rncoResult, ...(optionalOption || [])]
            }
            // 필수조합옵션
            if (product.childComponentType === '필수조합옵션') {
              if (e.optionDetails === undefined) {
                return []
              }
              const requiredOption: TProductCard001.props[] = pipe(
                e.optionDetails,
                A.map((t) => ({
                  id: t.optionDetailCode,
                  thumbnail: e.imageUrls[0],
                  name: e.prodName,
                  option: pipe(
                    t.options,
                    O.fromNullable,
                    O.fold(
                      () => undefined,
                      flow(
                        A.reduce(
                          '',
                          (acc, curr) =>
                            `${acc} ${curr.optionName} ${curr.valueName} ·`
                        ),
                        (y) => y.slice(0, -2)
                      )
                    )
                  ),
                  originalPrice: t.optionDetailPrice,
                }))
              )
              return [...requiredOption, ...(optionalOption || [])]
            }
            if (product.childComponentType === '필수조합옵션_입력형옵션') {
              if (e.rcoOptions === undefined) {
                return []
              }
              const optionDetailPrice = pipe(
                e.rcoOptions,
                A.map(
                  flow(
                    (r) => r.optionDetails,
                    R.map((y) => y.optionDetailPrice),
                    Object.values,
                    A.reduce(0, (a, b) => a + b)
                  )
                )
              )
              log.debug('optionDetailPrice', optionDetailPrice)
              const rcoResult: TProductCard001.props[] = pipe(
                e.rcoOptions,
                A.map((r) => ({
                  id: pipe(
                    r.optionDetails,
                    R.map((y) => y.optionDetailCode),
                    Object.values,
                    (y) => y[0] as string
                  ),
                  thumbnail: e.imageUrls[0],
                  name: e.prodName,
                  option: pipe(
                    r.optionDetails,
                    R.map(
                      flow(
                        (y) => y.options,
                        A.map((y) => `${y.optionName} ${y.valueName}`),
                        (y) => y.join(' · ')
                      )
                    ),
                    Object.values,
                    (y) => y[0] as string,
                    (y) =>
                      y +
                      pipe(
                        e.rcoOptions,
                        O.fromNullable,
                        O.fold(
                          () => '',
                          flow(
                            A.map(
                              flow(
                                (t) => t.options,
                                R.map(
                                  (t) => `${t.optionName} ${t.optionValue}`
                                ),
                                Object.values,
                                (t) => t[0] as string
                              )
                            ),
                            (t) => t.join(' · '),
                            (t) => ` · ${t}`
                          )
                        )
                      )
                  ),
                  originalPrice: optionDetailPrice[0],
                }))
              )
              return [...rcoResult, ...(optionalOption || [])]
            }
            // 필수비조합옵션
            if (
              product.childComponentType === '필수비조합옵션' ||
              product.childComponentType === '필수비조합옵션_입력형옵션'
            ) {
              if (e.rncoOptions === undefined) {
                throw new Error('필수비조합옵션에 rncoOptions가 없습니다.')
              }
              const empty: TProductCard001.props = {
                id: '',
                thumbnail: '',
                name: '',
                option: '',
                originalPrice: 0,
              }
              const rncoResult: TProductCard001.props[] = pipe(
                e.rncoOptions,
                A.map(
                  flow(
                    R.reduce([], (acc: any, curr) => [
                      ...acc,
                      {
                        id: `${curr.optionCode}:${curr.optionValueCode}`,
                        thumbnail: '',
                        name: e.prodName,
                        option: `${curr.optionName} ${
                          curr.optionValueName === curr.optionName
                            ? curr.optionValue || curr.optionValueName
                            : curr.optionValueName
                        }`,
                        originalPrice: (acc.price || 0) + (curr.price || 0),
                      },
                    ]),
                    A.reduce(empty, (acc, curr: TProductCard001.props) => ({
                      ...acc,
                      id: `${acc.id ? `${acc.id}:` : 'RNCO='}${curr.id}`,
                      thumbnail: e.imageUrls[0],
                      name: e.prodName,
                      option: `${acc.option}${acc.option !== '' ? ' · ' : ''}${
                        curr.option
                      }`,
                      originalPrice:
                        pipe(
                          acc.originalPrice,
                          O.fromNullable,
                          O.getOrElse(() => 0)
                        ) +
                        pipe(
                          curr.originalPrice,
                          O.fromNullable,
                          O.getOrElse(() => 0)
                        ),
                    }))
                  )
                )
              )
              return [...rncoResult, ...(optionalOption || [])]
            }
            return []
          })
        ),
        A.flatten
      )

      return result
    })

    at.debugLabel = `search(${searchId})-update-selectedConvertType(TProductCard001)`
    return at
  },
  (a, b) => a.searchId === b.searchId
)

// 체크된 상품을 가져오는데 confirm 데이터에 넣어줄 포맷으로 변경해서 가져오기
type TOptionDataList = {
  optionType: string
  optionCode: string
  valueCode: string
}
type TOrderAddConfirmItems = {
  orderSectionItemCode?: string
  prodCode: string
  prodOptionDetailCode?: string
  qty?: number
  baseItemPrice: number
  itemPrice: number
  isIndividualOption: string
  isRequireOption: string
  optionDataList?: TOptionDataList[]
  [key: string]: unknown
  /**
   * 금액계산만을 위한 타입을 임시로 상품추가타입에 끼워넣어서 가져와서 사용할 것이다.
   * 상품추가 API -> https://api.oms.imstage.me/admin/docs#tag/[[EC]]A3[[BC]]EB[[AC]]B8-[[EC]]84[[B9]]EC[[85]]98/operation/OrderSectionController_createOrderSectionAndItem
   * 금액계산 API -> https://api.oms.imstage.me/admin/docs#tag/[[EC]]A3[[BC]]EB[[AC]]B8-[[EC]]84[[B9]]EC[[85]]98/operation/OrderSectionController_calclateAddItemsPrice
   * 다른부분이 조금있다.
   */
  type: 'normal' | 'digital' | 'subscribe' | string
}
const productSearchItemsSelectedToConfirmState = atomFamily(
  ({ searchId }: { searchId: string; values?: TItem[] }) => {
    const at = atom((get) => {
      const productSearchItemsSelectedAtom = productSearchItemsSelectedState({
        searchId,
      })
      const data = get(productSearchItemsSelectedAtom)
      if (data === undefined) {
        return undefined
      }

      const result = pipe(
        data.values,
        A.map(
          flow(
            (e) => {
              const product = new Product(e, searchId)
              // 선택상품
              const optionalOption: TOrderAddConfirmItems[] | undefined = pipe(
                e,
                (r) => r.options,
                O.fromNullable,
                O.fold(
                  () => undefined,
                  flow(
                    A.filter((t) => !t.isRequiredOption),
                    A.map((t) => {
                      if (t.values === undefined) {
                        return []
                      }
                      return pipe(
                        // options isRequired를 필터링했다면 values는 항상있다.
                        t.values!,
                        A.map((y) => ({
                          optionType: t.type,
                          optionCode: t.optionCode,
                          valueCode: y.valueCode,
                          optionDetailCode: y.optionDetailCode,
                        })),
                        A.map(
                          ({ optionDetailCode }): TOrderAddConfirmItems => ({
                            // orderSectionItemCode
                            prodCode: e.prodCode,
                            baseItemPrice: pipe(
                              t.values!,
                              A.reduce(0, (a, b) => a + Number(b.price))
                            ),
                            itemPrice: pipe(
                              t.values!,
                              A.reduce(0, (a, b) => a + Number(b.price))
                            ),
                            prodOptionDetailCode: optionDetailCode,
                            isRequireOption: t.isRequiredOption ? 'Y' : 'N',
                            isIndividualOption: t.isIndividualOption
                              ? 'Y'
                              : 'N',
                            type: e.type,
                          })
                        )
                      )
                    }),
                    A.flatten
                  )
                )
              )
              // 단일상품
              if (product.childComponentType === '단일상품') {
                const singeResult: TOrderAddConfirmItems = {
                  prodCode: e.prodCode,
                  baseItemPrice: pipe(e.price, Number),
                  itemPrice: pipe(e.price, Number),
                  type: e.type,
                  isIndividualOption: e.isIndividualOption ? 'Y' : 'N',
                  isRequireOption: 'N',
                }
                return [singeResult, ...(optionalOption || [])]
              }
              if (product.childComponentType === '단일상품_입력형옵션') {
                if (e.siOptions === undefined) {
                  throw new Error('필수비조합옵션에 siOptions가 없습니다.')
                }
                const optionDataLists: TOptionDataList[][] = pipe(
                  e.siOptions,
                  A.map(
                    flow(
                      (r) => r,
                      R.toArray,
                      (array) => array.map(([, value]) => value),
                      A.map(
                        (t): TOptionDataList => ({
                          optionType: t.type,
                          optionCode: t.optionCode,
                          valueCode: t.optionValue || '',
                        })
                      )
                    )
                  )
                )
                const fnSingeResult = (
                  o: TOptionDataList[]
                ): TOrderAddConfirmItems => ({
                  prodCode: e.prodCode,
                  baseItemPrice: pipe(e.price, Number),
                  itemPrice: pipe(e.price, Number),
                  type: e.type,
                  optionDataList: o,
                  isIndividualOption: e.isIndividualOption ? 'Y' : 'N',
                  isRequireOption: 'Y',
                })
                return [
                  ...pipe(optionDataLists, A.map(fnSingeResult)),
                  ...(optionalOption || []),
                ]
              }
              // 필수조합옵션
              if (product.childComponentType === '필수조합옵션') {
                if (e.optionDetails === undefined) {
                  return []
                }
                const requiredOption: TOrderAddConfirmItems[] = pipe(
                  e.optionDetails,
                  A.map((t) => ({
                    prodCode: e.prodCode,
                    prodOptionDetailCode: t.optionDetailCode,
                    baseItemPrice: t.optionDetailPrice,
                    itemPrice: t.optionDetailPrice,
                    type: e.type,
                    isIndividualOption: e.isIndividualOption ? 'Y' : 'N',
                    isRequireOption: 'Y',
                  }))
                )
                return [...requiredOption, ...(optionalOption || [])]
              }
              if (product.childComponentType === '필수조합옵션_입력형옵션') {
                if (e.rcoOptions === undefined) {
                  throw new Error('필수비조합옵션에 rcoOptions가 없습니다.')
                }
                const rncoResult: TOrderAddConfirmItems[] = pipe(
                  e.rcoOptions,
                  A.map(
                    flow((r) => {
                      // `r.optionDetails`는 배열이지만 1개만 있다.
                      // `r.options`는 배열로 여러개를 가질수있다.
                      const optionDataList: TOrderAddConfirmItems['optionDataList'] =
                        pipe(
                          r.options,
                          R.toArray,
                          (array) => array.map(([, value]) => value),
                          A.map((t) => ({
                            optionType: t.type,
                            optionCode: t.optionCode,
                            valueCode: t.optionValue || '',
                          }))
                        )
                      return pipe(
                        r.optionDetails,
                        R.reduce([], (acc: any, curr) => [
                          ...acc,
                          {
                            prodOptionDetailCode: curr.optionDetailCode,
                            baseItemPrice:
                              (acc?.baseItemPrice || 0) +
                              curr.optionDetailPrice,
                            itemPrice:
                              (acc?.itemPrice || 0) + curr.optionDetailPrice,
                          },
                        ]),
                        A.reduce(
                          {} as TOrderAddConfirmItems,
                          (acc, curr: TOrderAddConfirmItems) => ({
                            prodCode: e.prodCode,
                            prodOptionDetailCode: curr?.prodOptionDetailCode,
                            baseItemPrice:
                              (acc?.baseItemPrice || 0) +
                              (curr?.baseItemPrice || 0),
                            itemPrice:
                              (acc?.itemPrice || 0) + (curr?.itemPrice || 0),
                            type: e.type,
                            isIndividualOption: e.isIndividualOption
                              ? 'Y'
                              : 'N',
                            isRequireOption: 'Y',
                          })
                        ),
                        (t) => ({
                          ...t,
                          optionDataList,
                        })
                      )
                    })
                  )
                )
                return [...rncoResult, ...(optionalOption || [])]
              }
              // 필수비조합옵션
              if (
                product.childComponentType === '필수비조합옵션' ||
                product.childComponentType === '필수비조합옵션_입력형옵션'
              ) {
                if (e.rncoOptions === undefined) {
                  throw new Error('필수비조합옵션에 rncoOptions가 없습니다.')
                }
                const rncoResult: TOrderAddConfirmItems[] = pipe(
                  e.rncoOptions,
                  A.map(
                    flow(
                      R.reduce([], (acc: any, curr) => [
                        ...acc,
                        {
                          baseItemPrice: (acc?.baseItemPrice || 0) + curr.price,
                          itemPrice: (acc?.itemPrice || 0) + curr.price,
                          optionDataList: [
                            ...(acc?.optionDataList || []),
                            {
                              optionType: curr.type,
                              optionCode: curr.optionCode,
                              valueCode:
                                curr.type === 'input'
                                  ? curr.optionValue
                                  : curr.optionValueCode,
                            },
                          ],
                        },
                      ]),
                      A.reduce(
                        {} as TOrderAddConfirmItems,
                        (acc, curr: TOrderAddConfirmItems) => ({
                          prodCode: e.prodCode,
                          baseItemPrice:
                            (acc?.baseItemPrice || 0) +
                            (curr?.baseItemPrice || 0),
                          itemPrice:
                            (acc?.itemPrice || 0) + (curr?.itemPrice || 0),
                          optionDataList: [
                            ...(acc?.optionDataList || []),
                            ...(curr?.optionDataList || []),
                          ],
                          type: e.type,
                          isIndividualOption: e.isIndividualOption ? 'Y' : 'N',
                          isRequireOption: 'Y',
                        })
                      )
                    )
                  )
                )
                return [...rncoResult, ...(optionalOption || [])]
              }
              return []
            },
            O.fromNullable,
            O.fold(
              () => [],
              (t) => t
            )
          )
        ),
        A.flatten
      )

      return result
    })

    at.debugLabel = `search(${searchId})-update-convert-confirmData`
    return at
  },
  (a, b) => a.searchId === b.searchId
)

// ================== 검색결과 상태 (END) ==================

export const useProductSearchAtom = {
  items: (id: string) =>
    useAtom(React.useMemo(() => productSearchItemsState({ id }), [id])),
  getItems: (id: string) =>
    useAtomValue(React.useMemo(() => productSearchItemsState({ id }), [id])),
  setItems: (id: string) =>
    useSetAtom(React.useMemo(() => productSearchItemsState({ id }), [id])),
  /**
   * (Readonly) 체크된 상품만 가져오기
   */
  selectedItems: (searchId: string) =>
    useAtomValue(
      React.useMemo(
        () =>
          productSearchItemsSelectedState({
            searchId,
          }),
        [searchId]
      )
    ),
  /**
   * (Readonly) 체크된 상품의 총갯수 가져오기
   */
  allSelectedCount: (searchId: string) =>
    useAtomValue(
      React.useMemo(
        () => productSearchItemsSelectedCountState({ searchId }),
        [searchId]
      )
    ),
  /**
   * (Readonly)
   */
  selectedItemsToTProductCard001: (searchId: string) =>
    useAtomValue(
      React.useMemo(
        () =>
          productSearchItemsSelectedToTProductCard001State({
            searchId,
          }),
        [searchId]
      )
    ),
  selectedItemsToConfirm: (searchId: string) =>
    useAtomValue(
      React.useMemo(
        () => productSearchItemsSelectedToConfirmState({ searchId }),
        [searchId]
      )
    ),
  itemAtom: productSearchItemState,
  itemSelectedAtom: productSearchItemSelectedState,
  item: (searchId: string, prodCode: string) =>
    useAtom(
      React.useMemo(
        () => productSearchItemState({ searchId, prodCode }),
        [prodCode, searchId]
      )
    ),
  getItem: (searchId: string, prodCode: string) =>
    useAtomValue(
      React.useMemo(
        () => productSearchItemState({ searchId, prodCode }),
        [prodCode, searchId]
      )
    ),
  setItem: (searchId: string, prodCode: string) =>
    useSetAtom(
      React.useMemo(
        () => productSearchItemState({ searchId, prodCode }),
        [prodCode, searchId]
      )
    ),
}
