import React from 'react'
import { 상수_기본탭코드 } from '~/entities/@x'
import { useActiveTab } from '~/shared/hooks/use-tab-code'
import { 믹스패널 } from '~/shared/mixpanel'

export function useTrackOrdersPage() {
  const activeTab = useActiveTab()

  React.useEffect(() => {
    if (activeTab.adminOrderSearchTabCode !== 상수_기본탭코드) {
      dataLayer.push({
        /**
         * @description
         * event는 view이지만 event이름은 click인 이유
         * 해당 버튼에 click 이벤트로 넣는 것보다 페이지 전환시 해당 페이지 로드가 되었다는 것을 인지하는 것이다.
         * click 버튼과 보기를 동시에 이벤트를 잡은다.
         * 해당 tabCode의 버튼을 클릭한다는 것은 해당 뷰를 보는 것과 동일하다.
         */
        event: 믹스패널.click_bo_oms_order_list_tap_select_tap,
        params: {
          tab_code: activeTab.adminOrderSearchTabCode,
          tab_name: activeTab.name,
        },
      })
    }
  }, [activeTab.adminOrderSearchTabCode])
}
