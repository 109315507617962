import { ApiClient } from '~/shared/api'
import {
  TPostPayloadBulkActionPaymentApplyAllDto,
  TPostResBulkActionPaymentApplyAllDto,
} from './bulk-action-payment-apply-all.type'
import { AxiosResponse } from 'axios'
import { postResBulkActionPaymentApplyAllDto } from './bulk-action-payment-apply-all.dto'
import * as Sentry from '@sentry/react'

const axios = ApiClient()

export async function postBulkActionPaymentApplyAll({
  body,
}: {
  body: TPostPayloadBulkActionPaymentApplyAllDto
}) {
  type R = typeof body
  type T = TPostResBulkActionPaymentApplyAllDto
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/payment/confirmation/all', body)
    .then((res) => res.data)

  try {
    postResBulkActionPaymentApplyAllDto.parse(result.data)
  } catch (error) {
    Sentry.captureException(error)
  }
  return result.data
}
