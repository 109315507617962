import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import type {
  TPostResBulkActionSectionInvoicePrintValidateAllSchema,
  TPostPayloadBulkActionSectionInvoicePrintValidateAllSchema,
} from './bulk-action-section-invoice-print-validate-all.type'
import { postResBulkActionSectionInvoicePrintValidateAllSchema } from './bulk-action-section-invoice-print-validate-all.schema'
import * as Sentry from '@sentry/react'

const axios = ApiClient()

export async function postBulkActionSectionInvoicePrintValidateAll({
  body,
}: {
  body: TPostPayloadBulkActionSectionInvoicePrintValidateAllSchema
}) {
  type R = typeof body
  type T = TPostResBulkActionSectionInvoicePrintValidateAllSchema
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/section/invoice/print/validate/all', body)
    .then((res) => res.data)

  try {
    postResBulkActionSectionInvoicePrintValidateAllSchema.parse(result.data)
  } catch (error) {
    Sentry.captureException(error)
  }
  return result.data
}
