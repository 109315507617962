import './app/bootstrap'
import './app/index.css'
import '@imwebme/clay-stylesheet'

import { HelmetProvider } from 'react-helmet-async'
import ReactDOM from 'react-dom/client'
import { routes } from '@imwebme/generouted-react-router'
import { sentryCreateBrowserRouter } from './app/provider/sentry'
import { RouterProvider } from 'react-router-dom'
import { useEffect, useState } from 'react'

const SDKProvider = () => {
  const router = sentryCreateBrowserRouter(routes)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const init = async () => {
      await ImwebOAuth2ClientAPI.init()
      setIsLoading(false)
    }
    init()
  }, [])

  if (!isLoading) {
    return (
      <HelmetProvider>
        <RouterProvider router={router} />
      </HelmetProvider>
    )
  }

  return null
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <SDKProvider />
)
