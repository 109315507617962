import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import {
  type TPostResBulkActionSectionDeliveryHoldValidateAllSchema,
  type TPostPayloadBulkActionSectionDeliveryHoldValidateAllSchema,
  postResBulkActionSectionDeliveryHoldValidateAllSchema,
} from '.'

const axios = ApiClient()

export async function postBulkActionSectionDeliveryHoldValidateAll({
  body,
}: {
  body: TPostPayloadBulkActionSectionDeliveryHoldValidateAllSchema
}) {
  type R = typeof body
  type T = TPostResBulkActionSectionDeliveryHoldValidateAllSchema
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/section/delivery/hold/validate/all', body)
    .then((res) => res.data)

  try {
    postResBulkActionSectionDeliveryHoldValidateAllSchema.parse(result.data)
  } catch (error) {
    Sentry.captureException(error)
  }

  return result.data
}
