import React from 'react'
import { OrderToastContainer } from './order-toast.container'

export function OrderToastPack() {
  return (
    <React.Suspense>
      <OrderToastContainer />
    </React.Suspense>
  )
}
