/**
 * @description
 * `@imwebme/generouted-react-router`에서 `_app_provider.tsx`를 가져와서 사용할 수 있도록 했다.
 * `_app_provider.tsx`는 Router와 Modals를 모두 감싸는 provider이다.
 */
import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import {
  AppI18nextProvider,
  AuthProvider,
  JotaiProvider,
  QueryStateManagementProvider,
  RecoilProvider,
  SiteInfoProvider,
} from '~/app/provider'
import { BrwoserRouterEventContainer } from '~/app/provider/react-router-dom'

// 각 Provider를 래핑하는 함수
const wrap =
  (Provider: (props: React.PropsWithChildren) => React.ReactNode) =>
  (children: React.ReactNode) => <Provider>{children}</Provider>

const AppProvider =
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  (props: P) => {
    const ComposedProviders = ({ children }: { children: React.ReactNode }) =>
      pipe(
        children,
        wrap(BrwoserRouterEventContainer),
        wrap(SiteInfoProvider),
        wrap(AuthProvider),
        wrap(RecoilProvider),
        wrap(JotaiProvider),
        wrap(QueryStateManagementProvider),
        wrap(AppI18nextProvider)
      )
    return (
      <ComposedProviders>
        <WrappedComponent {...props} />
      </ComposedProviders>
    )
  }

const AppChildren = ({ children }: React.PropsWithChildren) => (
  <React.Fragment>{children}</React.Fragment>
)

export default AppProvider(AppChildren)
