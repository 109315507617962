import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPatchResExpiredTimeDto,
  type TPatchPayloadExpiredTimeDto,
  patchResExpiredTimeDto,
  patchPayloadExpiredTimeDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/jpkVuy
 * @description 결제(무통장, 가상계좌) 입금 기한 변경
 */
export function patchExpiredTime({ paymentCode }: { paymentCode: string }) {
  return async function (body: TPatchPayloadExpiredTimeDto) {
    type T = TPatchResExpiredTimeDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/payment/${paymentCode}/expired-time`, body)
      .then((res) => res.data)

    try {
      patchResExpiredTimeDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}
