import React from 'react'
import { TGetPayloadOrderListDto } from './order-list.type'
import { useOrderSearchQuery } from '~/shared/hooks/use-order-search-query'
import { useOrderSearchFilterQueryString } from '~/container/order-search-filter/order-search-filter.store'
import { usePageNumber } from '~/shared/hooks/use-page-number'
import { getTabQueryString } from '~/container/orders/orders-fn'
import { useActiveTab } from '~/shared/hooks/use-tab-code'

// TODO getTabQueryString의 폴더 위치가 변경될 필요가 있습니다.

/**
 * 주문목록 조회에 필요한 쿼리 파라미터를 반환합니다.
 */
export function useOrderListQueryParams(
  pagination?: boolean
): TGetPayloadOrderListDto {
  const activeTab = useActiveTab()
  const pageNumber = usePageNumber()
  const [searchKeyword] = useOrderSearchQuery()
  const filterQueryString = useOrderSearchFilterQueryString()

  const ordersQueryString = React.useMemo(() => {
    const baseQueryString = {
      ...(!!searchKeyword && { keyword: searchKeyword }),
      ...getTabQueryString(activeTab.columnCondition),
      ...filterQueryString,
    }

    if (pagination) {
      return baseQueryString
    }

    return {
      ...baseQueryString,
      page: pageNumber,
      sort: 'wtime' as const,
    }
  }, [pageNumber, filterQueryString, searchKeyword, activeTab, pagination])

  return ordersQueryString
}
