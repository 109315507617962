import { useRef, createRef, useEffect, useState, useCallback } from 'react'
import { useSuspenseQuery } from '@tanstack/react-query'
import { debounce } from 'lodash'
import { useLL, __ } from '~/shared/i18n'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CalcHiddenTabs } from './숨김탭정의/calc-hidden-tabs'
import { orderSearchTabQueryOptions } from '~/entities/order-search-tab'
import { useTabCode } from '~/shared/hooks/use-tab-code/use-tab-code'
import { TabItem } from './orders-tab'
import { match } from 'ts-pattern'
import { useScrollbarWidth } from 'react-use'
import { 상수_전체탭이름 } from '~/entities/@x'
import { useLastVisibleTabIdx } from './orders-tab.store'

const MAX_TAB_COUNT = 30

const TEMP_NEW_TAB: TabItem = {
  code: 'temp-new-tab-code',
  name: __('새로운 탭'),
  isAllTab: false,
  count: 0,
  sortNo: 0,
}

/**
 * 주문탭 API 호출 및 로직
 */
export const useOrdersTabView = () => {
  const location = useLL()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const tabCodeFromSearchParams = searchParams.get('tabCode')
  const 탭생성페이지인가 = location.pathname.includes('/orders/tab/new')
  const 탭수정페이지인가 =
    location.pathname.includes('/orders/tab/edit') &&
    tabCodeFromSearchParams !== null

  const tabCode = match<boolean>(true)
    .with(탭생성페이지인가, () => TEMP_NEW_TAB.code)
    .with(탭수정페이지인가, () => tabCodeFromSearchParams as string)
    .otherwise(() => useTabCode())

  const { data: tabList } = useSuspenseQuery({
    ...orderSearchTabQueryOptions(),
    select: (data) => {
      const serverData = data.data.list.map((item) => ({
        code: item.adminOrderSearchTabCode,
        name: item.name,
        isAllTab: item.name === 상수_전체탭이름,
        count: item.count,
        sortNo: item.sortNo,
      }))
      return 탭생성페이지인가 ? serverData.concat([TEMP_NEW_TAB]) : serverData
    },
  })
  const [orderedTabList, setOrderedTabList] = useState(tabList)
  const activeTab =
    orderedTabList.find((tab) => tab.code === tabCode) ?? orderedTabList[0]

  const [lastVisibleTabIdx, setLastVisibleTabIdx] = useLastVisibleTabIdx()
  const containerRef = useRef<HTMLDivElement>(null)
  const tabItemRef = useRef(
    new Array(orderedTabList.length)
      .fill(0)
      .map(() => createRef<HTMLDivElement>())
  )
  const activeTabIdx =
    orderedTabList.findIndex((tab) => tab.code === activeTab.code) ?? 0
  const scrollbarWidth = useScrollbarWidth() ?? 0
  const updateLastVisibleTabIdx = useCallback(
    debounce(() => {
      if (containerRef.current) {
        const calcHiddeonTabs = new CalcHiddenTabs(
          containerRef.current.offsetWidth - 76 - scrollbarWidth, // 순수 탭목록의 너비만 구하기 위해 padding과 +버튼 너비 제외
          tabItemRef.current.map((e) => e.current?.offsetWidth ?? 0),
          activeTabIdx
        )
        setLastVisibleTabIdx(calcHiddeonTabs.getLastVisibleTabIdx())
      }
    }, 16),
    [containerRef.current, tabItemRef.current, activeTabIdx]
  )

  const handleAddTab = useCallback(() => {
    if (orderedTabList.length < MAX_TAB_COUNT) {
      navigate(
        `/orders/tab/new?from=${location.pathname}${location.search}${location.hash}`
      )
    } else {
      toast.success(
        __('최대 생성가능한 탭 수는 {{count}}개입니다.', {
          count: MAX_TAB_COUNT,
        }),
        {
          position: 'top-center',
        }
      )
    }
  }, [location, orderedTabList.length])

  const handleTabClick = useCallback(
    (tabIdx: number) => {
      const clickedTabCode =
        orderedTabList[tabIdx]?.code ?? orderedTabList[0].code
      navigate(`/orders/tab/${clickedTabCode}`)
    },
    [orderedTabList]
  )

  useEffect(() => {
    setOrderedTabList(tabList)
  }, [tabList])

  useEffect(() => {
    updateLastVisibleTabIdx()
  }, [updateLastVisibleTabIdx, orderedTabList])

  useEffect(() => {
    if (!containerRef.current) {
      return
    }

    const resizeObserver = new ResizeObserver(() => {
      updateLastVisibleTabIdx()
    })

    resizeObserver.observe(containerRef.current)

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current)
      }
      resizeObserver.disconnect()
    }
  }, [updateLastVisibleTabIdx, containerRef.current])

  return {
    tabList,
    orderedTabList,
    setOrderedTabList,
    lastVisibleTabIdx,
    activeTab,
    activeTabIdx,
    containerRef,
    tabItemRef,
    handleAddTab,
    handleTabClick,
  }
}
