import { pipe, flow } from 'fp-ts/function'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import { type TGetResSitePgSchema, getResSitePgSchema } from '.'
import * as Sentry from '@sentry/react'

const axios = ApiClient()

/**
 * 스마트스토어 OPM99 노출 임시 제거
 */
function filterOPM99(data: ApiResponse<TGetResSitePgSchema>) {
  const result = data.data.filter((item) => item.methodCd !== 'OPM99')
  data.data = result
  return data
}
/**
 * @API문서
 * @description site-pg
 */
export async function getSitePg() {
  const result = await axios
    .get<ApiResponse<TGetResSitePgSchema>>('/site/pg')
    .then((res) => pipe(res.data, filterOPM99))

  try {
    getResSitePgSchema.parse(result.data)
  } catch (error) {
    Sentry.captureException(error)
  }
  return result
}
