import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import type {
  TPostPayloadBulkActionCashReceiptValidateAllDto,
  TPostResBulkActionCashReceiptValidateAllDto,
} from './bulk-action-cash-receipt-validate-all.type'
import { postResBulkActionCashReceiptValidateAllDto } from './bulk-action-cash-receipt-validate-all.dto'
import * as Sentry from '@sentry/react'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/6YrE12
 * @description 현금영수증 발급 대량 처리 검증
 */
export async function postBulkActionCashReceiptValidateAll<
  T = TPostResBulkActionCashReceiptValidateAllDto,
  R = TPostPayloadBulkActionCashReceiptValidateAllDto,
>({ body }: { body: R }) {
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/cash-receipt/validate/all', body)
    .then((res) => res.data)

  try {
    postResBulkActionCashReceiptValidateAllDto.parse(result.data)
  } catch (error) {
    Sentry.captureException(error)
  }
  return result.data
}
