import { queryOptions } from '@tanstack/react-query'
import {
  getOrderSearchTab,
  getOrderSearchTabDetail,
} from './order-search-tab.api'

export function orderSearchTabQueryOptions() {
  return queryOptions({
    queryKey: ['order-search-tab'],
    queryFn: getOrderSearchTab(),
    staleTime: 1000 * 10,
  })
}

export function orderSearchTabDetailQueryOptions(tabCode: string) {
  return queryOptions({
    queryKey: ['order-search-tab-detail', tabCode],
    queryFn: getOrderSearchTabDetail(tabCode),
    staleTime: 1000 * 10,
  })
}
