import { queryOptions } from '@tanstack/react-query'
import { getExternalOrderDelivery } from './external-order-delivery.api'

export function externalOrderDeliveryQueryOptions(params: {
  orderCode: string
  orderDeliveryCode: string
}) {
  return queryOptions({
    queryKey: ['external-order-delivery'],
    queryFn: getExternalOrderDelivery(params),
  })
}
