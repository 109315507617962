import { queryOptions } from '@tanstack/react-query'
import { getMenuBanner } from '.'

export function menuBannerQueryOptions() {
  return queryOptions({
    queryKey: ['menu-banner'],
    queryFn: getMenuBanner(),
    staleTime: 1000 * 10,
  })
}
