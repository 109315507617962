import { match, P } from 'ts-pattern'
import React from 'react'
import { __ } from '~/shared/i18n'

interface PriceState {
  type: 'percent' | 'fixed'
  discountValue: number | null
  baseItemPrice: number //판매가
  error?: string | null
  itemPrice?: number | null
}

export interface PriceAction {
  payload?: string
  type?: 'percent' | 'fixed'
}

function discountReducer(state: PriceState, action: PriceAction) {
  const { payload, type } = action

  // payload와 type 모두다 undefined인 경우
  if (payload === undefined && type === undefined) {
    return state
  }
  // payload만 설정할때는 type이 undefined이다 그래서 type이 undefined이면서 빈문자열인 경우
  if (type === undefined && payload === '') {
    return {
      ...state,
      itemPrice: null,
      error: null,
    }
  }

  // 변경할 기준값
  const numValue = match({
    arg: payload,
    state: state.discountValue,
  })
    .with(
      { arg: P.nullish, state: P.nonNullable },
      ({ state: _price }) => _price
    )
    .with(
      {
        arg: P.when((arg) => {
          if (arg === undefined || '') {
            return false
          }
          const _arg = Number(arg)
          return !Number.isNaN(_arg) && Number.isInteger(_arg)
        }),
      },
      ({ arg }) => Number(arg)
    )
    .otherwise(() => 0)

  // 아이템 가격
  const itemPrice = match({ arg: type, state: state.type })
    .with(
      { arg: undefined, state: 'percent' },
      { arg: 'percent' },
      () => state.baseItemPrice * (1 - numValue / 100)
    )
    .with(
      { arg: undefined, state: 'fixed' },
      { arg: 'fixed' },
      () => state.baseItemPrice - numValue
    )
    .otherwise(() => 0)

  const baseNextState: PriceState = {
    ...state,
    type: type || state.type,
    discountValue: numValue,
    itemPrice,
  }

  // 에러 메시지
  const error = match({ type: baseNextState.type, numValue })
    .with(
      {
        type: 'fixed',
        numValue: P.when((v) => v > baseNextState.baseItemPrice),
      },
      () => __('판매가보다 낮은 값을 입력해주세요')
    )
    .with({ type: 'percent', numValue: P.when((v) => v > 100) }, () =>
      __('100 이하의 값을 입력해주세요')
    )
    .with({ numValue: P.when((v) => v < 0) }, () =>
      __('0 이상의 값을 입력해주세요')
    )
    .otherwise(() => null)

  return { ...baseNextState, error }
}

function initPriceArgs({
  type,
  discountValue,
  baseItemPrice,
}: {
  type: 'percent' | 'fixed'
  discountValue: number | null
  baseItemPrice: number
}) {
  return {
    type,
    discountValue,
    baseItemPrice,
    error: null,
  }
}

export function useDiscountPrice(props: {
  type: 'percent' | 'fixed'
  discountValue: number | null
  baseItemPrice: number
}) {
  return React.useReducer(discountReducer, props, initPriceArgs)
}
