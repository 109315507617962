import { pipe, flow } from 'fp-ts/function'
import { Outlet } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { ToastContainer } from '~/app/provider/toast-container'
import { Loading } from '~/shared/components/loading'
import { ErrorCard } from '~/shared/components/error'
import { getPermission } from '~/entities/permission'
import {
  store,
  permissionAtom,
} from '~/app/provider/permission/permission.store'
import { Redirects } from '~/app/redirects'

export const Catch = () => <ErrorCard isFull />
export const Pending = () => <Loading />

export const Loader = async () => {
  const init = async () => {
    try {
      // 해당 유저의 권한을 가져온다
      const data = await getPermission()({})

      store.set(permissionAtom, data)

      return true
    } catch (error) {
      return false
    }
  }
  return await init()
}

/**
 * @description
 * 빌드후 배포시 dist파일이 없어진다. 배포는 정상적으로되었지만 파일이 없는 것임으로 해당 함수로 새로고침해서 새버전의 앱으로 변경한다
 */
window.addEventListener('vite:preloadError', () => {
  try {
    Sentry.captureMessage(
      '앱로드가 실패하였고 캐시초기화 새로고침을 시도합니다.'
    )
  } catch (error) {
    // eslint-disable-next-line no-restricted-syntax
    console.error('error', error)
  } finally {
    // window location을 변경해서 캐시를 무효화 시킨다
    const currentUrl = window.location.href
    const url = new URL(currentUrl)
    url.searchParams.set('timestemp', String(new Date().getTime()))
    window.location.href = url.toString()
    // window.parent.postMessage(
    //   {
    //     source: 'refresh',
    //     message: 'refresh',
    //   },
    //   '*'
    // )
  }
})

function App() {
  return (
    <main>
      <ToastContainer />
      <Redirects>
        <Outlet />
      </Redirects>
      <div className="pt-[200px]" />
    </main>
  )
}

// 로컬환경에서는 Sentry를 사용하지 않는다.
export default pipe(App, (A) => {
  if (import.meta.env.VITE_NODE_ENV === 'local') {
    return pipe(A)
  }
  return pipe(A, Sentry.withProfiler)
})
