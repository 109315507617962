import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import * as S from 'fp-ts/string'
import * as E from 'fp-ts/Either'
import { ISpecification, Spec } from 'spec-pattern-ts'
import {
  OrderDetailData,
  OrderSectionDto,
  PaymentWithMethodDetailData,
} from '../..'
import { 모델_주문서 } from '.'
import {
  YN,
  상수_결제상태,
  상수_결제수단,
  상수_결제타입,
  상수_섹션상태,
  상수_주문상태,
  상수_주문타입,
  상수_판매채널,
} from '~/entities/@x'
import { 모델_주문섹션, 스펙_주문섹션 } from '../order-section'

export const 주문서: ISpecification<모델_주문서> = new Spec(
  flow((candidate) => candidate.data.orderCode, O.fromNullable, O.isSome)
)
// ====================================== 사전준비물 스펙

export const 일반주문섹션스펙에부합하는섹션존재유무 = (
  $sectionSpec: ISpecification<모델_주문섹션>
): ISpecification<모델_주문서> =>
  new Spec((candidate) =>
    pipe(
      candidate.data.orderSectionList,
      O.fromNullable,
      O.fold(
        () => false,
        flow(
          A.findFirst((e) => {
            const $section = new 모델_주문섹션(e)
            return $sectionSpec.isSatisfiedBy($section)
          }),
          O.isSome
        )
      )
    )
  )

export const 일반주문섹션스펙some = (
  $sectionSpec: ISpecification<모델_주문섹션>[]
): ISpecification<모델_주문서> =>
  new Spec((candidate) =>
    pipe(
      candidate.data.orderSectionList,
      O.fromNullable,
      O.fold(
        () => false,
        flow(
          A.filter((e) => {
            const $section = new 모델_주문섹션(e)
            // 한가지라도 유요해야한다
            const result = pipe(
              $sectionSpec,
              A.some((spec) => spec.isSatisfiedBy($section))
            )
            return result
          }),
          (e) => e.length === candidate.data.orderSectionList!.length
        )
      )
    )
  )

const 섹션스펙필터링 = (
  sectionSpec: ISpecification<모델_주문섹션>
): ISpecification<모델_주문서> =>
  new Spec((candidate) =>
    pipe(
      candidate.data.orderSectionList,
      O.fromNullable,
      O.fold(
        () => false,
        flow(
          A.filter((e) => sectionSpec.isSatisfiedBy(new 모델_주문섹션(e))),
          (e) => e.length > 0
        )
      )
    )
  )

// ======================================

/**
 * @description 일반상품주문서
 */
export const 일반상품주문: ISpecification<모델_주문서> = new Spec(
  (candidate) => candidate.data.orderTypeCd === 상수_주문타입.쇼핑
)

export const 예약상품주문: ISpecification<모델_주문서> = new Spec(
  (candidate) => candidate.data.orderTypeCd === 상수_주문타입.예약
)

export const 디지털상품주문: ISpecification<모델_주문서> = new Spec(
  (candidate) => candidate.data.orderTypeCd === 상수_주문타입.디지털
)

export const 그룹패스상품주문: ISpecification<모델_주문서> = new Spec(
  (candidate) => candidate.data.orderTypeCd === 상수_주문타입.그룹패스
)

export const 입금대기: ISpecification<모델_주문서> = new Spec(
  (candidate) => candidate.data.paymentInfo.type === 상수_결제타입.입금대기
)

export const PC: ISpecification<모델_주문서> = new Spec(
  (candidate) => candidate.data.deviceCd === 'DTA01'
)

export const MOBILE: ISpecification<모델_주문서> = new Spec(
  (candidate) => candidate.data.deviceCd === 'DTA02'
)

export const 입금기한_초과: ISpecification<모델_주문서> = new Spec(
  (candidate) =>
    pipe(
      candidate.data.paymentInfo.paymentList,
      O.fromNullable,
      O.fold(
        () => false,
        flow(
          A.findFirst((r) => r.statusCd === 상수_결제상태.결제기한초과),
          O.isSome
        )
      )
    ) && candidate.data.paymentInfo.type === 상수_결제타입.입금대기
)

export const 수동입금: ISpecification<모델_주문서> = new Spec(
  (candidate) => candidate.data.paymentInfo.type === 상수_결제타입.미결제
)

export const 미결제: ISpecification<모델_주문서> = new Spec(
  (candidate) => candidate.data.paymentInfo.type === 상수_결제타입.미결제
)

export const 결제요청: ISpecification<모델_주문서> = new Spec(
  (candidate) =>
    candidate.data.paymentInfo.type === 상수_결제타입.미결제 &&
    candidate.data.isRequestPayment === YN.Y
)

export const 외부연동_주문: ISpecification<모델_주문서> = new Spec(
  (candidate) =>
    [상수_판매채널.네이버페이, 상수_판매채널.톡체크아웃].some(
      (idx) => idx === candidate.data.saleChannelIdx
    )
)

export const 선물하기주문: ISpecification<모델_주문서> = new Spec(
  (candidate) => candidate.data.isGift === 'Y'
)

export const 선물하기배송지미입력주문: ISpecification<모델_주문서> = new Spec(
  (candidate) =>
    candidate.data.isGift === 'Y' &&
    !!candidate.data.orderDeliveryList?.some((od) => od.isInput === 'N')
)

/**
 * @description
 * - 🟥 디지털상품
 * - 🟥 그룹패스상품
 * - 🟥 예약상품
 * - 🟥 입금대기
 */
export const 섹션변경가능여부 = 주문서
  .and(디지털상품주문.not())
  .and(그룹패스상품주문.not())
  .and(예약상품주문.not())
  .and(입금대기.not())
  .and(선물하기배송지미입력주문.not())

export const 거래종료여부 = 주문서.and(
  new Spec((candidate) => candidate.data.statusCd === 상수_주문상태.거래종료)
)

export const 정기구독_주문: ISpecification<모델_주문서> = new Spec(
  (candidate) => candidate.data.isSubscription === YN.Y
)

/**
 * 추가 가능한 섹션이 있는가
 * @see https://www.notion.so/imweb/07-27025a52850b46dc9d8dee3040370a44
 */
export const 섹션추가가능여부 = 주문서
  .and(
    new Spec((candidate) => {
      // 주문의 결제상태 검증: 결제대기 상태에는 추가 제한
      if (candidate.data.paymentInfo.type === 상수_결제타입.입금대기) {
        return false
      }
      // 거래 개시 상태에서는 추가 가능
      return candidate.data.statusCd === 상수_주문상태.거래개시
    })
  )
  .and(정기구독_주문.not())

export const 디지털그룹패스반품가능여부 = 디지털상품주문
  .or(그룹패스상품주문)
  .not()
  .and(
    섹션스펙필터링(
      스펙_주문섹션.배송중.or(스펙_주문섹션.배송완료).or(스펙_주문섹션.구매확정)
    )
  )

/**
 * 반품 가능한 섹션이 있는가
 * @see https://www.notion.so/imweb/07-27025a52850b46dc9d8dee3040370a44
 */
export const 일반상품반품가능여부 = 일반상품주문.and(
  섹션스펙필터링(
    스펙_주문섹션.배송중.or(스펙_주문섹션.배송완료).or(스펙_주문섹션.구매확정)
  )
)

/**
 * @description
 * 디지털, 그룹패스 취소가능여부
 * 섹션에 상품준비중 섹션이 존재
 * 또는 구매확정 섹션 존재
 */
export const 디지털그룹패스취소가능여부: ISpecification<모델_주문서> =
  디지털상품주문
    .or(그룹패스상품주문)
    .and(
      일반주문섹션스펙에부합하는섹션존재유무(스펙_주문섹션.상품준비).or(
        일반주문섹션스펙에부합하는섹션존재유무(스펙_주문섹션.구매확정)
      )
    )

/**
 * @description
 * 일반상품 취소가능여부
 * 섹션에 상품준비중 섹션이 존재할때
 * 섹션에 배송대기 상태이면서 송장번호가 없는 섹션이 존재할때
 */
export const 일반상품취소가능여부: ISpecification<모델_주문서> =
  일반상품주문.and(
    일반주문섹션스펙에부합하는섹션존재유무(스펙_주문섹션.상품준비).or(
      일반주문섹션스펙에부합하는섹션존재유무(
        스펙_주문섹션.배송대기_송장등록전
      ).or(
        일반주문섹션스펙에부합하는섹션존재유무(
          스펙_주문섹션.배송대기_송장등록후.and(스펙_주문섹션.배송없는섹션)
        )
      )
    )
  )

export const 전체취소: ISpecification<모델_주문서> = new Spec((order) => {
  /**
   * @description
   * ```
   * 생성된 주문거는 거래개시로 만들어진다.
   * 거래개시는 paymentList가 0개이다.
   * 거래개시가 아닌 상태는 paymentList가 무조건 있다.
   * **가드처럼 사용**
   * ```
   */
  if (order.data.statusCd === 상수_주문상태.거래대기) {
    return true
  }

  // 주문서 검증: 주문서상태는 "거래개시" + 주문서의 결제된 금액은 0원
  function isOrderValid(_order: OrderDetailData) {
    if (_order.statusCd === 상수_주문상태.거래개시 && _order.pgAmount === 0) {
      return O.some(_order)
    }
    return O.none
  }

  // 결제데이터 검증: 결제대기 데이터가 1개 있어야하고 그 결제데이터의 paymantNo는 최소결제데이터를 뜻하는 P0또는 P1이어야함
  function isPaymentValid(
    paymentList: OrderDetailData['paymentInfo']['paymentList']
  ) {
    return pipe(
      paymentList,
      O.fromNullable,
      O.fold(
        () => O.none,
        flow(
          A.filter((p) => p.statusCd === 상수_결제상태.결제대기),
          (e) => {
            if (e.length === 1) {
              const paymentNo = e[0].paymentNo
              if (paymentNo.endsWith('P0') || paymentNo.endsWith('P1')) {
                return O.some(e[0])
              }
            }
            return O.none
          }
        )
      )
    )
  }
  // 결제수단 검증: 위 결제대기 데이터의 결제수단은 무통장 or 가상계좌
  function isPaymentMethodValid(payment: PaymentWithMethodDetailData[]) {
    if (
      payment.length === 1 &&
      (payment[0].pgMethodCd === 상수_결제수단.무통장입금 ||
        payment[0].pgMethodCd === 상수_결제수단.가상계좌)
    ) {
      return O.some(payment[0])
    }
    return O.none
  }
  const result = pipe(
    order.data,
    isOrderValid,
    O.chain((o) => isPaymentValid(o.paymentInfo.paymentList)),
    O.chain((p) => isPaymentMethodValid(p.data)),
    O.isSome
  )
  return result
})

export const 비회원주문: ISpecification<모델_주문서> = new Spec(
  flow((candidate) => candidate.data.memberCode, S.startsWith('gu'))
)

export const 주문섹션전체취소: ISpecification<모델_주문서> = new Spec(
  flow(
    (candidate) =>
      candidate.data.orderSectionList?.every(
        (section) => section.statusCd === 상수_섹션상태.취소완료
      ) ?? false
  )
)

export const 거래대기상태: ISpecification<모델_주문서> = new Spec(
  flow((candidate) => candidate.data.statusCd === 상수_주문상태.거래대기)
)
