import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPatchResReturnReasonDto,
  type TPatchPayloadReturnReasonDto,
  patchResReturnReasonDto,
  patchPayloadReturnReasonDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/JpVs8B
 * @description [return] 외부 주문서 반품접수 섹션 사유수정 실제 (외부 접수건의 사유는 변경되지 않습니다)
 */
export function patchReturnReason({ orderCode }: { orderCode: string }) {
  return async function (body: TPatchPayloadReturnReasonDto) {
    type T = TPatchResReturnReasonDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/external-order/${orderCode}/sections/return-reason`, body)
      .then((res) => res.data)

    try {
      patchResReturnReasonDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}
