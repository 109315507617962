import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import type {
  TGetResOrderSearchTabSchema,
  TPostResOrderSearchTabSchema,
  TPostPayloadOrderSearchTabSchema,
  TPatchResOrderSearchTabSchema,
  TPatchPayloadOrderSearchTabSchema,
  TDeleteResOrderSearchTabSchema,
  TPostResOrderSearchTabCopySchema,
  TPostPayloadOrderSearchTabCopySchema,
  TPatchPayloadOrderSearchTabSortingSchema,
  TGetResOrderSearchTabDetailSchema,
} from './order-search-tab.type'
import {
  getResOrderSearchTabSchema,
  postResOrderSearchTabSchema,
  postPayloadOrderSearchTabSchema,
  patchResOrderSearchTabSchema,
  patchPayloadOrderSearchTabSchema,
  deleteResOrderSearchTabSchema,
  patchPayloadOrderSearchTabSortingSchema,
  getResOrderSearchTabDetailSchema,
} from './order-search-tab.dto'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/cDcMrC
 * @description 주문 검색 - 탭, 필터 조회
 */
export function getOrderSearchTab() {
  return async function () {
    const result = await axios
      .get<ApiResponse<TGetResOrderSearchTabSchema>>('/order/search/tabs/test')
      .then((res) => {
        // list[].columnCondition이 {}이라면 null로 변경한다
        res.data.data.list.forEach((item) => {
          if (
            item.columnCondition &&
            Object.keys(item.columnCondition).length === 0
          ) {
            item.columnCondition = null
          }
        })
        return res.data
      })

    try {
      getResOrderSearchTabSchema.parse(result.data)
    } catch (error) {
      log.debug(error)
    }
    return result
  }
}

export function getOrderSearchTabDetail(tabCode: string) {
  return async function () {
    const result = await axios
      .get<
        ApiResponse<TGetResOrderSearchTabDetailSchema>
      >(`/order/search/tabs/test/${tabCode}`)
      .then((res) => {
        // columnCondition이 {}이라면 null로 변경한다
        if (
          res.data.data.columnCondition &&
          Object.keys(res.data.data.columnCondition).length === 0
        ) {
          res.data.data.columnCondition = null
        }
        return res.data
      })

    try {
      getResOrderSearchTabDetailSchema.parse(result.data)
    } catch (error) {
      log.debug(error)
    }
    return result
  }
}

/**
 * @API문서 https://5k.gg/fidgeK
 * @description 주문 검색 - 탭 정보 생성
 */
export function postOrderSearchTab() {
  return async function (body: TPostPayloadOrderSearchTabSchema) {
    type T = TPostResOrderSearchTabSchema
    type R = typeof body
    postPayloadOrderSearchTabSchema.parse(body)

    const result = await axios.post<T, AxiosResponse<ApiResponse<T>>, R>(
      '/order/search/tabs',
      body
    )

    return result
  }
}

/**
 * @API문서 https://5k.gg/B8R8jt
 * @description 주문 검색 - 탭 정보 수정
 */
export function patchOrderSearchTab({ tabCode }: { tabCode: string }) {
  return async function (body: TPatchPayloadOrderSearchTabSchema) {
    type T = TPatchResOrderSearchTabSchema
    type R = typeof body
    patchPayloadOrderSearchTabSchema.parse(body)

    const result = await axios.patch<T, AxiosResponse<T>, R>(
      `/order/search/tabs/${tabCode}`,
      body
    )

    return result
  }
}

/**
 * @API문서 https://5k.gg/4vpLI3
 * @description 주문 검색 - 탭 정보 삭제
 */
export function deleteOrderSearchTab({ tabCode }: { tabCode: string }) {
  return async function () {
    type T = TDeleteResOrderSearchTabSchema
    const result = await axios.delete<T, AxiosResponse<ApiResponse<T>>>(
      `/order/search/tabs/${tabCode}`
    )

    try {
      deleteResOrderSearchTabSchema.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}

/**
 * @API문서 https://5k.gg/usFqt0
 * @description 주문 검색 - 탭 정보 복사
 */
export function postOrderSearchTabCopy({ tabCode }: { tabCode: string }) {
  return async function (body: TPostPayloadOrderSearchTabCopySchema) {
    type T = TPostResOrderSearchTabCopySchema
    type R = typeof body
    const result = await axios.post<T, AxiosResponse<ApiResponse<T>>, R>(
      `/order/search/tabs/${tabCode}/copy`,
      body
    )

    return result
  }
}

/**
 * @description 주문 검색 - 탭 정렬 순서 변경
 */
export async function patchOrderSearchTabSorting({
  tabCode,
  body,
}: {
  tabCode: string
  body: TPatchPayloadOrderSearchTabSortingSchema
}) {
  type T = ApiResponse<undefined>
  type R = typeof body
  patchPayloadOrderSearchTabSortingSchema.parse(body)

  const result = await axios.patch<T, AxiosResponse<T>, R>(
    `/order/search/tabs/${tabCode}/sorting`,
    body
  )

  return result
}
