import { atom, useAtom } from 'jotai'
import { __ } from '~/shared/i18n'

type TModalParams = {
  currency: string
  prodName: string
  prodImg: string | null
  optionLabels: string[] | null
  baseItemPrice: number
  handleSubmit: (
    price: number,
    detail: {
      type: 'percent' | 'fixed'
      discountValue: number
    }
  ) => void
  type?: 'percent' | 'fixed'
  discountValue?: number
}

const productDiscountModalAtom = atom<TModalParams>({
  currency: 'KRW',
  prodName: '',
  prodImg: null,
  optionLabels: null,
  baseItemPrice: 0,
  handleSubmit: log.debug,
  discountValue: 0,
  type: 'fixed',
})

export function useProductDiscountModalAtom() {
  return useAtom(productDiscountModalAtom)
}
