import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import * as Eq from 'fp-ts/Eq'
import * as N from 'fp-ts/number'
import * as B from 'fp-ts/boolean'
import { contramap } from 'fp-ts/Ord'
import {
  SELECTION,
  TGetResExcelTemplatesDto,
  TPostPayloadExcelTemplatesDto,
  TPostPayloadExcelTemplatesRdo,
  TSelection,
} from '~/entities/excel/excel-templates'
import { __c } from '~/shared/i18n'

export function findExportTemplate(templateCode: number) {
  return (templates: TGetResExcelTemplatesDto['list']) =>
    // templates에서 templateCode에 해당하는 값을 찾아서 반환
    pipe(
      templates,
      A.findFirst((template) => template.idx === templateCode),
      O.fold(
        () => {
          throw new Error('template not found')
        },
        (template) => template
      )
    )
}

type T = TPostPayloadExcelTemplatesDto['templateInfo']['selection']
type U = TPostPayloadExcelTemplatesRdo['templateInfo']['selection']
// 받아온 selections의 sort를 통해서 배열을 정렬한다.
export const bySortTemplateInfoSelectionSort = pipe(
  N.Ord,
  contramap((a: T[0]) => a.sort)
)
export const bySortTemplateInfoSelectionDisalbedSort = pipe(
  B.Ord,
  contramap((a: U[0]) => a._active)
)

export const bySortTemplateInfoSelectionSearchSort = pipe(
  N.Ord,
  contramap((a: U[0]) => a._searchOrder)
)

export function selectionRdo(selections: T): U {
  const selectionsAll = pipe(
    SELECTION,
    Object.keys,
    A.mapWithIndex((i, key) => ({
      column: SELECTION[key as TSelection],
      _searchOrder: i,
    }))
  )
  // sheetItems에서 selectedSheetItems있는 id들의 순서를 찾아서 checked안된것들을 먼저 보여주고 checked된것들을 보여준다.
  // checked된 것들의 정렬을 후순위로
  const filterSheetItems = pipe(
    selectionsAll,
    A.filter((item) =>
      pipe(
        selections,
        A.map((selectedItem) => selectedItem.column),
        A.elem(Eq.eqStrict)(item.column),
        (e) => !e
      )
    ),
    A.map((e) => ({
      ...e,
      sort: 9999,
      _active: false,
    }))
  )

  const result = pipe(
    [
      ...pipe(
        selections,
        A.map((e) => ({
          ...e,
          _active: true,
          _searchOrder: pipe(
            selectionsAll,
            A.findFirst((item) => item.column === e.column),
            O.map((e2) => e2._searchOrder),
            O.getOrElse(() => 0)
          ),
        }))
      ),
      ...filterSheetItems,
    ],
    A.map((e) => ({
      ...e,
      _name: __c(e.column),
      _check: false,
    }))
  )
  return result
}

export function convertDtoRdoPostPayloadExcelTemplate(
  template: TPostPayloadExcelTemplatesDto
): TPostPayloadExcelTemplatesRdo {
  const selection = pipe(template.templateInfo.selection, selectionRdo)
  return {
    ...template,
    templateInfo: {
      ...template.templateInfo,
      selection,
    },
  }
}

export const deletedReorder = (selections: U) => {
  // _active가 true인 것들 추출
  const trueGroup = pipe(
    selections,
    A.filter((e) => e._active),
    A.sortBy([bySortTemplateInfoSelectionSort]),
    A.mapWithIndex((index, e) => ({
      ...e,
      sort: index,
    }))
  )
  // _active가 false인 것들 추출
  const falseGroup = pipe(
    selections,
    A.filter((e) => !e._active),
    A.map((e) => ({
      ...e,
      sort: 9999,
    }))
  )

  const result = [...trueGroup, ...falseGroup]
  return result
}

export const addReorder = (selections: U) => {
  const result = pipe(
    selections,
    A.mapWithIndex((index, e) => ({
      ...e,
      sort: index,
    }))
  )
  return result
}
