import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FormProvider, useForm } from 'react-hook-form'
import deepEqual from 'fast-deep-equal'
import { PageLayout } from './components/page-layout'
import { OrderSearchTabForm } from './components/order-search-tab-form'
import {
  postOrderSearchTab,
  orderSearchTabQueryOptions,
} from '~/entities/order-search-tab'
import { toast } from 'react-toastify'
import { __, __e } from '~/shared/i18n'
import {
  TOrderSearchTabRdo,
  TOrderSearchTabSelectRdo,
  orderSearchTabResolver,
} from './order-search-tab.rdo'
import {
  useSelectData,
  useHandleCancel,
  useApiPayload,
} from './order-seasrch-tab.hook'
import { 믹스패널 } from '~/shared/mixpanel'

export default function OrderSearchTabNew() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const selectDataList = useSelectData()
  const handleCancel = useHandleCancel()
  const getApiPayload = useApiPayload()
  const [loading, setLoading] = React.useState(false)

  const { mutate } = useMutation({
    mutationFn: postOrderSearchTab(),
    onSuccess: async (res) => {
      setLoading(true)

      await queryClient.invalidateQueries({
        queryKey: orderSearchTabQueryOptions().queryKey,
      })

      toast.success(__('새로운 탭이 생성되었어요'))
      dataLayer.push({
        event: 믹스패널.click_bo_oms_order_list_tap_add_tap_save,
      })

      navigate(`/orders/tab/${res.data.data.adminOrderSearchTabCode}`, {
        replace: true,
      })

      setLoading(false)
    },
    onError: (e) => {
      toast.error(
        e.response?.data.code
          ? __e(e.response?.data.code)
          : '탭 생성에 실패했어요'
      )
    },
  })

  const formInitialValues: TOrderSearchTabRdo = React.useMemo(() => {
    const selectData = selectDataList.reduce(
      (acc, { name, options }) => ({
        ...acc,
        [name]: options.map((option) => option.value),
      }),
      {} as TOrderSearchTabSelectRdo
    )

    return { ...selectData, name: '' }
  }, [selectDataList])

  const methods = useForm<TOrderSearchTabRdo>({
    resolver: orderSearchTabResolver,
    defaultValues: formInitialValues,
    mode: 'onChange',
  })

  const formValues = methods.watch()
  const formNotChanged = React.useMemo(
    () => deepEqual(formValues, formInitialValues),
    [formInitialValues, formValues]
  )

  function onSubmit(data: TOrderSearchTabRdo) {
    mutate(getApiPayload(data))
  }

  React.useEffect(() => {
    methods.setFocus('name')
  }, [methods.setFocus])

  return (
    <PageLayout>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <OrderSearchTabForm
            selectDataList={selectDataList}
            handleCancel={handleCancel}
            isLoading={methods.formState.isSubmitting || loading}
            isDisabled={formNotChanged || !methods.formState.isValid}
          />
        </form>
      </FormProvider>
    </PageLayout>
  )
}
