import React from 'react'
import { Flex, Modal, Spinner } from '@imwebme/clay-components'
import { useModals } from '~/router'
import { ExternalDeliveryEditModal } from './external-delivery-edit-modal'
import { __ } from '~/shared/i18n'
import { ErrorBoundary } from '@sentry/react'
import { ErrorCard } from '~/shared/components/error'

export function ExternalDeliveryEditModalPack() {
  const modals = useModals()

  return (
    <Modal width="small" isOpen={!!open} setClose={modals.close}>
      <Modal.Header text={__('배송지 수정')} />
      <ErrorBoundary
        fallback={
          <Flex.Center height="574px">
            <ErrorCard />
          </Flex.Center>
        }
      >
        <React.Suspense
          fallback={
            <Flex.Center height="574px">
              <Spinner size="medium" />
            </Flex.Center>
          }
        >
          <ExternalDeliveryEditModal />
        </React.Suspense>
      </ErrorBoundary>
    </Modal>
  )
}
