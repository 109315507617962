import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import { Controller } from 'react-hook-form'
import { useOrderSectionCancelComplete } from '..'
import { RadioGroup } from '~/shared/components/ui/radio-group'
import { __ } from '~/shared/i18n'
import { match } from 'ts-pattern'
import { Trans } from 'react-i18next'
import {
  모델_취소금액계산,
  스펙_취소금액계산,
} from '~/entities/order-section/cancel-settlements/model'
import { Input } from '~/shared/components/input'
import { ContentsBanner } from '@imwebme/clay-components'
import { useCurrency } from '~/shared/hooks/use-currency'
import { cn } from '~/shared/utils'
import * as math from 'mathjs'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { useSuspenseQuery } from '@tanstack/react-query'
import { orderDetailOptions } from '~/entities/order-detail'
import { 상수_환불금액_타입 } from '~/entities/@x'

export function DeliveryExtraPriceType({
  $cancel,
}: {
  $cancel: 모델_취소금액계산
}) {
  const currency = useCurrency()
  const orderCode = useOrderCode()
  const { data } = useSuspenseQuery(orderDetailOptions({ orderCode }))
  const {
    control,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
  } = useOrderSectionCancelComplete()
  const deliveryExtraPriceType = watch('deliveryExtraPriceType')

  React.useEffect(() => {
    const _sub = match<boolean>(true)
      .with(
        $cancel.form.refundPriceTypeCd === 상수_환불금액_타입.실결제가,
        $cancel.form.refundPriceTypeCd === 상수_환불금액_타입.평균실결제가,
        () =>
          math.min(
            $cancel.최대_환불가능_배송비,
            math
              .chain(data.deliveryPrice || 0)
              .add(data.deliveryIslandPrice)
              .done()
          )
      )
      .otherwise(() => null)
    setValue('deliveryExtraPrice._sub', _sub)
  }, [$cancel.form.deliveryExtraPriceType, $cancel.form.refundPriceTypeCd])

  React.useEffect(() => {
    if ($cancel.form.deliveryExtraPrice?._sub) {
      if (
        Number($cancel.form.deliveryExtraPrice._sub) >
          $cancel.최대_환불가능_배송비 &&
        errors.deliveryExtraPrice?._sub === undefined
      ) {
        setError('deliveryExtraPrice._sub', {
          message: __('최대 환불 가능 배송비를 초과하였습니다.'),
        })
      } else if (
        Number($cancel.form.deliveryExtraPrice._sub) <=
          $cancel.최대_환불가능_배송비 &&
        errors.deliveryExtraPrice?._sub &&
        Number($cancel.form.deliveryExtraPrice._sub) > 0
      ) {
        clearErrors('deliveryExtraPrice._sub')
      }
    }
  }, [$cancel])

  const disabled = 스펙_취소금액계산.배송비환불가능여부
    .not()
    .isSatisfiedBy($cancel)

  return (
    <div className="space-y-[20px]">
      {match<boolean>(true)
        .with(
          $cancel.form.refundPriceTypeCd === 상수_환불금액_타입.직접입력,
          () => (
            <ContentsBanner
              type="secondary"
              variant="warning"
              text={__('금액 설정을 직접하는 경우 배송비 설정이 제한되어요')}
            />
          )
        )
        .with(
          스펙_취소금액계산.배송비환불타입
            .and(스펙_취소금액계산.배송비환불가능여부.not())
            .isSatisfiedBy($cancel),
          () => (
            <ContentsBanner
              type="secondary"
              variant="warning"
              text={__(
                '환불 가능한 금액보다 환불 요청 금액이 더 많은경우 배송비 환불이 제한되어요'
              )}
            />
          )
        )
        .otherwise(() => null)}
      <Controller
        control={control}
        name="deliveryExtraPriceType"
        render={({ field: { value, onChange } }) => (
          <RadioGroup
            defaultValue="separate"
            value={value}
            onValueChange={onChange}
            className="flex gap-x-[24px]"
            disabled={
              $cancel.form.refundPriceTypeCd === 상수_환불금액_타입.직접입력
            }
          >
            <div className="flex items-center space-x-2">
              <RadioGroup.Item value="ADD" id="r1" />
              <RadioGroup.Label htmlFor="r1">{__('요청')}</RadioGroup.Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroup.Item value="SUB" id="r2" />
              <RadioGroup.Label htmlFor="r2">{__('환불')}</RadioGroup.Label>
            </div>
          </RadioGroup>
        )}
      />
      <div>
        <div
          className={cn(
            'space-y-[8px]',
            deliveryExtraPriceType !== 'ADD' && 'hidden'
          )}
        >
          <Controller
            control={control}
            name="deliveryExtraPrice._add"
            render={({ field, fieldState: { error } }) => (
              <>
                <div className="relative w-[180px]">
                  <Trans
                    i18nKey="<input /> <span>{{ currency, currency }}</span>"
                    values={{
                      formatParams: {
                        currency: {
                          currency,
                        },
                      },
                    }}
                    components={{
                      input: (
                        <Input
                          type="number"
                          value={pipe(field.value, String)}
                          onChange={flow(
                            (e) => e.target.value,
                            Number,
                            (e) => {
                              if (e < 0) {
                                return 0
                              }
                              return e
                            },
                            field.onChange
                          )}
                          className={cn(
                            'pr-[calc(12px*2+12px)]',
                            error &&
                              'bg-clay-semantic-surfaceCritical border-none'
                          )}
                          disabled={$cancel.form.refundPriceTypeCd === 'ORT05'}
                        />
                      ),
                      span: (
                        <span className="text-clay-semantic-textSub typo-clay-body-medium absolute right-[12px] top-1/2 -translate-y-1/2" />
                      ),
                    }}
                  />
                </div>
                {error && (
                  <div className="typo-clay-body-small">
                    {__('배송비 추가금액은 20만원 이하로 입력해주세요.')}
                  </div>
                )}
              </>
            )}
          />
          <div className="typo-clay-body-small text-clay-semantic-textSub">
            {__('최대 {{price, 3comma}} {{currency, currency}} 가능', {
              price: 200000,
              formatParams: {
                currency: {
                  currency,
                },
              },
            })}
          </div>
        </div>
        <div
          className={cn(
            'space-y-[8px]',
            deliveryExtraPriceType !== 'SUB' && 'hidden'
          )}
        >
          <Controller
            control={control}
            name="deliveryExtraPrice._sub"
            render={({ field, fieldState: { error } }) => (
              <div className="relative w-[180px]">
                <Trans
                  i18nKey="<input /> <span>{{ currency, currency }}</span>"
                  values={{
                    formatParams: {
                      currency: {
                        currency,
                      },
                    },
                  }}
                  components={{
                    input: (
                      <Input
                        type="number"
                        value={pipe(field.value, String)}
                        onChange={flow(
                          (e) => e.target.value,
                          Number,
                          (e) => {
                            if (e < 0) {
                              return 0
                            }
                            return e
                          },
                          field.onChange
                        )}
                        disabled={disabled}
                        className={cn(
                          'pr-[calc(12px*2+12px)]',
                          error &&
                            'bg-clay-semantic-surfaceCritical border-none'
                        )}
                      />
                    ),
                    span: (
                      <span
                        className={cn(
                          'typo-clay-body-medium absolute right-[12px] top-1/2 -translate-y-1/2',
                          disabled
                            ? 'text-clay-semantic-textDisabled'
                            : 'text-clay-semantic-textSub'
                        )}
                      />
                    ),
                  }}
                />
              </div>
            )}
          />
          {!disabled && (
            <div className="mt-[8px] typo-clay-body-small text-clay-semantic-textSub">
              {__('최대 {{price, 3comma}} {{currency, currency}} 가능', {
                price: $cancel.최대_환불가능_배송비,
                formatParams: {
                  currency: {
                    currency,
                  },
                },
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
