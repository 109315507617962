import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { ErrorCard } from '~/shared/components/error'
import { Loading } from '~/shared/components/loading'
import { TExportTemplateModalBase } from '.'

const ExportTemplateModalContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.ExportTemplateModal),
  }))
)

interface TExportTemplateModalPack extends TExportTemplateModalBase {}
export function ExportTemplateModalPack(props: TExportTemplateModalPack) {
  return (
    <ErrorBoundary fallback={ErrorCard}>
      <React.Suspense fallback={<Loading />}>
        <ExportTemplateModalContainer {...props} />
      </React.Suspense>
    </ErrorBoundary>
  )
}
