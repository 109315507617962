import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPostResBulkActionSectionDeliveryHoldApplySchema,
  type TPostPayloadBulkActionSectionDeliveryHoldApplySchema,
  postResBulkActionSectionDeliveryHoldApplySchema,
} from '.'
import * as Sentry from '@sentry/react'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/HutS6I
 * @description 주문 배송 보류 설정 대량 처리 등록
 */
export async function postBulkActionSectionDeliveryHoldApply({
  body,
}: {
  body: TPostPayloadBulkActionSectionDeliveryHoldApplySchema
}) {
  type R = typeof body
  type T = TPostResBulkActionSectionDeliveryHoldApplySchema
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/section/delivery/hold', body)
    .then((res) => res.data)

  try {
    postResBulkActionSectionDeliveryHoldApplySchema.parse(result.data)
  } catch (error) {
    Sentry.captureException(error)
  }
  return result
}
