import { useSuspenseQuery } from '@tanstack/react-query'
import { orderListPaginationQueryOptions } from '~/entities/order-list'
import { useOrderListQueryParams } from '~/entities/order-list/order-list.model'
import { Pagination } from '~/shared/components/pagination'
import { usePageNumber } from '~/shared/hooks/use-page-number'

export function OrdersPaginationContainer() {
  const queryParams = useOrderListQueryParams(true)
  const { data: pagination } = useSuspenseQuery(
    orderListPaginationQueryOptions(queryParams)
  )
  const pageNumber = usePageNumber()

  if (pagination.data.totalPage <= 1) {
    return null
  }
  return (
    <div className="px-clay-5 pt-clay-6 pb-clay-3 flex justify-center">
      <Pagination.Link total={pagination.data.totalPage} page={pageNumber} />
    </div>
  )
}
