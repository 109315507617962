import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { ErrorCard } from '~/shared/components/error'
import { Loading } from '~/shared/components/loading'
import { TExcelExportModalBase } from '.'

const ExcelExportModalContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.ExcelExportModal),
  }))
)

interface TExcelExportModalPack extends TExcelExportModalBase {}
export function ExcelExportModalPack(props: TExcelExportModalPack) {
  return (
    <ErrorBoundary fallback={ErrorCard}>
      <React.Suspense fallback={<Loading />}>
        <ExcelExportModalContainer {...props} />
      </React.Suspense>
    </ErrorBoundary>
  )
}
