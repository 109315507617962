import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { TBankTransferRefundBase, useBankTransferRefundAtom } from '.'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { ErrorCard } from '~/shared/components/error'
import { Modal } from '@imwebme/clay-components'
import { Loading } from '~/shared/components/loading'

const BankTransferRefundContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.BankTransferRefund),
  }))
)

interface TBankTransferRefundPack extends TBankTransferRefundBase {}
export function BankTransferRefundPack(props: TBankTransferRefundPack) {
  const orderCode = useOrderCode()
  const [open, setState] = useBankTransferRefundAtom.state({
    atomKey: props.atomKey,
    orderCode,
  })
  return (
    <Modal isOpen={!!open} setClose={() => setState(false)} width="small">
      <ErrorBoundary fallback={ErrorCard}>
        <React.Suspense fallback={<Loading />}>
          <BankTransferRefundContainer {...props} />
        </React.Suspense>
      </ErrorBoundary>
    </Modal>
  )
}
