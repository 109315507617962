import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import {
  type TPostResBulkActionSectionStatusApplyAllDto,
  type TPostPayloadBulkActionSectionStatusApplyAllDto,
  postResBulkActionSectionStatusApplyAllSchema,
} from '.'
import { TBulkActionSectionStatusApply } from '../bulk-action-section-status-apply'
import * as Sentry from '@sentry/react'

const axios = ApiClient()

export async function postBulkActionSectionStatusApplyAll<
  T = TPostResBulkActionSectionStatusApplyAllDto,
  R = TPostPayloadBulkActionSectionStatusApplyAllDto,
>({ body, status }: { body: R; status: TBulkActionSectionStatusApply }) {
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >(`/order/bulk-action/section/${status}/all`, body)
    .then((res) => res.data)

  try {
    postResBulkActionSectionStatusApplyAllSchema.parse(result.data)
  } catch (error) {
    Sentry.captureException(error)
  }
  return result.data
}
