import { atom, useAtom } from 'jotai'
import { __ } from '~/shared/i18n'

type TModalParams = {
  currency: string
  prodName: string
  prodImg: string | null
  optionLabels: string[] | null
  itemPrice: number
  baseItemPrice: number
  handleSubmit: (itemPrice: number) => void
}

const productPriceModalAtom = atom<TModalParams>({
  currency: 'KRW',
  prodName: '',
  prodImg: null,
  optionLabels: null,
  itemPrice: 0,
  baseItemPrice: 0,
  handleSubmit: log.debug,
})

export function useProductPriceModalAtom() {
  return useAtom(productPriceModalAtom)
}
