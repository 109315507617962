import { z } from 'zod'
import {
  templateInfoSelectionItemDto,
  templateInfoSettingDto,
} from './template-info.dto'

const templateInfoSelection = z.array(
  templateInfoSelectionItemDto.extend({
    _name: z.string(),
    _active: z.boolean(),
    _check: z.boolean(),
    _searchOrder: z.number(),
  })
)

const templateInfoSections = z.object({
  selection: templateInfoSelection,
})
export const templateInfoRdo =
  templateInfoSettingDto.merge(templateInfoSections)
