import React from 'react'
import { useTabCode } from '~/shared/hooks/use-tab-code'
import { useOrderAtom } from '~/container/orders/orders-table/orders-table-body/orders-table-body.store'
import { OrderToast } from './order-toast'

export function OrderToastContainer() {
  const tabCode = useTabCode()
  const selectedList = useOrderAtom.codeSelection.get({ tabCode }) // 선택된 주문서의 데이터

  return <OrderToast open={(selectedList.value || []).length > 0} />
}
