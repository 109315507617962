import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { TRefundProcessBase, useRefundProcessAtom } from '.'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { ErrorCard } from '~/shared/components/error'
import { Loading } from '~/shared/components/loading'
import { Modal } from '@imwebme/clay-components'

const RefundProcessContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.RefundProcess),
  }))
)

interface TRefundProcessPack extends TRefundProcessBase {}
export function RefundProcessPack(props: TRefundProcessPack) {
  const orderCode = useOrderCode()
  const [open, setState] = useRefundProcessAtom.state({
    orderCode: orderCode + (props.atomKey || ''),
  })
  return (
    <Modal isOpen={!!open} setClose={() => setState(false)} width="small">
      <ErrorBoundary fallback={ErrorCard}>
        <React.Suspense fallback={<Loading />}>
          <RefundProcessContainer {...props} />
        </React.Suspense>
      </ErrorBoundary>
    </Modal>
  )
}
