import { pipe, flow } from 'fp-ts/function'
import { match, P } from 'ts-pattern'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { cn } from '~/shared/utils'
import { ExternalOrderDetailHeader } from './partials/external-order-detail-header/external-order-detail-header'
import { ExternalOrderSectionList } from './partials/section/external-order-section-list'
import { ExternalPaymentInfoCardPack } from '~/widgets/external-payment-info-card'
import { ExternalPaymentInfoModalPack } from '~/widgets/external-payment-info-modal'
import {
  TypeTabCds,
  useExternalOrderDetailAtom,
} from './external-order-detail.store'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { useSuspenseQuery } from '@tanstack/react-query'
import { externalOrderQueryOptions } from '~/entities/external-order'
import { Tabs } from '~/shared/components/ui/tabs'
import {
  모델_외부채널_주문섹션,
  스펙_외부채널_주문섹션,
} from '~/entities/external-order/model/external-order-section'
import { ExternalPurchaserInfoCardPack } from '~/widgets/external-purchaser-info-card/external-purchaser-info-card.pack'
import { ExternalReceiverInfoCardPack } from '~/widgets/external-receiver-info-card'

export function ExternalOrderDetail() {
  return (
    <>
      <div className="@container/detail min-w-[992px] overflow-auto pt-[44px] desktop:pt-0">
        <div
          className={cn(
            'w-full mx-auto px-[40px] @8xl/detail:px-0 @8xl/detail:w-[1152px]'
          )}
        >
          <ExternalOrderDetailHeader />
          <div
            className={cn(
              'w-[100%] relative grid gap-x-[20px] gap-y-[16px] items-start',
              'grid-cols-[1fr,369px]'
            )}
          >
            <div className="row-span-2">
              <ExternalOrderDetailTabs />
              <div className="space-y-[16px] mt-[16px]">
                <ExternalOrderSectionList />
              </div>
            </div>
            <div className="grid gap-[16px]">
              <ExternalPaymentInfoCardPack />
              <ExternalPurchaserInfoCardPack />
              <ExternalReceiverInfoCardPack />
            </div>
          </div>
        </div>
      </div>
      <ExternalPaymentInfoModalPack />
    </>
  )
}

function ExternalOrderDetailTabs() {
  const orderCode = useOrderCode()
  const [currentTab, setCurrentTab] = useExternalOrderDetailAtom.state({
    orderCode,
  })
  const { data } = useSuspenseQuery({
    ...externalOrderQueryOptions({ orderCode }),
    select(d) {
      return pipe(
        d.data.orderSectionList,
        O.fromNullable,
        O.fold(
          () => [
            { tabName: '전체', count: 0 },
            { tabName: '배송', count: 0 },
            { tabName: '취소', count: 0 },
            { tabName: '반품', count: 0 },
          ],
          flow(
            A.reduce(
              {
                전체: 0,
                배송: 0,
                취소: 0,
                반품: 0,
              },
              (acc, e) => {
                acc.전체 += 1

                const $section = new 모델_외부채널_주문섹션(e)
                match<boolean>(true)
                  .with(
                    스펙_외부채널_주문섹션.상품준비
                      .or(스펙_외부채널_주문섹션.배송대기_송장등록전)
                      .or(스펙_외부채널_주문섹션.배송대기_송장등록후)
                      .or(스펙_외부채널_주문섹션.배송보류)
                      .or(스펙_외부채널_주문섹션.배송중)
                      .isSatisfiedBy($section),
                    () => (acc.배송 += 1)
                  )
                  .with(
                    스펙_외부채널_주문섹션.취소접수
                      .or(스펙_외부채널_주문섹션.취소완료)
                      .isSatisfiedBy($section),
                    () => (acc.취소 += 1)
                  )
                  .with(
                    스펙_외부채널_주문섹션.반품접수
                      .or(스펙_외부채널_주문섹션.반품완료)
                      .isSatisfiedBy($section),
                    () => (acc.반품 += 1)
                  )
                  .otherwise(() => acc)
                return acc
              }
            ),
            (e) => [
              { tabName: '전체', count: e.전체 },
              { tabName: '배송', count: e.배송 },
              { tabName: '취소', count: e.취소 },
              { tabName: '반품/교환', count: e.반품 },
            ]
          )
        )
      )
    },
  })
  return (
    <div className="w-[100%]">
      <Tabs.Root
        value={currentTab}
        onValueChange={(e) => setCurrentTab(e as TypeTabCds)}
        variant="segment"
        defaultValue="전체"
        className="w-[100%]"
      >
        <Tabs.List className="w-[100%] grid grid-cols-4 bg-clay-semantic-surfaceSub !rounded-[8px]">
          {data.map(({ count, tabName }) => (
            <Tabs.Trigger
              value={tabName}
              className="group min-h-[40px] text-clay-semantic-textSub gap-[4px] duration-0 m-[4px] typo-clay-label-medium-bold"
              key={tabName}
            >
              <div>{tabName}</div>
              <div className="group-data-[state=active]:bg-dark-clay-semantic-surfaceHighlight group-data-[state=active]:text-dark-clay-semantic-textPrimary min-w-[20px] h-[20px] flex items-center p-[2px 6px 2px 6px] rounded-[50px] ease-in-out duration-0 justify-center">
                <div>{count}</div>
              </div>
            </Tabs.Trigger>
          ))}
        </Tabs.List>
      </Tabs.Root>
    </div>
  )
}
