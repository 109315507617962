import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import type {
  TPatchResCancelCompleteDto,
  TPatchPayloadCancelCompleteDto,
} from './cancel-complete.type'
import { patchResCancelCompleteDto } from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/QH2pW5
 * @description 주문 섹션 상태 변경 (cancel-complete)
 */
export function patchCancelComplete({
  orderCode,
  sectionCode,
}: {
  orderCode: string
  sectionCode: string
}) {
  return async function (body: TPatchPayloadCancelCompleteDto) {
    type T = TPatchResCancelCompleteDto
    type R = typeof body
    const result = await axios.patch<T, AxiosResponse<ApiResponse<T>>, R>(
      `/order/${orderCode}/sections/${sectionCode}/cancel-complete`,
      body
    )

    try {
      patchResCancelCompleteDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}
