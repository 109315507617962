import { useEffect } from 'react'
import { __, __e, useLL } from '~/shared/i18n'
import { useCurOrderSearchFilter } from '~/container/order-search-filter/order-search-filter.store'
import { OrderSearchFilterPack } from '~/container/order-search-filter'
import { useOrderDetailHeaderGoBack } from '~/container/order/order-detail/partials/order-detail-header/order-detail-header.store'
import { Typography, IconButton } from '@imwebme/clay-components'
import { OrderSearch } from './order-search/order-search'
import { OrderSettings } from './order-settings/order-settings'
import { OrderColumnSortContainer } from './order-column-sort'
import { Filter } from '@imwebme/clay-icons'
import { cn } from '~/shared/utils'
import { OrdersTableBodyPack } from './orders-table-body'
import { useOrderSearchQuery } from '~/shared/hooks/use-order-search-query'
import { 믹스패널 } from '~/shared/mixpanel'
import { OrdersPaginationPack } from './orders-pagination'
import { NumOrdersBadgePack } from './num-orders-badge'
import { useActiveTab } from '~/shared/hooks/use-tab-code'

export const OrdersTable = () => {
  const location = useLL()
  const [, setSearchKeyword] = useOrderSearchQuery()
  const [filter, setFilter] = useCurOrderSearchFilter()
  const activeTab = useActiveTab()

  const setOrderDetailHeaderGoBack = useOrderDetailHeaderGoBack.set()
  useEffect(() => {
    setOrderDetailHeaderGoBack(`${location.pathname}${location.search}`)
  }, [location])

  // ====================================== 엑셀내보내기
  function handleClickFilter() {
    dataLayer.push({
      event: 믹스패널.click_bo_oms_order_list_filter,
    })
    setFilter({ ...filter, open: true })
    setSearchKeyword(null)
  }

  return (
    <div className="pt-clay-5 pb-clay-6 rounded-clay-large bg-clay-semantic-surface">
      {/* 테이블 헤더 */}
      <div className="px-clay-6 pb-clay-4">
        <div className="flex flex-col gap-y-clay-3 desktop:flex-row justify-between">
          <div className="flex gap-clay-1 items-center">
            <Typography variant="heading-xlarge-bold">
              {activeTab.name}
            </Typography>
            <NumOrdersBadgePack />
          </div>
          <div className="flex flex-col desktop:flex-row gap-clay-3">
            <OrderSearch />
            <div className="flex gap-clay-2">
              <IconButton
                native={{ type: 'button' }}
                variant="secondary"
                className={cn(
                  filter.open && 'bg-clay-semantic-actionSecondaryPressed'
                )}
                onClick={handleClickFilter}
                aria-label={__('필터설정')}
                icon={<Filter />}
              />
              <OrderColumnSortContainer />
              <OrderSettings />
            </div>
          </div>
        </div>
      </div>

      <OrderSearchFilterPack />

      <OrdersTableBodyPack />

      <OrdersPaginationPack />
    </div>
  )
}
