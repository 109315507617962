import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import type {
  TPostResBulkActionInvoiceDeleteValidateSchema,
  TPostPayloadBulkActionInvoiceDeleteValidateSchema,
} from './bulk-action-invoice-delete-validate.type'
import { postResBulkActionInvoiceDeleteValidateSchema } from './bulk-action-invoice-delete-validate.schema'
import * as Sentry from '@sentry/react'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/Yj2mGR
 * @description 주문 송장 삭제 대량 처리 검증
 */
export async function postBulkActionInvoiceDeleteValidate({
  body,
}: {
  body: TPostPayloadBulkActionInvoiceDeleteValidateSchema
}) {
  type R = typeof body
  type T = TPostResBulkActionInvoiceDeleteValidateSchema
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/invoice/delete/validate', body)
    .then((res) => res.data)

  try {
    postResBulkActionInvoiceDeleteValidateSchema.parse(result.data)
  } catch (error) {
    Sentry.captureException(error)
  }
  return result.data
}
