import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPatchResToggleIsRetrievedDto,
  type TPatchPayloadToggleIsRetrievedDto,
  patchResToggleIsRetrievedDto,
  patchPayloadToggleIsRetrievedDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description 수거완료여부 토글
 */
export function patchToggleIsRetrieved({
  orderCode,
  sectionCode,
}: {
  orderCode: string
  sectionCode: string
}) {
  return async function (body: TPatchPayloadToggleIsRetrievedDto) {
    type T = TPatchResToggleIsRetrievedDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/order/${orderCode}/sections/${sectionCode}/toggle-is-retrieved`, body)
      .then((res) => res.data)

    try {
      patchResToggleIsRetrievedDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}
