import { ApiClient } from '~/shared/api'
import { TGetResOrderListPaginationDto, type TGetResOrderListDto } from '.'

const axios = ApiClient()

// TODO test 지우기

/**
 * @API문서
 * @description order-list
 */
export async function getOrderList(params: Record<string, unknown>) {
  const result = await axios
    .get<ApiResponse<TGetResOrderListDto>>('/order/test', {
      params,
      paramsSerializer: {
        indexes: false,
      },
    })
    .then((res) => res.data)

  return result
}

/**
 * @API문서
 * @description order-list-pagination
 */
export async function getOrderListPagination(params: Record<string, unknown>) {
  const result = await axios
    .get<ApiResponse<TGetResOrderListPaginationDto>>('/order/test/pagenation', {
      params,
      paramsSerializer: {
        indexes: false,
      },
    })
    .then((res) => res.data)

  return result
}
