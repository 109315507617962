import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  TBulkActionSectionStatusApply,
  postResBulkActionSectionStatusApplySchema,
  type TPostPayloadBulkActionSectionStatusApplySchema,
  TPostResBulkActionPaymentApplySchema,
} from '.'
import * as Sentry from '@sentry/react'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/THHNLM
 * @description 주문 섹션 상태 변경 대량 처리 등록
 */
export async function postBulkActionSectionStatusApply({
  body,
  status,
}: {
  body: TPostPayloadBulkActionSectionStatusApplySchema
  status: TBulkActionSectionStatusApply
}) {
  type T = TPostResBulkActionPaymentApplySchema
  type R = typeof body
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >(`/order/bulk-action/section/${status}`, body)
    .then((res) => res.data)

  try {
    postResBulkActionSectionStatusApplySchema.parse(result.data)
  } catch (error) {
    Sentry.captureException(error)
  }
  return result.data
}
