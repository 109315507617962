import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPostResDeliveryDelayDto,
  type TPostPayloadDeliveryDelayDto,
  postResDeliveryDelayDto,
  postPayloadDeliveryDelayDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/0IMCcw
 * @description [delivery-delay] 외부채널 주문서 섹션 발송지연처리
 */
export function postDeliveryDelay({ orderCode }: { orderCode: string }) {
  return async function (body: TPostPayloadDeliveryDelayDto) {
    type R = typeof body
    type T = TPostResDeliveryDelayDto
    const result = await axios
      .post<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/external-order/${orderCode}/sections/delivery-delay`, body)
      .then((res) => res.data)

    try {
      postResDeliveryDelayDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}
