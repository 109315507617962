import {
  Button,
  ButtonGroup,
  Clay,
  Flex,
  Modal,
  SafeImageLoading,
  Tag,
  Textfield,
  Typography,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { useModals } from '~/router'
import { __ } from '~/shared/i18n'
import { useProductPriceModalAtom, useProductPrice } from '.'
import ProdThumbFallback from '~/shared/components/icon/product-thumb-fallback.svg?react'
import { Trans } from 'react-i18next'
import { toast } from 'react-toastify'

/** 상품 판매가 변경 모달 */
export function ProductPriceModal() {
  const modals = useModals()
  const [modalData] = useProductPriceModalAtom()
  const [localState, localDispatch] = useProductPrice({
    itemPrice: modalData.itemPrice,
    baseItemPrice: modalData.baseItemPrice,
  })

  return (
    <Modal width="small" isOpen={true} setClose={modals.close}>
      <Modal.Header text={__('판매가 변경')} />
      <Modal.Body>
        <Flex.Column rowGap={vars.spacing[5]}>
          {/* 상품 정보 */}
          <Flex columnGap={vars.spacing[3]}>
            <Clay
              width="48px"
              height="48px"
              overflow="hidden"
              borderRadius={vars.rounded.medium}
              flexShrink={0}
            >
              <SafeImageLoading
                src={modalData.prodImg ?? undefined}
                fallback={<ProdThumbFallback />}
              />
            </Clay>

            <Flex.Column rowGap={vars.spacing[15]}>
              <Typography variant="label-medium">
                {modalData.prodName}
              </Typography>
              {modalData.optionLabels && (
                <Flex gap={vars.spacing[1]} flexWrap="wrap">
                  {modalData.optionLabels.map((label, idx) => (
                    <Tag
                      key={idx}
                      variant="other"
                      text={label}
                      sx={{
                        '--clay-tag-background-color':
                          'rgba(113, 118, 128, 0.10)',
                        '--clay-tag-color': vars.semantic.color.textSub,
                      }}
                    />
                  ))}
                </Flex>
              )}
            </Flex.Column>
          </Flex>

          {/* 변경할 판매가 입력 */}
          <Textfield.Set>
            <Textfield.Box>
              <Textfield.Input
                value={localState.itemPrice?.toLocaleString('ko-KR') ?? ''}
                onChangeText={(v) =>
                  localDispatch({ payload: v.replaceAll(/[^0-9]/g, '') })
                }
                isValid={localState.error ? false : undefined}
                placeholder={__('{{price, 3comma}}', {
                  price: modalData.baseItemPrice,
                })}
                className="text-right"
              />
              <Textfield.Addon>
                <Typography variant="body-medium" colorToken="textSub">
                  {__('{{currency, currency}}', {
                    currency: modalData.currency,
                  })}
                </Typography>
              </Textfield.Addon>
            </Textfield.Box>

            <Textfield.HelperText>{localState.error}</Textfield.HelperText>
          </Textfield.Set>

          {/* 판매가 결과 */}
          <Flex
            columnGap={vars.spacing[1]}
            alignItems="center"
            justifyContent="flex-end"
          >
            {localState.itemPrice !== null &&
              localState.itemPrice !== localState.baseItemPrice && (
                <Typography
                  variant="body-medium"
                  colorToken="textDisabled"
                  as="span"
                  sx={{ textDecoration: 'line-through' }}
                >
                  {__('{{price, 3comma}}', { price: localState.baseItemPrice })}
                </Typography>
              )}
            <Trans
              i18nKey="<t1>{{price, 3comma}}</t1> <t2>{{currency, currency}}</t2>"
              values={{
                price: localState.itemPrice ?? localState.baseItemPrice,
              }}
              components={{
                t1: <Typography variant="heading-large-bold" as="span" />,
                t2: <Typography variant="body-medium" as="span" />,
              }}
            />
          </Flex>
        </Flex.Column>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button
            text={__('취소')}
            variant="outlined"
            fullWidth
            onClick={() => modals.close()}
          />
          <Button
            text={__('반영')}
            variant="primary"
            fullWidth
            isDisabled={!!localState.error || localState.itemPrice === null}
            onClick={() => {
              modalData.handleSubmit(localState.itemPrice as number)
              toast.success(__('판매가를 수정했어요'))
              modals.close()
            }}
          />
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  )
}
