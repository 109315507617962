import { __ } from '~/shared/i18n'
import { ContentsBanner } from '@imwebme/clay-components'
import { 모델_벌크검증, 스펙_벌크검증 } from '.'
import { Button } from '@imwebme/clay-components'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { TBulkBannerBase } from './bulk-banner.type'

interface TBulkBannerDirection {
  nextStatus: string
  prevStatus: string
}

interface TBulkBannerHeader extends TBulkBannerBase, TBulkBannerDirection {}
export function BulkBannerHeader({
  nextStatus,
  // prevStatus,
  fulfilled,
  rejected,
}: TBulkBannerHeader) {
  const $dpcc = new 모델_벌크검증({ fulfilled, rejected })
  if (스펙_벌크검증.실패.isSatisfiedBy($dpcc)) {
    return (
      <span>
        {__('{{섹션_변경상태}} 처리할 수 없어요', {
          섹션_변경상태: nextStatus,
        })}
      </span>
    )
  }
  return (
    <span>
      {__('{{처리가능주문건수}}건을 {{섹션_변경상태}} 처리할까요?', {
        처리가능주문건수: fulfilled,
        섹션_변경상태: nextStatus,
      })}
    </span>
  )
}

interface TBulkBanner extends TBulkBannerBase, TBulkBannerDirection {}
export function BulkBanner({
  nextStatus,
  prevStatus,
  fulfilled,
  rejected,
}: TBulkBanner) {
  const $dpcc = new 모델_벌크검증({ fulfilled, rejected })
  if (스펙_벌크검증.실패.isSatisfiedBy($dpcc)) {
    /**
     * @see https://cocoastudio.slack.com/archives/C069PU8TBNW/p1727688351297109
     * bulk banner 템플릿은 `{{섹션_변경상태}} 처리할 수 없어요` 이었지만 송장등록은 예외적으로 처리
     */
    if (nextStatus === '송장등록') {
      return (
        <ContentsBanner
          variant="critical"
          type="secondary"
          text={__('배송대기 상태가 아니라면 처리할 수 없어요')}
        />
      )
    }
    return (
      <ContentsBanner
        variant="critical"
        type="secondary"
        text={__('{{섹션_변경상태}} 처리할 수 없어요', {
          섹션_변경상태: nextStatus,
        })}
      />
    )
  }
  if (rejected === 0) {
    return (
      <div className="semantic-p14 text-clay-semantic-textSub">
        {__('선택한 주문의 상태를 한번에 변경할 수 있어요.')}
      </div>
    )
  }
  return (
    <ContentsBanner
      variant="warning"
      type="secondary"
      text={__(
        '{{변경상태_직전섹션}} 상태가 아닌 {{미처리주문수}}건은 처리되지 않아요.',
        {
          미처리주문수: rejected,
          변경상태_직전섹션: prevStatus,
        }
      )}
    />
  )
}

interface TBulkButtons extends TBulkBannerBase, TBulkBannerDirection {
  isLoading?: boolean
}
export function BulkBannerButtons({
  nextStatus,
  fulfilled,
  rejected,
  isLoading,
}: TBulkButtons) {
  const $dpcc = new 모델_벌크검증({ fulfilled, rejected })
  if (스펙_벌크검증.실패.isSatisfiedBy($dpcc)) {
    return (
      <div className="w-full flex justify-end gap-x-[8px]">
        <AlertDialog.Cancel asChild>
          <Button
            native={{ type: 'button' }}
            variant="secondary"
            text={__('닫기')}
          />
        </AlertDialog.Cancel>
      </div>
    )
  }

  return (
    <div className="w-full flex justify-end gap-x-[8px]">
      <AlertDialog.Cancel asChild>
        <Button
          native={{ type: 'button' }}
          variant="secondary"
          text={__('취소')}
        />
      </AlertDialog.Cancel>
      <Button
        native={{ type: 'submit' }}
        variant="primary"
        text={__('{{처리_상태명}} 처리', {
          처리_상태명: nextStatus,
        })}
        isLoading={isLoading}
      />
    </div>
  )
}
