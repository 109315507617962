import { TGetResExternalOrderDto, TExternalPaymentListItemDto } from '../../'
import { mainExternalPayment } from './external-order-payment.calc'
import { 모델_외부채널_주문서 } from '../external-order'

export class 모델_외부채널_페이먼트 extends 모델_외부채널_주문서 {
  payment: TExternalPaymentListItemDto

  constructor(data: TGetResExternalOrderDto) {
    super(data)
    this.payment = mainExternalPayment(data.paymentInfo)
  }
}
